export const API_URL = process.env.XPRO_API_URL;
export const { CHINA_API_URL } = process.env;
export const GOOGLE_API = process.env.GOOGLE_MAP_API_KEY;
export const DEFAULT_LANGUAGE = 'en';
export const {
  PAYTREK_API_URL,
  PUBLISHABLE_KEY,
  APP_NAME,
  SENTRY_DSN,
  API_KEY,
  B2C_API_KEY,
  TRANSFER_ENABLED,
  IS_TOKENIZATION_ENABLED,
  OPEN_STREET_MAP_KEY,
  GOOGLE_MAP_ENABLED,
  TRIPADVISOR_ENABLED,
  IS_SHOWN_SWITCH_NOTIFICATION,
  IS_PROFIT_ENABLED,
  SAFECHARGE_MERCHANT_ID,
  SAFECHARGE_SITE_ID,
  SAFECHARGE_ENV,
  RECAPTCHA_SITE_KEY,
} = process.env;

export const MAX_ROOM_COUNT_GALLERY_MODAL = 2;
export const MAX_ROOM_COUNT_MAP_MODAL = 3;
export const MAX_SELECTABLE_PHOTOS = 5;
export const MAX_SELECTABLE_ROOMS = 5;
export const MAX_SHOW_ROOM_COUNT = 5;
export const MAX_PROVISION_REQUEST = 6;

export const SUBAGENCY_LIST_LIMIT = 20;

export const CUSTOMER_SUPPORT = '+90 850 502 49 52';

export const CHECKOUT_INSTALLMENT_ENABLED = false;

export const MAX_GUEST_COUNT_PER_ROOM = 6;
export const MAX_NIGHT = 30;

export const DEFAULT_ADULT_COUNT = 2;
export const DEFAULT_CHILD_COUNT = 0;
export const MAX_ROOM_COUNT = 5;
export const MIN_ROOM_COUNT = 1;
export const MAX_CHILD_COUNT = 4;
export const MIN_CHILD_COUNT = 0;
export const MAX_ADULT_COUNT = 6;
export const MIN_ADULT_COUNT = 1;
export const MAX_CHILD_AGE = 17;
export const MIN_CHILD_AGE = 0;
export const MAX_RECENT_SEARCH_COUNT = 4;

export const countriesWithState = ['6251999', '6252001']; // Canada, United States

export const TICKET_MAX_FILE_SIZE = 3145728;
export const TICKET_STATUS_OPEN = 'Open';
export const TICKET_STATUS_CLOSE = 'Closed';

export const MAX_SEARCH_RESULTS_COUNT_PER_PAGE = 30;

export const OPEN_STREET_MAP_BASE_URL = 'https://tile.geofabrik.de/';
export const OPEN_STREET_STATIC_MAP_BASE_URL =
  'https://staticmap.geofabrik.de/';

export const apiConfig = {
  account: {
    login: '/account/login',
    logout: '/account/logout',
    passwordReset: '/account/password_reset',
    userInfo: '/account/users',
    changePassword: 'account/change-password',
    resendActivation: 'account/send-activation',
    setCurrency: userId => `account/users/${userId}/currency`,
    userLanguages: userID => `/account/users/${userID}/languages`,
    userCurrencies: userID => `/account/users/${userID}/currencies`,
    userBalances: userID => `/account/users/${userID}/balances`,
    getCommissionRates: userID => `account/users/${userID}/config`,
    getLanguages: userId => `account/users/${userId}/languages`,
    getCoreLanguages: '/core/languages',
    setLanguage: userId => `account/users/${userId}/language`,
    getCurrencies: userId => `account/users/${userId}/currencies`,
    userPermissions: userId => `account/users/${userId}/perms`,
    userPerms: userId => `account/users/${userId}/permissions`,
    setPriceMargin: userId => `account/users/${userId}/margin`,
    setCommission: userId => `account/users/${userId}/commission`,
    getWaitingBookings: () => `booking/my_bookings/?status=payment_processing`,
    contactEmail: userId => `account/users/${userId}/contacts`,
    contactEmailActions: (userId, emailId) =>
      `account/users/${userId}/contacts/${emailId}`,
    futureBalance: (userId, balanceDate) =>
      `account/users/${userId}/future_balances?balance_date=${balanceDate}`,
    loginHistories: (userId, limit) =>
      `account/users/${userId}/login_histories?limit=${limit}`,
    userInformation: userId => `account/users/${userId}`,
    loyalty: userId => `account/users/${userId}/loyalty`,
    reports: userId => `account/users/${userId}/reports`,
    financialInformation: agencyId => `agency/agencies/${agencyId}/financial`,
    agencyInformationDetail: agencyId => `agency/agencies/${agencyId}`,
    subagencyList: (agencyId, limit, offset, filterValue) =>
      `agency/agencies/${agencyId}/sub-agencies?limit=${limit}&offset=${offset}&search=${filterValue}`,
    agencyUsers: agencyId => `agency/agencies/${agencyId}/users`,
    agencyInformation: informationId => `agency/informations/${informationId}`,
    paymentMethods: agencyId => `agency/agencies/${agencyId}/payment-methods`,
    virtualBalance: agencyId => `agency/agencies/${agencyId}/balances`,
    authCheck: `/auth/check`,
    walletCard: `payment/wallet`,
    deleteWalletCard: cardId => `payment/wallet/${cardId}`,
  },
  agency: {
    postAgencySignup: `agency/agencies`,
    consortiaList: 'agency/info/consortia',
    hostAgencies: 'agency/info/host_agencies',
  },
  autocomplete: {
    search: query => `/auto_complete/search?query=${query}`,
    hotels: query => `/auto_complete/hotels?query=${query}`,
    destinations: query => `/auto_complete/destinations?query=${query}`,
    countries: query => `/auto_complete/countries?query=${query}`,
    maps: query => `/auto_complete/maps?query=${query}`,
  },
  bookings: {
    getUserBookings: args => `booking/my_bookings/?${args}`,
  },
  search: {
    getResults: (searchKey, args) =>
      `/search/search/${searchKey}/results${args}`,
    getFilters: searchKey => `/search/search/${searchKey}/filters`,
    searchResult: (searchKey, args) =>
      `/search/search/${searchKey}/results?${args}`,
    startSearch: (
      code,
      checkin,
      checkout,
      currencyCode,
      clientNationality,
      pax,
      _,
      maxProduct = 1,
    ) =>
      `/search/search?checkin=${checkin}&checkout=${checkout}&currency=${currencyCode}&client_nationality=${clientNationality}&${pax}${code}&max_product=${maxProduct}`,
    startMultiCitySearch: args => `/search/search?search_more=1${args}`,
    provision: productCode => `/search/product/${productCode}/provision`,
    /* eslint-disable camelcase */
    hotels: (
      checkin,
      checkout,
      currencyCode,
      clientNationality,
      pax,
      search_key,
      code,
      filters,
    ) =>
      `/hotels/?checkin=${checkin}&checkout=${checkout}&currency=${currencyCode}&client_nationality=${clientNationality}&${pax}&search_key=${search_key}${code}${filters}`,
  },
  destination: {
    info: code => `/destination/destinations/${code}`,
    place: id => `/destination/maps/${id}`,
    countries: `/destination/countries`,
    states: countryId => `/destination/countries/${countryId}/states`,
  },
  hotel: {
    images: (code, limit) => `/hotel/hotels/${code}/images?limit=${limit}`,
    getHotelDetailInfo: code => `/hotel/hotels/${code}/details`,
    getAvailability: (hotelCode, args) => `/search/hotels/${hotelCode}${args}`,
    getProvision: productCode => `/search/product/${productCode}/availability`,
    addBasket: `/basket/basket`,
    /* eslint-disable camelcase */
    searchResults: (
      hotel_code,
      checkin,
      checkout,
      currencyCode,
      clientNationality,
      pax,
      search_key,
      filters,
    ) =>
      `/hotel/hotels/${hotel_code}?checkin=${checkin}&checkout=${checkout}&currency=${currencyCode}&client_nationality=${clientNationality}&${pax}&search_key=${search_key}${filters}`,
    getOleryInfo: code => `/hotel/hotels/${code}/olery_data`,
  },
  checkout: {
    get: productCode => `/checkout/checkout/?product_code=${productCode}`,
    post: `/checkout/checkout`,
    chargeCurrency: args => `checkout/charge_currency${args}`,
    scOpenOrder: `/checkout/sc_open_order`,
    scGetStatusOpenedOrder: sessionToken =>
      `/checkout/sc_get_status_opened_order/${sessionToken}`,
  },
  payout: {
    get: bookingCode => `/payout/${bookingCode}`,
    post: bookingCode => `/payout/${bookingCode}`,
    scOpenOrder: bookingCode => `/payout/sc_open_order/${bookingCode}`,
    scGetStatusOpenedOrder: sessionToken =>
      `/checkout/sc_get_status_opened_order/${sessionToken}`,
  },
  couponCode: {
    couponCodeAvailability: `/coupon/code/availability`,
  },
  booking: {
    detail: bookingCode => `/booking/bookings/${bookingCode}`,
    notes: bookingCode => `/booking/bookings/${bookingCode}/notes`,
    cancel: bookingCode => `/booking/bookings/${bookingCode}/cancel`,
    search: (bookingCode, limit) =>
      `/booking/bookings/search?query=${bookingCode}&limit=${limit}`,
    screenshot: '/booking/images',
    createCouponCode: bookingCode =>
      `/booking/bookings/${bookingCode}/refund-residual`,
  },
  cms: {
    cardBrands: '/cms/contents/credit-card-brands',
    groupAccommodation: '/cms/forms/group-accommodation',
    fitAccommodation: '/cms/forms/fit-accommodation',
    transfer: '/cms/forms/transfer-request',
    termsAndConditions: '/cms/contents/terms-conditions',
    privacyPolicy: '/cms/contents/privacy',
    tradeFairs: '/cms/forms/trade-fair',
    hotelSignup: `/cms/forms/partner`,
    bankDetails: '/cms/contents/bank-details',
    loyaltyTerms: '/cms/contents/loyalty-terms-conditions',
  },
  faq: {
    termsAndConditions: '/faq/categories/termsconditions/questions',
    privacyPolicy: '/faq/categories/privacy/questions',
    categories: `faq/categories`,
    category: categoryKey => `faq/categories/${categoryKey}`,
    categoryQuestions: categoryKey => `faq/categories/${categoryKey}/questions`,
  },
  marketing: {
    tradeFairs: '/marketing/trade-fairs',
    additionalUrls: '/marketing/urls',
  },
  getHelp: {
    offices: '/company/offices',
    tollFreeNumbers: '/company/toll_frees',
    mainSubjects: '/contact_us_subject_type',
    subjects: '/contact_us_subject',
    createTicket: '/contact_us_subject/create_ticket',
  },
  tickets: {
    getTickets: args => `/ticket/tickets${args}`,
    getTicketDetail: ticketId => `ticket/tickets/${ticketId}`,
    updateTicket: ticketId => `ticket/tickets/${ticketId}`,
    categories: bookingCode => `/ticket/categories?booking_code=${bookingCode}`,
    create: `ticket/tickets`,
    getEmails: userId => `sent_emails/${userId}/user_emails`,
  },
  favourites: {
    collections: '/collection/collections',
    collectionItems: '/collection/items',
    removeItemFromCollection: itemId => `/collection/items/${itemId}`,
    collectionItemsCheck: hotelId => `/collection/items?hotel_id=${hotelId}`,
    getCollectionItems: collectionId =>
      `/collection/collections/${collectionId}/items`,
  },
  profit: {
    bonuses: userId => `/account/users/${userId}/loyalty`,
    withdrawHistory: userId =>
      `/account/users/${userId}/loyalty/withdraw_histories`,
    createWallet: userId => `/account/users/${userId}/loyalty/account`,
    requestPayment: userId => `/account/users/${userId}/loyalty/payment`,
    activateAgreement: userId => `/account/users/${userId}/loyalty/activate`,
  },
  activities: {
    toursAndActivities: '/activities/transfer',
  },
  transfer: {
    search: params => `/transfer/transfers/search?${params}`,
    airports: query => `/transfer/terminals/airports?query=${query}`,
    addTransferBasket: `/basket/transfers`,
    removeTransferBasket: `/basket/transfers`,
  },
  paytrek: {
    cardFee: '/payment/options/',
    tokenization: '/payment/tokenization/',
  },
};

export const chinaUrl = 'china.hprotravel.com';
const isChinaDomain = window.location.hostname === chinaUrl;

export const externalUrls = {
  invoice: invoiceUrl =>
    isChinaDomain
      ? `https://china.hotelspro.com${invoiceUrl}`
      : `https://www.hotelspro.com${invoiceUrl}`,
  proforma: proformaUrl =>
    isChinaDomain
      ? `https://china.hotelspro.com${proformaUrl}`
      : `https://www.hotelspro.com${proformaUrl}`,
  creditCardAuthorization: bookingCode =>
    isChinaDomain
      ? `https://china.hotelspro.com/bookings/ccard_authorization_form/${bookingCode}`
      : `https://www.hotelspro.com/bookings/ccard_authorization_form/${bookingCode}`,
  invoiceP1: (code, token) =>
    isChinaDomain
      ? `https://china.hotelspro.com/api/v2/booking/invoice/${code}/?_token=${token}`
      : `https://www.hotelspro.com/api/v2/booking/invoice/${code}/?_token=${token}`,
};

export const baseApiUrl = isChinaDomain ? CHINA_API_URL : API_URL;
console.log('baseApiUrl', baseApiUrl); // eslint-disable-line no-console
console.log(window.location.hostname, isChinaDomain, CHINA_API_URL, API_URL); // eslint-disable-line no-console
