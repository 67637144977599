import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import classNames from 'classnames';

import { OPEN_STREET_MAP_KEY, OPEN_STREET_STATIC_MAP_BASE_URL } from 'config';

import './style.scss';

const StaticMap = props => {
  const { longitude, latitude, width, height, zoom, className, alt } = props;
  const classes = classNames('static-map', className);
  const mapProps = omit(
    props,
    'longitude',
    'latitude',
    'zoom',
    'width',
    'height',
    'alt',
  );

  return (
    <img
      alt={alt}
      className={classes}
      src={`${OPEN_STREET_STATIC_MAP_BASE_URL}${OPEN_STREET_MAP_KEY}?maptype=default&attribution=false&center=${latitude},${longitude}&size=${width}x${height}&zoom=${zoom}`}
      {...mapProps}
    />
  );
};

StaticMap.propTypes = {
  zoom: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
};

StaticMap.defaultProps = {
  zoom: 15,
  width: 100,
  height: 100,
  alt: 'default_map',
};

export default StaticMap;
