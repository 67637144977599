import {
  GET_OLERY_DATA_REQUESTED,
  GET_OLERY_DATA_SUCCEEDED,
  GET_OLERY_DATA_FAILED,
} from './constants';

// olery action creators

export const getOleryDataRequested = hotelCode => ({
  type: GET_OLERY_DATA_REQUESTED,
  hotelCode,
});

export const getOleryDataSucceeded = results => ({
  type: GET_OLERY_DATA_SUCCEEDED,
  results,
});

export const getOleryDataFailed = reason => ({
  type: GET_OLERY_DATA_FAILED,
  reason,
});
