import styled from 'styled-components';
import List from 'components/List';
import Menu from 'components/Menu';

const DepositBalance = styled.div`
  height: 100%;
`;

const DepositBalanceList = styled(List)`
  position: absolute;
  top: 100%;
  left: 0;
`;

const DepositBalanceMenu = styled(Menu)`
  display: flex;
  height: 100%;
  background-color: ${props => (props.isOpened ? '#f2f1f6' : '#FFF')};
  padding-left: 16px;
  padding-right: 20px;
`;

export { DepositBalance, DepositBalanceList, DepositBalanceMenu };
