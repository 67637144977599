import { chinaUrl } from 'config';

export function qs(selector, scope) {
  return (scope || document).querySelector(selector);
}

export function qsa(selector, scope) {
  return [...(scope || document).querySelectorAll(selector)];
}

export function $on(target, type, callback, capture) {
  if (target) {
    target.addEventListener(type, callback, !!capture);
  }
}

export function $off(target, type, callback, capture) {
  if (target) {
    target.removeEventListener(type, callback, !!capture);
  }
}

export function getFieldByName(fieldName) {
  return document.getElementsByName(fieldName)
    ? document.getElementsByName(fieldName)[0] &&
        document.getElementsByName(fieldName)[0].value
    : null;
}

export const scrollToElement = (id, options = {}) => {
  const scrollOptions = options;
  scrollOptions.behavior = 'smooth';
  qs(`#${id}`).scrollIntoView(scrollOptions);
};

export const allowOnlyAsciiChars = e => {
  const theEvent = e || window.event;

  let key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode(key);
  const regexSpecialCharacters = /[^'!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~'0123456789]/;
  theEvent.persist();
  if (!regexSpecialCharacters.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) {
      theEvent.preventDefault();
    }
  }
};

export const allowOnlyNumbers = e => {
  const theEvent = e || window.event;
  let key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode(key);
  const regex = /[0-9]|\./;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};

// Fıstıkçı Şahap => Fistikci Sahap
export const replaceSpecialChars = str => {
  const lettersMap = {
    Ğ: 'G',
    Ü: 'U',
    Ş: 'S',
    İ: 'I',
    Ö: 'O',
    Ç: 'C',
    ğ: 'g',
    ü: 'u',
    ş: 's',
    ı: 'i',
    ö: 'o',
    ç: 'c',
  };

  return Array.from(str)
    .map(
      char =>
        typeof lettersMap[char] !== 'undefined' ? lettersMap[char] : char,
    )
    .join('');
};

export const copyText = text => {
  const elm = document.createElement('textarea');
  elm.value = text;
  elm.setAttribute('readonly', '');
  elm.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(elm);
  elm.select();
  document.execCommand('copy');
  document.body.removeChild(elm);
};

export const isChinaDomain = window.location.hostname === chinaUrl;
