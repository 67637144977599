import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'components/TripAdvisorRating/messages';

const tripadvisorText = rating => {
  const ratingText = {
    '0': <FormattedMessage {...messages.terrible} />,
    '0.5': <FormattedMessage {...messages.terrible} />,
    '1': <FormattedMessage {...messages.terrible} />,
    '1.5': <FormattedMessage {...messages.poor} />,
    '2': <FormattedMessage {...messages.poor} />,
    '2.5': <FormattedMessage {...messages.average} />,
    '3': <FormattedMessage {...messages.standart} />,
    '3.5': <FormattedMessage {...messages.standart} />,
    '4': <FormattedMessage {...messages.excellent} />,
    '4.5': <FormattedMessage {...messages.excellent} />,
    '5': <FormattedMessage {...messages.excellent} />,
  };

  return ratingText[rating] || '';
};

const tripadvisorColor = rating => {
  const ratingColor = {
    '0': '#dacc27',
    '0.5': '#dacc27',
    '1': '#dacc27',
    '1.5': '#dacc27',
    '2': '#dacc27',
    '2.5': '#dacc27',
    '3': '#dacc27',
    '3.5': '#dacc27',
    '4': '#a1da27',
    '4.5': '#a1da27',
    '5': '#27da58',
  };

  return ratingColor[rating] || '#626262';
};

export { tripadvisorColor, tripadvisorText };
