import styled from 'styled-components';

import { Icon } from 'components/Icon/style';

const Review = styled.div`
  display: inline-flex;
  align-items: center;
  opacity: 0.9;
  font-size: 13px;
  line-height: 1.62;
  letter-spacing: 0.2px;
  color: #626262;
  ${Icon} {
    margin-right: 8px;
  }
`;

export { Review };
