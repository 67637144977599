import styled, { css } from 'styled-components';
import { Icon } from '../Icon/style';

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  border: 1px solid #e7e5ef;
  background-color: #fff;
  position: relative;
  min-width: 200px;
  ${props =>
    props.isOpen &&
    css`
      visibility: hidden;
      opacity: 0;
      display: none;
    `};

  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 20px;
    top: 0;
    transform: rotate(-45deg) translateY(-10px);
    background-color: #fff;
    border: 1px solid #e7e5ef;
    border-bottom: 0;
    border-left: 0;
    border-radius: 0px 4px;
    z-index: 1;
  }
  ${props =>
    !props.isLoaded &&
    css`
      align-items: center;
      justify-content: center;
    `};
`;

const Header = styled.span`
  display: flex;
  color: #949494;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  padding: 20px 16px 4px;
  white-space: nowrap;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 0;
  max-height: 360px;
  overflow: auto;
  margin: 0;
`;

const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ListItem = styled.li`
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.2px;
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
  transition: 0.2s;
  ${Icon} {
    flex-shrink: 1;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
  &:hover {
    background-color: #f2f1f6;
  }
  ${props =>
    props.active &&
    css`
      color: #3A8DC3;
    `};
`;

const ItemCode = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  margin-right: 15px;
`;

export { Wrapper, Header, List, ListItem, ItemCode, ListItemWrapper };
