import { defineMessages } from 'react-intl';

export const scope = 'app.components.Sidebar';

export default defineMessages({
  overview: {
    id: `${scope}.overview`,
    defaultMessage: 'Overview',
  },
  homepage: {
    id: `${scope}.homepage`,
    defaultMessage: 'Homepage',
  },
  myInbox: {
    id: `${scope}.myInbox`,
    defaultMessage: 'My Inbox',
  },
  bookings: {
    id: `${scope}.bookings`,
    defaultMessage: 'Bookings',
  },
  favorites: {
    id: `${scope}.favorites`,
    defaultMessage: 'Favorites',
  },
  profit: {
    id: `${scope}.profit`,
    defaultMessage: 'Profit',
  },
  reports: {
    id: `${scope}.reports`,
    defaultMessage: 'Reports',
  },
  termsConditions: {
    id: `${scope}.termsConditions`,
    defaultMessage: 'Terms & Conditions',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy Policy',
  },
  tradeFairs: {
    id: `${scope}.tradeFairs`,
    defaultMessage: 'Trade Fairs',
  },
  contactUs: {
    id: `${scope}.contactUs`,
    defaultMessage: 'Contact Us',
  },
  faq: {
    id: `${scope}.faq`,
    defaultMessage: 'FAQ',
  },
  bankDetails: {
    id: `${scope}.bankDetails`,
    defaultMessage: 'Bank Details',
  },
  blog: {
    id: `${scope}.blog`,
    defaultMessage: 'Blog',
  },
  offlineRequest: {
    id: `${scope}.offlineRequest`,
    defaultMessage: 'Offline Request',
  },
  groupAccommodation: {
    id: `${scope}.groupAccommodation`,
    defaultMessage: 'Group Accommodation',
  },
  fitAccommodation: {
    id: `${scope}.fitAccommodation`,
    defaultMessage: 'FIT Accommodation',
  },
  transfer: {
    id: `${scope}.transfer`,
    defaultMessage: 'Transfer',
  },
  switchToOldHProTravel: {
    id: `${scope}.switchToOldHProTravel`,
    defaultMessage: 'Switch to old HProTravel',
  },
  switchToOldVersion: {
    id: `${scope}.switchToOldVersion`,
    defaultMessage: 'Switch to old Version',
  },
});
