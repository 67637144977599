import React from 'react';
import PropTypes from 'prop-types';

import Notification from 'containers/Notification';

import { checkShownSwitchDesignButton } from 'utils/switchOldDesign';

import { Header } from '../Header';
import Footer from '../Footer';

import * as S from './style';

const MainLayout = props => {
  const { children, center, showNotification } = props;
  const layoutProps = { center };
  return (
    <>
      {checkShownSwitchDesignButton() && <Notification />}
      <S.Layout showNotification={showNotification}>
        <S.Wrapper {...layoutProps}>
          <Header />
          <S.Content className="layout-content">{children}</S.Content>
          <Footer />
        </S.Wrapper>
      </S.Layout>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  center: PropTypes.bool,
};

export default MainLayout;
