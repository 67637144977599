import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'components/Icon';

import * as S from './style';

const Review = props => {
  const { children, className, isNegative } = props;
  const classes = classNames('review', className);
  const iconName = isNegative ? 'decrease' : 'checkCircle';
  const iconColor = isNegative ? '#adadad' : '#3A8DC3';

  return (
    <S.Review className={classes}>
      <Icon name={iconName} width={18} color={iconColor} />
      {children}
    </S.Review>
  );
};

Review.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isNegative: PropTypes.bool,
};

export default Review;
