import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, FormattedPlural } from 'react-intl';

import { TRIPADVISOR_ENABLED } from 'config';

import { tripadvisorText, tripadvisorColor } from 'utils/tripAdvisor';

import Icon from 'components/Icon';
import Badge from 'components/Badge';
import * as S from './style';
import messages from './messages';

const TripAdvisorRating = props => {
  if (!JSON.parse(TRIPADVISOR_ENABLED)) {
    return null;
  }
  const { rating, hasBadge, className, reviewCount } = props;
  const classes = classNames('tripadvisor-rating', className);
  const ratingProps = {
    color: tripadvisorColor(rating),
    hasBadge,
    reviewCount,
  };
  const text = tripadvisorText(rating);
  return (
    <S.TripAdvisorRating className={classes} {...ratingProps}>
      {hasBadge && <Badge color="#FFF">{rating}</Badge>}
      {reviewCount ? (
        <S.WithReviewCount>
          <S.RatingText>{text}</S.RatingText>
          <S.ReviewCountText>
            <FormattedMessage
              {...messages.reviewText}
              values={{
                count: reviewCount,
                message: (
                  <FormattedPlural
                    value={reviewCount}
                    one={<FormattedMessage {...messages.review} />}
                    other={<FormattedMessage {...messages.reviews} />}
                  />
                ),
              }}
            />
          </S.ReviewCountText>
        </S.WithReviewCount>
      ) : (
        <>
          <S.RatingText>{text}</S.RatingText>
          <Icon name="tripadvisor" width="20" color="#00a680" />
        </>
      )}
    </S.TripAdvisorRating>
  );
};

TripAdvisorRating.defaultProps = {
  rating: 5,
  hasBadge: false,
};

TripAdvisorRating.propTypes = {
  rating: PropTypes.number,
  hasBadge: PropTypes.bool,
  className: PropTypes.string,
};

export default TripAdvisorRating;
