import { HOME, MY_BOOKINGS, INBOX } from 'containers/App/routes';

export const menuList = [
  {
    key: '0',
    iconName: 'home',
    href: HOME,
    name: 'homepage',
  },
  {
    key: '1',
    iconName: 'mark',
    href: MY_BOOKINGS,
    name: 'bookings',
  },
  // {
  //   key: '2',
  //   iconName: 'report',
  //   href: REPORTS,
  //   name: 'reports',
  //   isShownSubList: false,
  // },
  // {
  //   key: '3',
  //   iconName: 'offlineRequest',
  //   href: OFFLINE_REQUEST,
  //   name: 'offlineRequest',
  //   isShownSubList: false,
  //   subItems: [
  //     {
  //       key: '0',
  //       href: GROUP_ACCOMMODATION,
  //       name: 'groupAccommodation',
  //     },
  //     {
  //       key: '1',
  //       href: FIT_ACCOMMODATION,
  //       name: 'fitAccommodation',
  //     },
  //     {
  //       key: '2',
  //       href: TRANSFER,
  //       name: 'transfer',
  //     },
  //   ],
  // },
  {
    key: '4',
    href: INBOX,
    name: 'myInbox',
  },
];
