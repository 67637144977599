import { defineMessages } from 'react-intl';

export const scope = 'app.components.DepositBalance';

export default defineMessages({
  deposit: {
    id: `${scope}.deposit`,
    defaultMessage: 'Deposit',
  },
});
