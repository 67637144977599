import styled, { css } from 'styled-components';

const Badge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: #e6e5ee;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  ${props =>
    props.color &&
    css`
      border: 1px solid;
      color: ${props.color || '#3A8DC3'};
      background-color: transparent;
    `};
`;

export { Badge };
