import styled, { css } from 'styled-components';
import { Stars } from 'components/Stars/style';
import { Icon } from 'components/Icon/style';
import { Button } from 'components/Button/style';

const HotelHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 19px;
  justify-content: space-between;
  padding-top: 24px;
  align-items: baseline;
  ${props =>
    props.isModal &&
    css`
      margin-bottom: 0;
      ${AddressText} {
        color: #bebebe;
      }

      ${Title} {
        color: #ffffff;
      }
    `};

  ${props =>
    props.isPdf &&
    css`
      padding-top: 12px;
      ${Title} {
        font-size: 14px;
      }
    `};

  ${props =>
    props.hasCopyIcon &&
    css`
      padding-top: 0;
      ${TitleContainer} {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 3px;
      }

      ${Title} {
        font-size: 20px;
        letter-spacing: 0.2px;
        line-height: 20px;
      }

      ${AddressText} {
        font-size: 13px;
      }
    `};

  ${props =>
    props.isCreateOffer &&
    css`
      padding-top: 0;
      margin-bottom: 0;
      ${Title} {
        font-size: 18px;
        line-height: 27px;
      }
      ${AddressText} {
        font-size: 11px;
        letter-spacing: 0.16px;
      }
      ${DetailsLocation} {
        align-items: center;
        margin-top: 3px;
        ${Icon} {
          width: 14px;
        }
      }
      ${TitleContainer} {
        ${Icon} {
          width: 10px;
        }
      }
    `};

  ${props =>
    props.isVoucher &&
    css`
      padding-top: 6px;
      margin-bottom: 0;
      ${Title} {
        font-size: 15px;
        line-height: 20px;
      }
      ${AddressText} {
        max-width: 192px;
        font-size: 10px;
        letter-spacing: 0.2px;
        line-height: 13px;
      }
      ${DetailsLocation} {
        align-items: flex-start;
        margin-top: 3px;
        ${Icon} {
          width: 10px;
        }
      }
      ${TitleContainer} {
        ${Icon} {
          width: 10px;
        }
      }
      ${DetailsPhoneNumber} {
        align-items: baseline;
        ${Icon} {
          width: 10px;
        }
      }
      ${PhoneText} {
        font-size: 10px;
        letter-spacing: 0.25px;
      }
    `};
  ${props =>
    props.isTransfer &&
    css`
      ${Title} {
        width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `};

  ${props =>
    props.titleDirection &&
    css`
      ${TitleContainer} {
        align-items: flex-start;
        flex-direction: ${props.titleDirection || 'row'};
      }

      ${DetailsLocation} {
        margin-top: 12px;
      }
    `};
`;

const TitleWithCopy = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const CopyText = styled.span`
  color: #f9a11b;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.22px;
  line-height: 21px;
  margin-left: 5px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  ${Stars} {
    margin-right: 20px;
  }
`;

const DetailsLocation = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 9px;
`;

const DetailsPhoneNumber = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 9px;
`;

const Title = styled.h1`
  color: #303030;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  margin-right: 13px;
  word-break: break-word;
`;

const AddressText = styled.p`
  margin-left: 6px;
  color: #626262;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 23px;
`;

const PhoneText = styled.p`
  margin-left: 6px;
  color: #626262;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
`;

const Rating = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Review = styled.p`
  color: #f9a11b;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.22px;
  line-height: 21px;
  text-align: right;
`;

const RateButton = styled(Button)``;

export {
  HotelHeader,
  DetailsContainer,
  TitleContainer,
  TitleWithCopy,
  CopyText,
  DetailsLocation,
  DetailsPhoneNumber,
  Title,
  AddressText,
  PhoneText,
  Rating,
  Review,
  RateButton,
};
