import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Icon } from '../Icon/style';

const FooterWrapper = styled.div`
  display: flex;
  position: relative;
  padding-right: 128px;
  padding-left: 128px;
  justify-content: space-between;
  min-height: 95px;
  align-items: center;
  background-color: #3a8dc3;
`;

const FooterListContainer = styled.ul`
  display: flex;
  right: 128px;
  align-items: center;
`;

const FooterItem = styled(NavLink)`
  display: flex;
  cursor: pointer;
  color: #e7e5ef;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 20px;
  max-width: 100%;
  justify-content: flex-start;
  &.active {
    color: #fff;
  }
`;

const Item = styled.div`
  cursor: pointer;
  height: 42px;
  &:hover {
    ${ItemName}, ${Icon} {
      color: #fff;
    }
  }
  ${Icon} {
    flex: 0 0 auto;
    color: #a39dd1;
  }
`;

const ItemName = styled.span`
  color: #a39dd1;
  font-size: 12px;
  font-weight: 500;
  padding-left: 16px;
`;

const FooterItemWrapper = styled.li`
  margin-left: 24px;
  &:hover {
    ${FooterItem} {
      color: #fff;
    }
  }
`;

const SwitchBack = styled.div`
  background-color: #f8a119;
  max-width: 175px;
  overflow: hidden;
  ${Item} {
    height: 36px;
  }
  &&& {
    .active {
      background-color: #f8a119;
    }
    ${ItemName} {
      padding-left: 10px;
      font-size: 12px;
      color: #19143e;
    }
    ${Icon} {
      color: #19143e;
    }
  }
  ${ItemWrapper}:hover {
    ${ItemName}, ${Icon} {
      color: #19143e;
    }
  }
`;

const Navigation = styled(NavLink)`
  display: flex;
  height: 100%;
  ${props =>
    props.active &&
    css`
      background-color: rgba(93, 80, 204, 0.4);
      ${Icon}, ${ItemName} {
        color: #fff;
      }
    `} &.active {
    background-color: rgba(93, 80, 204, 0.4);
    ${Icon}, ${ItemName} {
      color: #fff;
    }
  }
`;
const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 16px;
  height: 100%;
  width: 100%;
`;

const LogoWrapper = styled.figure`
  width: 100%;
  margin-bottom: 17px;
`;

const Logo = styled.img`
  height: 66px;
`;

const SwitchToOldHpro = styled.div`
  diplay: flex;
  flex-direction: column;
  margin-bottom: -15px;
`;

export {
  FooterItemWrapper,
  FooterListContainer,
  FooterItem,
  SwitchBack,
  Navigation,
  Item,
  ItemName,
  ItemWrapper,
  FooterWrapper,
  LogoWrapper,
  Logo,
  SwitchToOldHpro,
};
