import React from 'react';
import PropTypes from 'prop-types';

import Header from '../LandingHeader';
import Footer from '../LandingFooter';

const LandingLayout = props => {
  const { children } = props;
  return (
    <>
      <Header />
      <div className="layout-content">
        {children}
        <Footer />
      </div>
    </>
  );
};

LandingLayout.propTypes = {
  children: PropTypes.node,
};

export default LandingLayout;
