// Home
export const HOME = '/search';

// Auth routes
export const LOGIN = '/account/login';
export const RESET_PASSWORD = '/account/password_reset/:uidb64/:token';

export const BOOKINGS_BASE = '/bookings/';
export const BOOKING_DETAILS = '/bookings/:booking_code';

// Search Results
export const SEARCH_RESULTS = '/hotels';

// Hotel Detail
export const HOTEL_DETAIL = '/hotel/hotels/:hotel_code';

// Create Offer
export const CREATE_OFFER_BASE = '/create-offer';
export const CREATE_OFFER = '/create-offer/:hotel_code';

// Trade Fairs
export const TRADE_FAIRS = '/trade-fairs';

// Trade Fairs Form
export const TRADE_FAIRS_FORM = '/trade-fairs-form';

// Privacy Policy
export const PRIVACY_POLICY = '/privacy-policy';

// Terms & Conditions
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';

// Checkout
export const CHECKOUT = '/checkout';

// Payout
export const PAYOUT_BASE = '/payout';
export const PAYOUT = '/payout/:booking_code';

// Create Offer Pdf
export const CREATE_OFFER_PDF = '/create-offer-pdf';

// Thank You Page
export const THANK_YOU_BASE = '/thank_you';
export const THANK_YOU = '/thank_you/:booking_code';

// Transfer Form
export const TRANSFER_FORM = '/transfer-form';

// Voucher
export const VOUCHER_BASE = '/voucher/';
export const VOUCHER = '/voucher/:booking_code';

// My Account
export const MY_ACCOUNT = '/account/profile';

// Agency Information Editor
export const AGENCY_INFORMATION_EDITOR = '/account/agency-information/edit';

// SubAgency Information Editor
export const SUBAGENCY_INFORMATION_PATH = '/account/subagency-information/';
export const SUBAGENCY_INFORMATION_EDITOR =
  '/account/subagency-information/:code';

// Add New User Account
export const ADD_NEW_USER_PATH = '/account/add-new-user';
export const ADD_NEW_USER = '/account/add-new-user/:code';

// Get Help Page
export const GET_HELP = '/get-help';

// Profit Page
export const PROFIT = '/loyalty';

// Tours Link
export const TOURS = '/activities/';

// Bookings Page
export const MY_BOOKINGS = '/bookings/my_bookings';

// Sign Up
export const SIGNUP = '/account/sign-up';

// Offline Requests Base
export const OFFLINE_REQUEST = '/offline-request/';

// Group Accommodation Page
export const GROUP_ACCOMMODATION = '/offline-request/group-accommodation';

// FIT Accommodation Page
export const FIT_ACCOMMODATION = '/offline-request/fit-accommodation';

// Transfer Page
export const TRANSFER = '/offline-request/transfer';

// Faq Page
export const FAQ = '/faq';

// External Urls
export const EXTERNAL_URLS = {
  fairsForm: (name, city, startDate, endDate) =>
    `https://metflow.metglobal.com/forms/hotelspro-meeting-request-form/0?&fair_name=${name}-${city}&fair_date=${startDate}-${endDate}`,
  toursAndActivities: 'https://tour-partner.hotelspro.com',
  blog: ``,
  payout: 'https://payout.hotelspro.com',
  oldHpro: 'https://www.hprotravel.com',
};

// My Inbox Page
export const INBOX = '/account/tickets';

// My Email Page
export const EMAIL = '/account/emails';

// Base My Inbox Page
export const INBOX_BASE = '/account/tickets/';

// Base Email Detail
export const EMAIL_BASE = '/account/emails/';

// Favourites
export const MY_FAVOURITES_BASE = '/my-favorites';
export const MY_FAVOURITES = '/my-favorites/:collection_id/:collection_name';

// Collections
export const COLLECTIONS = '/my-favorites';

// Ticket detail
export const TICKET_DETAIL = '/account/tickets/:ticket_id';

// Car Rental
export const CAR_RENTAL = '/car-rental';

// Email detail
export const EMAIL_DETAIL = '/account/emails/:email_id';

// Reports
export const REPORTS = '/reports';

// Bank Details
export const BANK_DETAILS = '/bank-details';

// Landing
export const LANDING = '/';

// Products
export const PRODUCTS = '/products';

// Offices
export const OFFICES = '/offices';

// Meeting Requests
export const MEETING_REQUESTS = '/meetings-requests';

// Landing Trade Fairs
export const TRADE_FAIRS_LANDING = '/landing/trade-fairs';

// Landing Trade Fairs Form
export const TRADE_FAIRS_LANDING_FORM = '/landing/trade-fairs-form';

// Landing Offices
export const LANDING_OFFICES = '/landing/offices';

// Landing Products
export const LANDING_PRODUCTS = '/landing/products';

// Landing Privacy Policy
export const LANDING_PRIVACY_POLICY = '/landing/privacy-policy';

// Landing Terms Conditions
export const LANDING_TERMS_CONDITIONS = '/landing/terms-conditions';

// Landing FAQ
export const LANDING_FAQ = '/landing/faq';

// Landing Help
export const LANDING_HELP = '/landing/help';
