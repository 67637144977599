import { fromJS } from 'immutable';

import * as Status from 'utils/status';

import { storage } from 'utils/storage';

import {
  GET_COMMISSIONS_REQUESTED,
  GET_COMMISSIONS_SUCCEEDED,
  GET_COMMISSIONS_FAILED,
  SET_COMMISSION_RATE_REQUESTED,
  SET_COMMISSION_RATE_SUCCEEDED,
  SET_COMMISSION_RATE_FAILED,
  SET_PRICE_MARGIN_REQUESTED,
  SET_PRICE_MARGIN_SUCCEEDED,
  SET_PRICE_MARGIN_FAILED,
} from './constants';

export const initialState = fromJS({
  reason: null,
  status: Status.INIT,
  commissions: [],
  commissionRate: storage.getItem('COMMISSION_RATE'),
  priceMargin: storage.getItem('PRICE_MARGIN'),
  setPriceMargin: {
    status: Status.INIT,
    reason: null,
  },
});

function commissionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMMISSIONS_REQUESTED:
      return state.set('status', Status.LOADING);
    case GET_COMMISSIONS_SUCCEEDED:
      return state
        .set('commissions', action.results)
        .set('status', Status.LOADED);
    case GET_COMMISSIONS_FAILED:
      return state.set('reason', action.reason).set('status', Status.FAILED);
    case SET_COMMISSION_RATE_REQUESTED:
      return state.set('status', Status.LOADING);
    case SET_COMMISSION_RATE_SUCCEEDED:
      storage.setItem('COMMISSION_RATE', action.commissionRate);
      return state
        .set('commissionRate', action.commissionRate)
        .set('status', Status.LOADED);
    case SET_COMMISSION_RATE_FAILED:
      return state.set('reason', action.reason).set('status', Status.FAILED);
    case SET_PRICE_MARGIN_REQUESTED:
      return state.setIn(['setPriceMargin', 'status'], Status.LOADING);
    case SET_PRICE_MARGIN_SUCCEEDED:
      storage.setItem('PRICE_MARGIN', action.priceMargin);
      return state
        .set('priceMargin', action.priceMargin)
        .set('status', Status.LOADED);
    case SET_PRICE_MARGIN_FAILED:
      return state
        .setIn(['setPriceMargin', 'status'], Status.FAILED)
        .setIn(['setPriceMargin', 'reason'], action.reason);
    default:
      return state;
  }
}

export default commissionReducer;
