import {
  LOGIN_REQUESTED,
  LOGIN_FORM_SENT,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT_FAILED,
  LOGOUT_SUCCEEDED,
  LOGOUT_REQUESTED,
  LOGIN_WITH_COOKIE_REQUESTED,
} from './constants';

// Login action creators
export const loginRequested = payload => ({
  type: LOGIN_REQUESTED,
  payload,
});

export const loginFormSent = otpPubKey => ({
  type: LOGIN_FORM_SENT,
  otpPubKey,
});

export const loginWithCookieRequested = (token, userId) => ({
  type: LOGIN_WITH_COOKIE_REQUESTED,
  token,
  userId,
});

export const loginSucceeded = (token, userId) => ({
  type: LOGIN_SUCCEEDED,
  token,
  userId,
});

export const loginFailed = reason => ({
  type: LOGIN_FAILED,
  reason,
});

export const logoutRequested = payload => ({
  type: LOGOUT_REQUESTED,
  payload,
});

export const logoutSucceeded = data => ({
  type: LOGOUT_SUCCEEDED,
  data,
});

export const logoutFailed = reason => ({
  type: LOGOUT_FAILED,
  reason,
});
