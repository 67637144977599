const memoryStorage = {};

function setItem(key, value) {
  try {
    if (localStorage) {
      // Safari with privacy options will have localStorage
      // but won't let us write to it.
      localStorage.setItem(key, value);
    } else {
      // Android WebView might not have localStorage at all.
      memoryStorage[key] = value;
    }
  } catch (err) {
    /* eslint-disable no-console */
    console.warn(
      'Hprotravel.com local storage warn: localStorage not available; falling back on memory storage',
    );
    memoryStorage[key] = value;
  }
}

function getItem(key) {
  let value;

  if (localStorage) {
    value = localStorage.getItem(key) || memoryStorage[key];
  } else {
    value = memoryStorage[key];
  }

  // per localStorage spec, it returns null when not found
  return value || null;
}

function removeItem(key) {
  if (localStorage) {
    localStorage.removeItem(key);
  }
  delete memoryStorage[key];
}

function getParsedValue(value) {
  let falsyValue;
  if (localStorage.getItem(value) === null) {
    falsyValue = false;
  } else {
    falsyValue = typeof localStorage.getItem(value) === 'object' ? {} : false;
  }
  return localStorage.getItem(value)
    ? JSON.parse(localStorage.getItem(value))
    : falsyValue;
}

export const storage = {
  setItem,
  getItem,
  removeItem,
  getParsedValue,
};
