import { fromJS } from 'immutable';

import * as Status from 'utils/status';

import {
  GET_BOOKING_INFO_SUCCEEDED,
  GET_BOOKING_INFO_REQUESTED,
  GET_BOOKING_INFO_FAILED,
  GET_BOOKING_NOTES_REQUESTED,
  GET_BOOKING_NOTES_SUCCEEDED,
  GET_BOOKING_NOTES_FAILED,
  ADD_BOOKING_NOTE_SUCCEEDED,
  ADD_BOOKING_NOTE_REQUESTED,
  ADD_BOOKING_NOTE_FAILED,
  GET_BOOKING_SEARCH_REQUESTED,
  GET_BOOKING_SEARCH_SUCCEEDED,
  GET_BOOKING_SEARCH_FAILED,
  CANCEL_BOOKING_REQUESTED,
  CANCEL_BOOKING_SUCCEEDED,
  CANCEL_BOOKING_FAILED,
} from './constants';

export const initialState = fromJS({
  booking: {
    data: {},
    status: Status.INIT,
    reason: null,
  },
  notes: {
    data: [],
    status: Status.INIT,
    reason: null,
  },
  addNote: {
    status: Status.INIT,
    data: {},
    reason: null,
  },
  bookingSearch: {
    reason: null,
    status: Status.INIT,
    data: [],
  },
  cancelBooking: {
    reason: null,
    status: Status.INIT,
  },
});

function bookDetailReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOOKING_INFO_REQUESTED:
      return state.setIn(['booking', 'status'], Status.LOADING);
    case GET_BOOKING_INFO_SUCCEEDED:
      return state
        .setIn(['booking', 'data'], action.booking)
        .setIn(['booking', 'status'], Status.LOADED);
    case GET_BOOKING_INFO_FAILED:
      return state
        .setIn(['booking', 'reason'], action.reason)
        .setIn(['booking', 'status'], Status.FAILED);
    case GET_BOOKING_NOTES_REQUESTED:
      return state.setIn(['notes', 'status'], Status.LOADING);
    case GET_BOOKING_NOTES_SUCCEEDED:
      return state
        .setIn(['notes', 'data'], action.notes.objects)
        .setIn(['notes', 'status'], Status.LOADED);
    case GET_BOOKING_NOTES_FAILED:
      return state
        .setIn(['notes', 'reason'], action.reason)
        .setIn(['notes', 'status'], Status.FAILED);
    case ADD_BOOKING_NOTE_REQUESTED:
      return state.setIn(['addNote', 'status'], Status.LOADING);
    case ADD_BOOKING_NOTE_SUCCEEDED:
      return state
        .setIn(['addNote', 'data'], action.status)
        .setIn(['addNote', 'status'], Status.LOADED);
    case ADD_BOOKING_NOTE_FAILED:
      return state
        .setIn(['addNote', 'status'], Status.FAILED)
        .setIn(['addNote', 'reason'], action.reason);
    case GET_BOOKING_SEARCH_REQUESTED:
      return state.setIn(['bookingSearch', 'status'], Status.LOADING);
    case GET_BOOKING_SEARCH_SUCCEEDED:
      return state
        .setIn(['bookingSearch', 'data'], action.results.objects)
        .setIn(['bookingSearch', 'status'], Status.LOADED);
    case GET_BOOKING_SEARCH_FAILED:
      return state
        .setIn(['bookingSearch', 'reason'], action.reason)
        .setIn(['bookingSearch', 'status'], Status.FAILED);
    case CANCEL_BOOKING_REQUESTED:
      return state.setIn(['cancelBooking', 'status'], Status.LOADING);
    case CANCEL_BOOKING_SUCCEEDED:
      return state.setIn(['cancelBooking', 'status'], Status.LOADED);
    case CANCEL_BOOKING_FAILED:
      return state
        .setIn(['cancelBooking', 'status'], Status.FAILED)
        .setIn(['cancelBooking', 'reason'], action.reason);
    default:
      return state;
  }
}

export default bookDetailReducer;
