import React from 'react';
import { NavLink } from 'react-router-dom';

// import Language from 'components/LanguageSelect';

import Logo from 'images/ht-logo-white.png';

import { LANDING } from 'containers/App/routes';
// import { storage } from 'utils/storage';
import { navigationLinks } from './navigationLinks';
import { navigationItems } from './navigationItems';

import * as S from './style';

const LandingHeader = () => {
  // const currentLang = storage.getItem('USER_LANGUAGE');
  const navigationItemList = navigationItems.map(item => (
    <S.NavigationItem>
      {!item.isBlank && (
        <NavLink className="item" to={item.url}>
          {item.name}
        </NavLink>
      )}
      {item.isBlank && (
        <a className="item" href={item.url} target="_blank">
          {item.name}
        </a>
      )}
    </S.NavigationItem>
  ));

  const navigationLinksList = navigationLinks.map(item => {
    if (item.type === 'lang') {
      /* return (
        <S.LinkItem language>
          <NavLink className="item" to={item.url}>
            {item.icon !== null && <S.Icon src={item.icon} alt="" />}
            {currentLang || item.name}
          </NavLink>
          <Language isPreLogin />
        </S.LinkItem>
      ); */
    }
    return (
      <S.LinkItem>
        <NavLink className="item" to={item.url}>
          {item.icon !== null && <S.Icon src={item.icon} alt="" />}
          {item.name}
        </NavLink>
      </S.LinkItem>
    );
  });

  return (
    <S.Header>
      <S.Wrapper>
        <S.Logo href={LANDING}>
          <S.Image src={Logo} alt="" />
        </S.Logo>
        <S.Navigation>{navigationItemList}</S.Navigation>
        <S.Links>{navigationLinksList}</S.Links>
      </S.Wrapper>
    </S.Header>
  );
};

export default LandingHeader;
