import React from 'react';
import chrome from 'images/chrome.png';
import firefox from 'images/firefox.png';
import safari from 'images/safari.png';
import opera from 'images/opera.png';
import HproLogo from 'images/hp.png';
import internetExplorer from 'images/internetExplorer.png';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

import * as S from './style';

const CheckBrowser = () => {
  const browserList = [
    {
      name: 'Chrome',
      url: 'https://www.google.com/chrome',
      image: chrome,
      textTop: 'Chrome',
      textBottom: '11+',
    },
    {
      name: 'Firefox',
      url: 'http://www.mozilla.org/firefox',
      image: firefox,
      textTop: 'Firefox',
      textBottom: '17+',
    },
    {
      name: 'Safari',
      url: 'http://www.apple.com/safari/',
      image: safari,
      textTop: 'Safari',
      textBottom: '6+',
    },
    {
      name: 'Opera',
      url: 'http://www.opera.com/',
      image: opera,
      textTop: 'Opera',
      textBottom: '12.6+',
    },
    {
      name: 'Internet Explorer',
      url: 'http://windows.microsoft.com/internet-explorer',
      image: internetExplorer,
      textTop: 'Internet',
      textBottom: 'Explorer 10+',
    },
  ];

  const BrowsersList = browserList.map(browser => (
    <S.Browser target="_blank" href={browser.url} key={browser.image}>
      <S.Figure>
        <S.Image src={browser.image} alt="browser-logo" />
      </S.Figure>
      <S.BrowserText>
        {' '}
        {browser.textTop} <br /> {browser.textBottom}{' '}
      </S.BrowserText>
    </S.Browser>
  ));

  const LinksList = browserList.map((browser, i) => (
    <>
      {i !== 0 && i !== browserList.length - 1 && <>, </>}
      {i === browserList.length - 1 && (
        <FormattedMessage {...messages.connectorMessage} />
      )}
      <S.Link target="_blank" href={browser.url} key={browser.name}>
        {browser.name}
      </S.Link>
    </>
  ));

  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderImage src={HproLogo} alt="logo" />
      </S.Header>
      <S.PageContainer>
        <S.Container>
          <S.TopContainer>
            <FormattedMessage {...messages.browserSupport} />
          </S.TopContainer>
          <S.BottomContainer>
            <S.BottomText>
              <FormattedMessage {...messages.browserVersionMessage} />
              <S.Links>
                <FormattedMessage {...messages.versionMessage} />{' '}
                <span>{LinksList}</span>
              </S.Links>
            </S.BottomText>
            <S.BrowsersContainer>{BrowsersList}</S.BrowsersContainer>
          </S.BottomContainer>
        </S.Container>
      </S.PageContainer>
    </S.Wrapper>
  );
};

export default CheckBrowser;
