import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectLanguage = state => state.get('language', initialState);

const makeSelectLocale = () =>
  createSelector(selectLanguage, state => state.get('locale'));

const makeSelectLanguages = () =>
  createSelector(selectLanguage, state => state.get('languages'));

const makeSelectLanguagesStatus = () =>
  createSelector(selectLanguage, state =>
    state.getIn(['getLanguages', 'status']),
  );

export {
  selectLanguage,
  makeSelectLocale,
  makeSelectLanguages,
  makeSelectLanguagesStatus,
};
