/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { GET_REPORTS_REQUESTED } from './constants';

import { getReportsSucceeded, getReportsFailed } from './actions';

import { getReportsApi } from './api';

export function* getReports({ userId }) {
  try {
    const { data } = yield call(getReportsApi, userId);
    yield put(getReportsSucceeded(data));
  } catch (e) {
    if (e.response) {
      const { data, status } = e.response;
      if (status >= 400) {
        yield put(getReportsFailed(data.error));
      }
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* root() {
  yield takeLatest(GET_REPORTS_REQUESTED, getReports);
}
