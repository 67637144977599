import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import business from 'images/business.svg';
import family from 'images/family.svg';
import couples from 'images/couple.svg';
import solo from 'images/solo.svg';
import friends from 'images/friends.svg';
import * as S from './style';

const OleryBadge = ({ children, bgColor, className, name }) => {
  const classes = classNames('olery-badge', className);
  const badgeType = {
    business: {
      image: business,
      color: 'rgba(150, 231, 254, 0.65)',
    },
    couples: {
      image: couples,
      color: 'rgba(255, 87, 116, 0.5)',
    },
    families: {
      image: family,
      color: 'rgba(248, 205, 25, 0.48)',
    },
    friends: {
      image: friends,
      color: 'rgba(150, 194, 254, 0.65)',
    },
    solo: {
      image: solo,
      color: 'rgba(241, 188, 123, 0.65)',
    },
  };
  const item = badgeType[name] || {};
  return (
    <S.OleryBadge className={classes} bgColor={item.color || bgColor}>
      <S.Figure>{item.image && <S.Image src={item.image} />}</S.Figure>
      {children}
    </S.OleryBadge>
  );
};

OleryBadge.propTypes = {
  children: PropTypes.node,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
};

OleryBadge.defaultProps = {
  bgColor: 'rgba(150, 231, 254, 0.65)',
};

export default OleryBadge;
