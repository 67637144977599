export const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED';
export const CHANGE_PASSWORD_SUCCEEDED = 'CHANGE_PASSWORD_SUCCEEDED';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const GET_USER_INFORMATION_REQUESTED = 'GET_USER_INFORMATION_REQUESTED';
export const GET_USER_INFORMATION_SUCCEEDED = 'GET_USER_INFORMATION_SUCCEEDED';
export const GET_USER_INFORMATION_FAILED = 'GET_USER_INFORMATION_FAILED';

export const GET_FINANCIAL_INFORMATION_REQUESTED =
  'GET_FINANCIAL_INFORMATION_REQUESTED';
export const GET_FINANCIAL_INFORMATION_SUCCEEDED =
  'GET_FINANCIAL_INFORMATION_SUCCEEDED';
export const GET_FINANCIAL_INFORMATION_FAILED =
  'GET_FINANCIAL_INFORMATION_FAILED';

export const GET_AGENCY_INFORMATION_DETAIL_REQUESTED =
  'GET_AGENCY_INFORMATION_DETAIL_REQUESTED';
export const GET_AGENCY_INFORMATION_DETAIL_SUCCEEDED =
  'GET_AGENCY_INFORMATION_DETAIL_SUCCEEDED';
export const GET_AGENCY_INFORMATION_DETAIL_FAILED =
  'GET_AGENCY_INFORMATION_DETAIL_FAILED';

export const UPDATE_USER_INFORMATION_REQUESTED =
  'UPDATE_USER_INFORMATION_REQUESTED';
export const UPDATE_USER_INFORMATION_SUCCEEDED =
  'UPDATE_USER_INFORMATION_SUCCEEDED';
export const UPDATE_USER_INFORMATION_FAILED = 'UPDATE_USER_INFORMATION_FAILED';

export const GET_CONTACT_EMAILS_REQUESTED = 'GET_CONTACT_EMAILS_REQUESTED';
export const GET_CONTACT_EMAILS_SUCCEEDED = 'GET_CONTACT_EMAILS_SUCCEEDED';
export const GET_CONTACT_EMAILS_FAILED = 'GET_CONTACT_EMAILS_FAILED';

export const UPDATE_CONTACT_EMAIL_REQUESTED = 'UPDATE_CONTACT_EMAIL_REQUESTED';
export const UPDATE_CONTACT_EMAIL_SUCCEEDED = 'UPDATE_CONTACT_EMAIL_SUCCEEDED';
export const UPDATE_CONTACT_EMAIL_FAILED = 'UPDATE_CONTACT_EMAIL_FAILED';

export const UPDATE_USER_PERMS_REQUESTED = 'UPDATE_USER_PERMS_REQUESTED';
export const UPDATE_USER_PERMS_SUCCEEDED = 'UPDATE_USER_PERMS_SUCCEEDED';
export const UPDATE_USER_PERMS_FAILED = 'UPDATE_USER_PERMS_FAILED';

export const DELETE_CONTACT_EMAIL_REQUESTED = 'DELETE_CONTACT_EMAIL_REQUESTED';
export const DELETE_CONTACT_EMAIL_SUCCEEDED = 'DELETE_CONTACT_EMAIL_SUCCEEDED';
export const DELETE_CONTACT_EMAIL_FAILED = 'DELETE_CONTACT_EMAIL_FAILED';

export const POST_CONTACT_EMAIL_REQUESTED = 'POST_CONTACT_EMAIL_REQUESTED';
export const POST_CONTACT_EMAIL_SUCCEEDED = 'POST_CONTACT_EMAIL_SUCCEEDED';
export const POST_CONTACT_EMAIL_FAILED = 'POST_CONTACT_EMAIL_FAILED';

export const UPDATE_MANAGER_INFORMATION_REQUESTED =
  'UPDATE_MANAGER_INFORMATION_REQUESTED';
export const UPDATE_MANAGER_INFORMATION_SUCCEEDED =
  'UPDATE_MANAGER_INFORMATION_SUCCEEDED';
export const UPDATE_MANAGER_INFORMATION_FAILED =
  'UPDATE_MANAGER_INFORMATION_FAILED';

export const UPDATE_AGENCY_FINANCIAL_INFORMATION_REQUESTED =
  'UPDATE_AGENCY_FINANCIAL_INFORMATION_REQUESTED';
export const UPDATE_AGENCY_FINANCIAL_INFORMATION_SUCCEEDED =
  'UPDATE_AGENCY_FINANCIAL_INFORMATION_SUCCEEDED';
export const UPDATE_AGENCY_FINANCIAL_INFORMATION_FAILED =
  'UPDATE_AGENCY_FINANCIAL_INFORMATION_FAILED';

export const UPDATE_AGENCY_INFORMATION_REQUESTED =
  'UPDATE_AGENCY_INFORMATION_REQUESTED';
export const UPDATE_AGENCY_INFORMATION_SUCCEEDED =
  'UPDATE_AGENCY_INFORMATION_SUCCEEDED';
export const UPDATE_AGENCY_INFORMATION_FAILED =
  'UPDATE_AGENCY_INFORMATION_FAILED';

export const GET_AGENCY_INFORMATION_REQUESTED =
  'GET_AGENCY_INFORMATION_REQUESTED';
export const GET_AGENCY_INFORMATION_SUCCEEDED =
  'GET_AGENCY_INFORMATION_SUCCEEDED';
export const GET_AGENCY_INFORMATION_FAILED = 'GET_AGENCY_INFORMATION_FAILED';

export const GET_AGENCY_USERS_REQUESTED = 'GET_AGENCY_USERS_REQUESTED';
export const GET_AGENCY_USERS_SUCCEEDED = 'GET_AGENCY_USERS_SUCCEEDED';
export const GET_AGENCY_USERS_FAILED = 'GET_AGENCY_USERS_FAILED';

export const GET_SUBAGENCIES_REQUESTED = 'GET_SUBAGENCIES_REQUESTED';
export const GET_SUBAGENCIES_SUCCEEDED = 'GET_SUBAGENCIES_SUCCEEDED';
export const GET_SUBAGENCIES_FAILED = 'GET_SUBAGENCIES_FAILED';

export const GET_FUTURE_BALANCE_REQUESTED = 'GET_FUTURE_BALANCE_REQUESTED';
export const GET_FUTURE_BALANCE_SUCCEEDED = 'GET_FUTURE_BALANCE_SUCCEEDED';
export const GET_FUTURE_BALANCE_FAILED = 'GET_FUTURE_BALANCE_FAILED';

export const POST_NEW_USER_REQUESTED = 'POST_NEW_USER_REQUESTED';
export const POST_NEW_USER_SUCCEEDED = 'POST_NEW_USER_SUCCEEDED';
export const POST_NEW_USER_FAILED = 'POST_NEW_USER_FAILED';

export const GET_COUNTRIES_REQUESTED = 'GET_COUNTRIES_REQUESTED';
export const GET_COUNTRIES_SUCCEEDED = 'GET_COUNTRIES_SUCCEEDED';
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED';

export const GET_COUNTRY_STATES_REQUESTED = 'GET_COUNTRY_STATES_REQUESTED';
export const GET_COUNTRY_STATES_SUCCEEDED = 'GET_COUNTRY_STATES_SUCCEEDED';
export const GET_COUNTRY_STATES_FAILED = 'GET_COUNTRY_STATES_FAILED';

export const CREATE_SUBAGENCY_REQUESTED = 'CREATE_SUBAGENCY_REQUESTED';
export const CREATE_SUBAGENCY_SUCCEEDED = 'CREATE_SUBAGENCY_SUCCEEDED';
export const CREATE_SUBAGENCY_FAILED = 'CREATE_SUBAGENCY_FAILED';

export const UPDATE_PAYMENT_METHODS_REQUESTED =
  'UPDATE_PAYMENT_METHODS_REQUESTED';
export const UPDATE_PAYMENT_METHODS_SUCCEEDED =
  'UPDATE_PAYMENT_METHODS_SUCCEEDED';
export const UPDATE_PAYMENT_METHODS_FAILED = 'UPDATE_PAYMENT_METHODS_FAILED';

export const GET_PAYMENT_METHODS_REQUESTED = 'GET_PAYMENT_METHODS_REQUESTED';
export const GET_PAYMENT_METHODS_SUCCEEDED = 'GET_PAYMENT_METHODS_SUCCEEDED';
export const GET_PAYMENT_METHODS_FAILED = 'GET_PAYMENT_METHODS_FAILED';

export const GET_VIRTUAL_BALANCE_REQUESTED = 'GET_VIRTUAL_BALANCE_REQUESTED';
export const GET_VIRTUAL_BALANCE_SUCCEEDED = 'GET_VIRTUAL_BALANCE_SUCCEEDED';
export const GET_VIRTUAL_BALANCE_FAILED = 'GET_VIRTUAL_BALANCE_FAILED';

export const GET_CARD_TOKEN_REQUESTED = 'GET_CARD_TOKEN_REQUESTED';
export const GET_CARD_TOKEN_SUCCEEDED = 'GET_CARD_TOKEN_SUCCEEDED';
export const GET_CARD_TOKEN_FAILED = 'GET_CARD_TOKEN_FAILED';

export const GET_SAVED_CARDS_REQUESTED = 'GET_SAVED_CARDS_REQUESTED';
export const GET_SAVED_CARDS_SUCCEEDED = 'GET_SAVED_CARDS_SUCCEEDED';
export const GET_SAVED_CARDS_FAILED = 'GET_SAVED_CARDS_FAILED';

export const DELETE_SAVED_CARD_REQUESTED = 'DELETE_SAVED_CARD_REQUESTED';
export const DELETE_SAVED_CARD_SUCCEEDED = 'DELETE_SAVED_CARD_SUCCEEDED';
export const DELETE_SAVED_CARD_FAILED = 'DELETE_SAVED_CARD_FAILED';

export const POST_CARD_INFORMATIONS_REQUESTED =
  'POST_CARD_INFORMATIONS_REQUESTED';
export const POST_CARD_INFORMATIONS_SUCCEEDED =
  'POST_CARD_INFORMATIONS_SUCCEEDED';
export const POST_CARD_INFORMATIONS_FAILED = 'POST_CARD_INFORMATIONS_FAILED';

export const UPDATE_VIRTUAL_BALANCE_REQUESTED =
  'UPDATE_VIRTUAL_BALANCE_REQUESTED';
export const UPDATE_VIRTUAL_BALANCE_SUCCEEDED =
  'UPDATE_VIRTUAL_BALANCE_SUCCEEDED';
export const UPDATE_VIRTUAL_BALANCE_FAILED = 'UPDATE_VIRTUAL_BALANCE_FAILED';

export const RESEND_ACTIVATION_LINK_REQUESTED =
  'RESEND_ACTIVATION_LINK_REQUESTED';
export const RESEND_ACTIVATION_LINK_SUCCEEDED =
  'RESEND_ACTIVATION_LINK_SUCCEEDED';
export const RESEND_ACTIVATION_LINK_FAILED = 'RESEND_ACTIVATION_LINK_FAILED';

export const CHANGE_PAGE = 'CHANGE_PAGE';
