export const GET_HOTEL_DETAIL_REQUESTED = 'GET_HOTEL_DETAIL_REQUESTED';
export const GET_HOTEL_DETAIL_SUCCEEDED = 'GET_HOTEL_DETAIL_SUCCEEDED';
export const GET_HOTEL_DETAIL_FAILED = 'GET_HOTEL_DETAIL_FAILED';

export const GET_ROOM_AVAILABILITY_REQUESTED =
  'GET_ROOM_AVAILABILITY_REQUESTED';
export const GET_ROOM_AVAILABILITY_SUCCEEDED =
  'GET_ROOM_AVAILABILITY_SUCCEEDED';
export const GET_ROOM_AVAILABILITY_FAILED = 'GET_ROOM_AVAILABILITY_FAILED';

export const ADD_BASKET_REQUESTED = 'ADD_BASKET_REQUESTED';
export const ADD_BASKET_SUCCEEDED = 'ADD_BASKET_SUCCEEDED';
export const ADD_BASKET_FAILED = 'ADD_BASKET_FAILED';

export const APPLY_FILTERS = 'APPLY_FILTERS';
export const SET_HOTEL_PRODUCTS = 'SET_HOTEL_PRODUCTS';

export const SEND_ADD_BASKET_DIMENSIONS = 'SEND_ADD_BASKET_DIMENSIONS';
