import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';

import Toast from 'components/Toast';

import {
  makeSelectLogoutStatus,
  makeSelectLogoutReason,
} from 'containers/Authentication/ReturnLogin/selectors';

import { logoutRequested } from 'containers/Authentication/ReturnLogin/actions';
import { setModalStatus } from 'containers/ModalProvider/actions';

import * as Status from 'utils/status';

import reducer from 'containers/Authentication/ReturnLogin/reducer';

import injectReducer from 'utils/injectReducer';

import SignOutModal from './SignOutModal';

import * as S from './style';

class Menu extends PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { logoutReason } = this.props;
    if (
      nextProps.logoutReason !== logoutReason &&
      nextProps.logoutStatus === Status.FAILED
    ) {
      toast.error(<Toast status={false} message={nextProps.logoutReason} />);
    }
  }

  render() {
    const { options, logoutStatus, logout, onChangeModalStatus } = this.props;
    return (
      <>
        {options.map(
          (option, index) =>
            option.isVisible && (
              <Fragment key={`option-${index.toString()}`}>
                <S.Item>
                  <S.ItemLink
                    disabled={
                      option.to === null && Status.LOADING === logoutStatus
                    }
                    to={option.to || null}
                    onClick={
                      option.to
                        ? null
                        : () => onChangeModalStatus('SIGN_OUT_MODAL', true)
                    }
                    transparent
                  >
                    {option.text}
                  </S.ItemLink>
                </S.Item>
              </Fragment>
            ),
        )}
        <SignOutModal
          logout={logout}
          onChangeModalStatus={onChangeModalStatus}
          status={logoutStatus}
        />
      </>
    );
  }
}

const mapStateToProps = () =>
  createStructuredSelector({
    logoutStatus: makeSelectLogoutStatus(),
    logoutReason: makeSelectLogoutReason(),
  });

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(logoutRequested('logout'));
  },
  onChangeModalStatus: (name, status) => {
    dispatch(setModalStatus(name, status));
  },
});

const withReducer = injectReducer({
  key: 'login',
  reducer,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Menu.propTypes = {
  options: PropTypes.array,
  logoutStatus: PropTypes.number,
  logoutReason: PropTypes.number,
  logout: PropTypes.func,
  onChangeModalStatus: PropTypes.func,
};

export default compose(
  withConnect,
  withReducer,
)(Menu);
