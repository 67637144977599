import { req } from 'utils/request';

import { apiConfig } from 'config';

import { storage } from 'utils/storage';

const { account } = apiConfig;

export function getUserLanguagesApi() {
  const userID = storage.getItem('USER_ID');
  return req().get(account.userLanguages(userID));
}

export function setUserLanguageApi(language) {
  const userID = storage.getItem('USER_ID');
  return req().post(account.setLanguage(userID), language);
}

export function getCoreLanguagesApi() {
  return req().get(account.getCoreLanguages);
}
