export const GET_USER_BALANCES_REQUESTED = 'GET_USER_BALANCES_REQUESTED';
export const GET_USER_BALANCES_SUCCEEDED = 'GET_USER_BALANCES_SUCCEEDED';
export const GET_USER_BALANCES_FAILED = 'GET_USER_BALANCES_FAILED';

export const GET_USER_FINALIZED_BOOKINGS_REQUESTED =
  'GET_USER_FINALIZED_BOOKINGS_REQUESTED';
export const GET_USER_FINALIZED_BOOKINGS_SUCCEEDED =
  'GET_USER_FINALIZED_BOOKINGS_SUCCEEDED';
export const GET_USER_FINALIZED_BOOKINGS_FAILED =
  'GET_USER_FINALIZED_BOOKINGS_FAILED';

export const GET_USER_WAITING_BOOKINGS_REQUESTED =
  'GET_USER_WAITING_BOOKINGS_REQUESTED';
export const GET_USER_WAITING_BOOKINGS_SUCCEEDED =
  'GET_USER_WAITING_BOOKINGS_SUCCEEDED';
export const GET_USER_WAITING_BOOKINGS_FAILED =
  'GET_USER_WAITING_BOOKINGS_FAILED';

export const GET_USER_WAITING_REFUNDS_REQUESTED =
  'GET_USER_WAITING_REFUNDS_REQUESTED';
export const GET_USER_WAITING_REFUNDS_SUCCEEDED =
  'GET_USER_WAITING_REFUNDS_SUCCEEDED';
export const GET_USER_WAITING_REFUNDS_FAILED =
  'GET_USER_WAITING_REFUNDS_FAILED';

export const GET_USER_LOYALTY_REQUESTED = 'GET_USER_LOYALTY_REQUESTED';
export const GET_USER_LOYALTY_SUCCEEDED = 'GET_USER_LOYALTY_SUCCEEDED';
export const GET_USER_LOYALTY_FAILED = 'GET_USER_LOYALTY_FAILED';

export const CREATE_COUPON_CODE_REQUESTED = 'CREATE_COUPON_CODE_REQUESTED';
export const CREATE_COUPON_CODE_SUCCEEDED = 'CREATE_COUPON_CODE_SUCCEEDED';
export const CREATE_COUPON_CODE_FAILED = 'CREATE_COUPON_CODE_FAILED';

export const SET_SELECTED_BOOKING_CODE = 'SET_SELECTED_BOOKING_CODE';
