import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import { formatDate } from 'utils/dates';
import history from 'utils/history';
import { CANCELLED, BOOKED, WAITING } from 'utils/bookingStatus';

import {
  makeSelectBookStatus,
  makeSelectPaymentDeadline,
} from 'containers/BookingDetailsPage/selectors';

import Icon from '../Icon';
import * as S from './style';
import messages from './messages';

const StatusBadge = props => (
  <S.StatusBadge {...props}>
    {props.icon && <Icon name={props.icon} width="14" />}
    {props.message}
  </S.StatusBadge>
);

StatusBadge.propTypes = {
  success: PropTypes.bool,
  cancelled: PropTypes.bool,
  icon: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

StatusBadge.defaultProps = {
  success: false,
  cancelled: false,
  icon: '',
  message: '',
};

const DetailsHeader = props => (
  <S.DetailHeader {...props}>
    <S.DetailHeaderContainer>
      <S.BackLink onClick={history.goBack}>
        <Icon name="back" width="16" color="#f8a119" />
        <FormattedMessage {...messages.backToDashboard} />
      </S.BackLink>
      {props.showDetails && (
        <S.DetailHeaderWrapper>
          {props.status === BOOKED && (
            <StatusBadge
              success
              icon="check"
              message={<FormattedMessage {...messages.booked} />}
            />
          )}
          {props.status === WAITING && (
            <>
              <StatusBadge
                icon="clock"
                message={<FormattedMessage {...messages.waitingPayment} />}
              />
              <S.DateList>
                <S.DateItem>
                  {formatDate(props.paymentDeadline, 'DD MMMM YYYY')}
                </S.DateItem>
                {/* TODO: Hours should not be static */}
                <S.DateItem>12:00 GMT</S.DateItem>
              </S.DateList>
            </>
          )}
          {props.status === CANCELLED && (
            <StatusBadge
              cancelled
              message={<FormattedMessage {...messages.cancelled} />}
            />
          )}
        </S.DetailHeaderWrapper>
      )}
    </S.DetailHeaderContainer>
  </S.DetailHeader>
);

DetailsHeader.propTypes = {
  showDetails: PropTypes.bool,
};

DetailsHeader.defaultProps = {
  showDetails: false,
};

const mapStateToProps = createStructuredSelector({
  status: makeSelectBookStatus(),
  paymentDeadline: makeSelectPaymentDeadline(),
});

export default connect(
  mapStateToProps,
  null,
)(DetailsHeader);
