import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { includeEndRange } from 'helpers/search';

import withBlurred from 'components/shared/withBlurred';

import injectSaga from 'utils/injectSaga';

import saga from 'containers/CommissionProvider/saga';

import * as Status from 'utils/status';

import {
  makeSelectCommissions,
  makeSelectCommissionRate,
  makeSelectCommissionsStatus,
} from 'containers/CommissionProvider/selectors';

import {
  getUserCommissionsRequested,
  setUserCommissionRequested,
} from 'containers/CommissionProvider/actions';

import * as S from './style';
import messages from './messages';

class Commission extends PureComponent {
  handleChangeShowMenu = () => {
    const { getCommissions, commissions } = this.props;
    if (!(commissions.min_commission_rate || commissions.max_commission_rate)) {
      getCommissions();
    }
    this.props.handleToggleMenu();
  };

  setUserCommission = commissionRate => {
    const { changeUserCommissionRate } = this.props;
    changeUserCommissionRate(commissionRate);
  };

  render() {
    const {
      commissions,
      commissionRate,
      status,
      intl,
      right,
      isShownList,
      forwardedRef,
    } = this.props;

    const commissionSelectHeader = intl.formatMessage({
      ...messages.commission,
    });

    const commissionOptions = [];

    includeEndRange(
      commissions.min_commission_rate,
      commissions.max_commission_rate,
    ).map(item => commissionOptions.push({ name: item }));

    return (
      <S.CommissionSelect ref={forwardedRef} right={right}>
        <S.CommissionMenu
          selected={commissionSelectHeader}
          onClick={this.handleChangeShowMenu}
          isOpened={isShownList}
        >
          {isShownList && (
            <S.CommissionList
              isLoaded={status === Status.LOADED}
              onClickedOption={option => this.setUserCommission(option.name)}
              options={commissionOptions}
              header={<FormattedMessage {...messages.selectCommissionHeader} />}
              selected={commissionRate}
              isJustName
            />
          )}
        </S.CommissionMenu>
      </S.CommissionSelect>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  commissions: makeSelectCommissions(),
  commissionRate: makeSelectCommissionRate(),
  status: makeSelectCommissionsStatus(),
});

const mapDispatchToProps = dispatch => ({
  getCommissions: () => {
    dispatch(getUserCommissionsRequested());
  },
  changeUserCommissionRate: commissionRate => {
    dispatch(setUserCommissionRequested(commissionRate));
  },
});

Commission.propTypes = {
  commissions: PropTypes.object,
  getCommissions: PropTypes.func,
  changeUserCommissionRate: PropTypes.func,
  right: PropTypes.bool,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({
  key: 'commission',
  saga,
});

export default withBlurred(
  injectIntl(
    compose(
      withConnect,
      withSaga,
    )(Commission),
  ),
);
