import styled from 'styled-components';
import { Badge } from 'components/Badge/style';

const OleryBadge = styled(Badge)`
  background-color: ${props => props.bgColor};
  text-transform: capitalize;
  border-radius: 14px;
  justify-content: flex-start;
  padding: 3px 16px 3px 3px;
  border: 0;
  font-size: 13px;
  letter-spacing: 0.2px;
  color: #08394a;
  font-weight: normal;
`;

const Figure = styled.figure`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
  margin-right: 8px;
`;

const Image = styled.img`
  width: 16px;
  height: 16px;
`;

export { OleryBadge, Figure, Image };
