import {
  GET_CURRENCIES_REQUESTED,
  GET_CURRENCIES_SUCCEEDED,
  GET_CURRENCIES_FAILED,
  SET_CURRENCY_CODE_REQUESTED,
  SET_CURRENCY_CODE_SUCCEEDED,
  SET_CURRENCY_CODE_FAILED,
} from './constants';

export const getUserCurrenciesRequested = () => ({
  type: GET_CURRENCIES_REQUESTED,
});

export const getUserCurrenciesSucceeded = results => ({
  type: GET_CURRENCIES_SUCCEEDED,
  results,
});

export const getUserCurrenciesFailed = reason => ({
  type: GET_CURRENCIES_FAILED,
  reason,
});

export const setUserCurrencyRequested = (currencyCode, forceRefresh) => ({
  type: SET_CURRENCY_CODE_REQUESTED,
  currencyCode,
  forceRefresh,
});

export const setUserCurrencySucceeded = currencyCode => ({
  type: SET_CURRENCY_CODE_SUCCEEDED,
  currencyCode,
});

export const setUserCurrencyFailed = reason => ({
  type: SET_CURRENCY_CODE_FAILED,
  reason,
});
