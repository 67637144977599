import { createSelector } from 'reselect';

import { initialState } from './reducer';

const getAccountState = state => state.get('account', initialState);

const makeSelectBalances = () =>
  createSelector(getAccountState, state => state.get('balances'));

const makeSelectFinalizedBookings = () =>
  createSelector(getAccountState, state =>
    state.getIn(['finalizedBookings', 'data']),
  );

const makeSelectWaitingBookings = () =>
  createSelector(getAccountState, state =>
    state.getIn(['waitingBookings', 'data']),
  );

const makeSelectWaitingBookingStatus = () =>
  createSelector(getAccountState, state =>
    state.getIn(['waitingBookings', 'status']),
  );

const makeSelectWaitingRefunds = () =>
  createSelector(getAccountState, state =>
    state.getIn(['waitingRefunds', 'data']),
  );

const makeSelectWaitingRefundsStatus = () =>
  createSelector(getAccountState, state =>
    state.getIn(['waitingRefunds', 'status']),
  );

const makeSelectFinalizedBookingStatus = () =>
  createSelector(getAccountState, state =>
    state.getIn(['finalizedBookings', 'status']),
  );

const makeSelectLoyalty = () =>
  createSelector(getAccountState, state => state.getIn(['loyalty', 'results']));

const makeSelectLoyaltyStatus = () =>
  createSelector(getAccountState, state => state.getIn(['loyalty', 'status']));

const makeSelectCouponCode = () =>
  createSelector(getAccountState, state =>
    state.getIn(['couponCode', 'results']),
  );

const makeSelectCouponCodeStatus = () =>
  createSelector(getAccountState, state =>
    state.getIn(['couponCode', 'status']),
  );

const makeSelectCouponCodeReason = () =>
  createSelector(getAccountState, state =>
    state.getIn(['couponCode', 'reason']),
  );

const makeSelectSelectedCouponCode = () =>
  createSelector(getAccountState, state => state.get('selectedBookingCode'));

export {
  makeSelectBalances,
  makeSelectFinalizedBookings,
  makeSelectWaitingBookings,
  makeSelectLoyalty,
  makeSelectLoyaltyStatus,
  makeSelectWaitingBookingStatus,
  makeSelectFinalizedBookingStatus,
  makeSelectWaitingRefunds,
  makeSelectWaitingRefundsStatus,
  makeSelectCouponCode,
  makeSelectCouponCodeStatus,
  makeSelectCouponCodeReason,
  makeSelectSelectedCouponCode,
};
