import styled, { css } from 'styled-components';
import List from 'components/List';
import Menu from 'components/Menu';

const PriceMarginSelect = styled.div`
  height: 100%;
  ${props =>
    props.right &&
    css`
      ${List} {
        z-index: 2;
        right: 0;
        left: auto;
        &:before {
          right: 20px;
          left: auto;
        }
      }
    `};
`;

const PriceMarginList = styled(List)`
  position: absolute;
  top: 100%;
  left: 0;
  height: 402px;
`;

const PriceMarginMenu = styled(Menu)`
  display: flex;
  height: 100%;
  background-color: ${props => (props.isOpened ? '#f2f1f6' : '#FFF')};
  padding-left: 16px;
  padding-right: 20px;
`;

export { PriceMarginSelect, PriceMarginList, PriceMarginMenu };
