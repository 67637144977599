import styled from 'styled-components';
import { Icon } from '../Icon/style';

const Menu = styled.div`
  position: relative;
`;

const Item = styled.span`
  display: inline-flex;
  align-items: center;
  color: #303030;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  user-select: none;
  ${Icon} {
    margin-left: 8px;
  }
`;

export { Menu, Item };
