import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
  getUserLanguagesFailed,
  getUserLanguagesSucceeded,
  getCoreLanguagesSucceeded,
  getCoreLanguagesFailed,
  setUserLanguageFailed,
  setUserLanguageSucceeded,
} from './actions';

import {
  getUserLanguagesApi,
  getCoreLanguagesApi,
  setUserLanguageApi,
} from './api';

import {
  GET_LANGUAGES_REQUESTED,
  GET_CORE_LANGUAGES_REQUESTED,
  SET_USER_LANGUAGE_REQUESTED,
} from './constants';

export function* setLanguageDirection(language) {
  document.documentElement.dir = language.code !== 'ar' ? 'ltl' : 'rtl';
}

export function* getUserLanguagesResult() {
  try {
    const { data } = yield call(getUserLanguagesApi);
    yield put(getUserLanguagesSucceeded(data));
  } catch (e) {
    yield put(getUserLanguagesFailed(e));
  }
}

export function* getCoreLanguages() {
  try {
    const { data } = yield call(getCoreLanguagesApi);
    yield put(getCoreLanguagesSucceeded(data));
  } catch (e) {
    yield put(getCoreLanguagesFailed(e));
  }
}

export function* setUserLanguage({ language }) {
  try {
    yield call(setUserLanguageApi, {
      language,
    });
    yield put(setUserLanguageSucceeded(language));
    window.location.reload(true);
    yield call(setLanguageDirection, language);
  } catch (e) {
    yield put(setUserLanguageFailed);
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_LANGUAGES_REQUESTED, getUserLanguagesResult),
    takeLatest(GET_CORE_LANGUAGES_REQUESTED, getCoreLanguages),
    takeLatest(SET_USER_LANGUAGE_REQUESTED, setUserLanguage),
  ]);
}
