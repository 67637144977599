import { apiConfig } from 'config';
import { req } from 'utils/request';
import { storage } from 'utils/storage';

const { account, bookings, booking } = apiConfig;

export function getUserBalancesApi() {
  const userID = storage.getItem('USER_ID');
  return req().get(account.userBalances(userID));
}

export function getUserBookingsApi(params) {
  const paramString = params !== '' ? `${params}` : '';
  return req().get(bookings.getUserBookings(paramString));
}

export function getUserWaitingBookingsApi() {
  const userID = storage.getItem('USER_ID');
  return req().get(account.getWaitingBookings(userID));
}

export function getUserLoyaltyApi() {
  const userID = storage.getItem('USER_ID');
  return req().get(account.loyalty(userID));
}

export function createCouponCodeApi(bookingCode, formData) {
  return req().post(booking.createCouponCode(bookingCode), formData);
}
