import styled, { css } from 'styled-components';
import Button from 'components/Button';
import { Button as ButtonStyle } from 'components/Button/style';
import { Icon } from 'components/Icon/style';

const ProfileCard = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;

const ListContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const NamePreview = styled.span`
  user-select: none;
  display: inline-block;
  padding: 8px;
  background-color: #3A8DC3;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin-right: 10px;
`;

const UserName = styled.span`
  color: #484848;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
`;

const MenuLinkContainer = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${Icon} {
    margin-left: 8px;
  }
`;

const CardDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const ProfileMenu = styled.div`
  position: relative;
  height: 100%;
  background-color: ${props => (props.isOpened ? '#f2f1f6' : '#FFF')};
  ${Item} > ${ButtonStyle} {
    border-left: 1px solid #e7e6f6;
  }
  ${props =>
    props.isOpened &&
    css`
      ${ProfileCard} {
        border-color: transparent;
      }
    `};
`;

const ProfileMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  border-top: 0;
  background-color: #fff;
  border-right: 1px solid #e7e5ef;
  border-bottom: 1px solid #e7e5ef;
  border-left: 1px solid #e7e5ef;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  max-height: 360px;
  overflow: auto;
`;

const ListWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 60px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 20px;
    top: 0;
    transform: rotate(-45deg) translateY(-10px);
    background-color: #fff;
    border: 1px solid #e7e5ef;
    border-bottom: 0;
    border-left: 0;
    border-radius: 0px 4px;
    z-index: 1;
  }
`;

const Item = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.2px;
  flex-shrink: 0;
  z-index: 2;
  transition: 0.2s;
  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
  &:hover {
    background-color: #f2f1f6;
  }
`;

const ItemLink = styled(Button)`
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 24px;
  width: 100%;
  justify-content: flex-start;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
`;

const DepositWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 6px;
`;

const Name = styled.span`
  color: #949494;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  padding-right: 16px;
`;

const Value = styled.span`
  color: #303030;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2px;
`;

const SignoutModalWrapper = styled.div`
  padding-bottom: 36px;
  position: relative;
  padding-right: 33px;
  padding-left: 33px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ModalIconArea = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 64px;
  height: 64px;
  padding: 8px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border-radius: 50%;
`;

const ModalLogoutImage = styled.img`
  display: flex;
  justify-content: center;
  width: 36px;
  height: 36px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
`;

const ModalTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 12px;
  color: #303030;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 20px;
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.2px;
  color: #626262;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${ButtonStyle} {
    &:first-child {
      margin-right: 16px;
    }
  }
`;

export {
  ProfileCard,
  ListContainer,
  NamePreview,
  CardDetail,
  MenuLinkContainer,
  UserName,
  ProfileMenu,
  ProfileMenuList,
  Item,
  ItemLink,
  DepositWrapper,
  Name,
  Value,
  ListWrapper,
  SignoutModalWrapper,
  ModalIconArea,
  ModalLogoutImage,
  ModalContent,
  ModalTitle,
  ModalDescription,
  ModalButtonContainer,
};
