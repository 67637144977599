import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash';

import * as S from './style';

const Loader = props => {
  const { className } = props;
  const classes = classNames('loader', className);
  const loaderContainerProps = omit(props, 'className', 'size');
  const loaderProps = omit(props, 'background', 'className');

  return (
    <S.LoaderContainer className={classes} {...loaderContainerProps}>
      <S.Loader {...loaderProps} />
    </S.LoaderContainer>
  );
};

export default Loader;

Loader.propTypes = {
  borderColor: PropTypes.string,
  borderTopColor: PropTypes.string,
  background: PropTypes.string,
  size: PropTypes.string,
};
