import {
  CHANGE_LOCALE,
  GET_LANGUAGES_REQUESTED,
  GET_LANGUAGES_SUCCEEDED,
  GET_LANGUAGES_FAILED,
  SET_USER_LANGUAGE_REQUESTED,
  SET_USER_LANGUAGE_SUCCEEDED,
  SET_USER_LANGUAGE_FAILED,
  GET_CORE_LANGUAGES_REQUESTED,
  GET_CORE_LANGUAGES_SUCCEEDED,
  GET_CORE_LANGUAGES_FAILED,
} from './constants';

export const changeLocale = languageLocale => ({
  type: CHANGE_LOCALE,
  locale: languageLocale,
});

export const getUserLanguagesRequested = () => ({
  type: GET_LANGUAGES_REQUESTED,
});

export const getUserLanguagesSucceeded = results => ({
  type: GET_LANGUAGES_SUCCEEDED,
  results,
});

export const getUserLanguagesFailed = reason => ({
  type: GET_LANGUAGES_FAILED,
  reason,
});

export const setUserLanguageRequested = language => ({
  type: SET_USER_LANGUAGE_REQUESTED,
  language,
});

export const setUserLanguageSucceeded = language => ({
  type: SET_USER_LANGUAGE_SUCCEEDED,
  language,
});

export const setUserLanguageFailed = reason => ({
  type: SET_USER_LANGUAGE_FAILED,
  reason,
});

export const getCoreLanguagesRequested = () => ({
  type: GET_CORE_LANGUAGES_REQUESTED,
});

export const getCoreLanguagesSucceeded = results => ({
  type: GET_CORE_LANGUAGES_SUCCEEDED,
  results,
});

export const getCoreLanguagesFailed = reason => ({
  type: GET_CORE_LANGUAGES_FAILED,
  reason,
});
