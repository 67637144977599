import styled, { css } from 'styled-components';

const Toast = styled.div`
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 6px 16px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #fff0f3;

  ${props =>
    props.status &&
    css`
      background-color: #eefff3;
      ${ToastMessage} {
        color: #27da58;
      }
    `};
`;

const ToastMessage = styled.span`
  color: #ff5774;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 23px;
`;

export { Toast, ToastMessage };
