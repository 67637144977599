import React from 'react';

import Language from 'components/LanguageSelect';

import { HOME } from 'containers/App/routes';

import Logo from 'images/HproTravelLogo.svg';

import * as S from './style';

const LoginHeader = () => (
  <S.LoginHeader>
    <S.LoginLogoWrapper to={HOME} transparent>
      <S.LogoImage src={Logo} alt="logo" />
    </S.LoginLogoWrapper>
    <S.LanguageMenu>
      <S.ListContainer>
        <S.MenuList>
          <S.ListItem>
            <Language isPreLogin position="right" />
          </S.ListItem>
        </S.MenuList>
      </S.ListContainer>
    </S.LanguageMenu>
  </S.LoginHeader>
);

export default LoginHeader;
