/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import Cookie from 'js-cookie';
import { ToastContainer } from 'react-toastify';

import { storage } from 'utils/storage';
// import { $on, $off } from 'helpers/dom';

import {
  sendPageViewEvent,
  sendCustomDimensions,
  // trackAllClickEvents,
} from 'helpers/analytics';

import HomePage from 'containers/HomePage/Loadable';
import BookingDetailsPage from 'containers/BookingDetailsPage/Loadable';
import SearchResultsPage from 'containers/SearchResultsPage/Loadable';
import TradeFairsPage from 'containers/TradeFairsPage/Loadable';
import TradeFairsForm from 'containers/TradeFairsForm/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import PrivacyPolicy from 'containers/PrivacyPolicy/Loadable';
import TermsAndConditions from 'containers/TermsAndConditions/Loadable';
import HotelDetail from 'containers/HotelDetail/Loadable';
import CreateOffer from 'containers/CreateOffer/Loadable';
import CheckoutPage from 'containers/CheckoutPage/Loadable';
import Payout from 'containers/Payout/Loadable';
import Login from 'containers/Authentication/Login/Loadable';
import OfferPdf from 'containers/CreateOffer/OfferPdf';
import ThankYouPage from 'containers/ThankYouPage/Loadable';
import VoucherPage from 'containers/VoucherPage/Loadable';
import MyAccount from 'containers/MyAccount/Loadable';
import AgencyInformationEditor from 'containers/AgencyInformationEditor/Loadable';
import SubAgencyInformationEditor from 'containers/SubAgencyInformationEditor/Loadable';
import AddNewUser from 'containers/AddNewUser/Loadable';
import GetHelp from 'containers/GetHelp/Loadable';
import SignUp from 'containers/Authentication/SignUp/Loadable';
import Profit from 'containers/Profit/Loadable';
import MyFavourites from 'containers/MyFavourites/Loadable';
import Collections from 'containers/Collections/Loadable';
import Inbox from 'containers/Inbox/Loadable';
import ResetPassword from 'containers/Authentication/ResetPassword/Loadable';
import TicketDetail from 'containers/TicketDetail/Loadable';
import EmailDetail from 'containers/EmailDetail/Loadable';
import BankDetails from 'containers/BankDetails/Loadable';
import Bookings from 'containers/Bookings/Loadable';
// import Landing from 'containers/Landing/Loadable';
import Landing from 'containers/LandingNew/Loadable';
import TradeFairsPageLanding from 'containers/Landing/TradeFairs/Loadable';
import TradeFairsFormLanding from 'containers/Landing/TradeFairsForm/Loadable';
import LandingOffices from 'containers/Landing/Offices/Loadable';
import LandingProducts from 'containers/Landing/Products/Loadable';
import PrivacyPolicyLanding from 'containers/Landing/PrivacyPolicy/Loadable';
import TermsConditionsLanding from 'containers/Landing/TermsConditions/Loadable';
import HelpLanding from 'containers/Landing/Contact/Loadable';

import { AppRoute, RedirectPage } from 'utils/layoutUtils';
import history from 'utils/history';
import injectSaga from 'utils/injectSaga';

import {
  MainLayout,
  DetailsLayout,
  InfoLayout,
  CheckoutLayout,
  PdfLayout,
  LoginLayout,
  AccountLayout,
  NotFoundLayout,
  LandingLayout,
  ContactLayout,
  BlankLayout,
} from 'components/Layout';

import { DEFAULT_LANGUAGE, IS_PROFIT_ENABLED } from 'config';
import saga from 'containers/Authentication/ReturnLogin/saga';
import { loginWithCookieRequested } from '../Authentication/ReturnLogin/actions';
import { authCheckApi } from '../Authentication/api';

import {
  HOME,
  BOOKING_DETAILS,
  SEARCH_RESULTS,
  HOTEL_DETAIL,
  CREATE_OFFER,
  CREATE_OFFER_PDF,
  TRADE_FAIRS,
  TRADE_FAIRS_FORM,
  CHECKOUT,
  PAYOUT,
  LOGIN,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  THANK_YOU,
  VOUCHER,
  MY_ACCOUNT,
  AGENCY_INFORMATION_EDITOR,
  SUBAGENCY_INFORMATION_EDITOR,
  ADD_NEW_USER,
  GET_HELP,
  MY_BOOKINGS,
  SIGNUP,
  PROFIT,
  FAQ,
  MY_FAVOURITES,
  COLLECTIONS,
  INBOX,
  RESET_PASSWORD,
  TICKET_DETAIL,
  EMAIL_DETAIL,
  BANK_DETAILS,
  EMAIL,
  LANDING,
  TRADE_FAIRS_LANDING,
  TRADE_FAIRS_LANDING_FORM,
  LANDING_OFFICES,
  LANDING_PRODUCTS,
  LANDING_PRIVACY_POLICY,
  LANDING_TERMS_CONDITIONS,
  LANDING_HELP,
} from './routes';

import { titles } from './titles';

import { makeSelectLocation } from './selectors';

import './main.scss';

const hasShownInbox = storage.getParsedValue('permissions')
  .can_view_agency_tickets;
const hasToken = storage.getItem('USER_TOKEN');
const hasLoyaltyActive = storage.getParsedValue('IS_LOYALTY_ACTIVE');

class App extends Component {
  static propTypes = {
    location: PropTypes.object,
    loginWithRedirect: PropTypes.func,
  };

  componentDidMount() {
    const { location, loginWithRedirect } = this.props;

    const path = location.pathname;
    sendPageViewEvent(path, titles(path));
    sendCustomDimensions(titles(path));
    // $on(document.body, 'click', e => trackAllClickEvents(e));

    const languageCode = storage.getItem('USER_LANGUAGE');
    Cookie.set('language', languageCode);

    if (Cookie.get('token') && !storage.getItem('USER_TOKEN')) {
      storage.setItem('USER_TOKEN', Cookie.get('token'));
    }

    const authToken = Cookie.get('token') || false;

    if (!storage.getItem('USER_ID')) {
      if (authToken) {
        authCheckApi(storage.getItem('USER_TOKEN')).then(response => {
          if (response.data) {
            const dt = response.data;
            storage.setItem('USER_ID', dt.user_id);
            loginWithRedirect(authToken, dt.user_id);
          } else {
            // Token is invalid return login page
            storage.removeItem('USER_TOKEN');
            history.replace(LOGIN);
          }
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    if (nextProps.location.pathname !== location.pathname) {
      const path = nextProps.location.pathname;
      sendPageViewEvent(path, titles(path));
      sendCustomDimensions(titles(path));
    }
  }

  componentWillUnmount() {
    // $off(document.body, 'click', e => trackAllClickEvents(e));
  }

  render() {
    return (
      <div>
        <ToastContainer
          className="toast-container"
          toastClassName="toast"
          closeButton={false}
          autoClose={3000}
          position="top-center"
          hideProgressBar
        />
        <Helmet titleTemplate="%s - HProTravel" defaultTitle="HProTravel">
          <html lang={storage.getItem('USER_LANGUAGE') || DEFAULT_LANGUAGE} />
          <meta
            name="description"
            content="HProTravel - The Key to Global Travel"
          />
        </Helmet>
        <Switch>
          <AppRoute
            exact
            path={HOME}
            layout={MainLayout}
            component={HomePage}
          />
          <AppRoute
            path={SEARCH_RESULTS}
            layout={MainLayout}
            component={SearchResultsPage}
          />
          <RedirectPage path={LOGIN} isRedirect={hasToken}>
            <AppRoute
              path={LOGIN}
              layout={LoginLayout}
              component={Login}
              isPrivateRoute
            />
          </RedirectPage>
          <RedirectPage path={SIGNUP} isRedirect={hasToken}>
            <AppRoute
              path={SIGNUP}
              layout={LoginLayout}
              component={SignUp}
              isPrivateRoute
            />
          </RedirectPage>
          <RedirectPage path={RESET_PASSWORD} isRedirect={hasToken}>
            <AppRoute
              path={RESET_PASSWORD}
              layout={LoginLayout}
              component={ResetPassword}
              isPrivateRoute
            />
          </RedirectPage>
          <RedirectPage path={TICKET_DETAIL} isRedirect={!hasShownInbox}>
            <AppRoute
              path={TICKET_DETAIL}
              layout={MainLayout}
              component={TicketDetail}
            />
          </RedirectPage>
          <RedirectPage path={EMAIL_DETAIL} isRedirect={!hasShownInbox}>
            <AppRoute
              path={EMAIL_DETAIL}
              layout={MainLayout}
              component={EmailDetail}
            />
          </RedirectPage>
          <RedirectPage path={INBOX} isRedirect={!hasShownInbox}>
            <AppRoute
              exact
              path={INBOX}
              layout={MainLayout}
              component={Inbox}
            />
          </RedirectPage>
          <RedirectPage path={EMAIL} isRedirect={!hasShownInbox}>
            <AppRoute
              exact
              path={EMAIL}
              layout={MainLayout}
              component={Inbox}
            />
          </RedirectPage>
          <AppRoute
            path={HOTEL_DETAIL}
            layout={MainLayout}
            component={HotelDetail}
            layoutStyle={{ center: true }}
          />
          <AppRoute
            path={CREATE_OFFER}
            layout={DetailsLayout}
            component={CreateOffer}
            layoutStyle={{ center: true }}
            headerStyle={{ showDetails: false }}
          />
          <AppRoute
            path={TRADE_FAIRS}
            layout={InfoLayout}
            component={TradeFairsPage}
          />
          <AppRoute
            path={TRADE_FAIRS_FORM}
            layout={InfoLayout}
            component={TradeFairsForm}
          />
          <AppRoute
            path={PRIVACY_POLICY}
            layout={InfoLayout}
            component={PrivacyPolicy}
          />
          <AppRoute
            path={TERMS_AND_CONDITIONS}
            layout={InfoLayout}
            component={TermsAndConditions}
          />
          <AppRoute
            path={CHECKOUT}
            layout={CheckoutLayout}
            component={CheckoutPage}
            layoutStyle={{ center: true }}
          />
          <AppRoute path={PAYOUT} layout={CheckoutLayout} component={Payout} />
          <AppRoute
            path={CREATE_OFFER_PDF}
            layout={PdfLayout}
            component={OfferPdf}
          />
          <AppRoute
            path={THANK_YOU}
            layout={MainLayout}
            layoutStyle={{ center: true }}
            component={ThankYouPage}
          />
          <AppRoute
            path={VOUCHER}
            layout={MainLayout}
            component={VoucherPage}
          />
          <AppRoute
            path={MY_ACCOUNT}
            layout={AccountLayout}
            component={MyAccount}
          />
          <AppRoute
            path={AGENCY_INFORMATION_EDITOR}
            layout={DetailsLayout}
            component={AgencyInformationEditor}
            headerStyle={{ showDetails: false, isBlock: true }}
          />
          <AppRoute
            path={SUBAGENCY_INFORMATION_EDITOR}
            layout={DetailsLayout}
            component={SubAgencyInformationEditor}
            headerStyle={{ showDetails: false, isBlock: true }}
          />
          <AppRoute
            path={ADD_NEW_USER}
            layout={DetailsLayout}
            component={AddNewUser}
            headerStyle={{ showDetails: false, isBlock: true }}
          />
          <AppRoute path={GET_HELP} layout={MainLayout} component={GetHelp} />
          <AppRoute
            exact
            path={MY_BOOKINGS}
            layout={MainLayout}
            component={Bookings}
          />
          <AppRoute
            exact
            path={BOOKING_DETAILS}
            layout={DetailsLayout}
            component={BookingDetailsPage}
            layoutStyle={{ center: true }}
            headerStyle={{ showDetails: true }}
          />
          <AppRoute
            path={MY_FAVOURITES}
            layout={MainLayout}
            component={MyFavourites}
          />
          <AppRoute
            path={COLLECTIONS}
            layout={MainLayout}
            component={Collections}
          />
          {JSON.parse(IS_PROFIT_ENABLED) && (
            <RedirectPage path={PROFIT} isRedirect={!hasLoyaltyActive}>
              <AppRoute path={PROFIT} layout={MainLayout} component={Profit} />
            </RedirectPage>
          )}
          <AppRoute
            path={BANK_DETAILS}
            layout={InfoLayout}
            component={BankDetails}
          />
          <AppRoute path={FAQ} layout={MainLayout} component={NotFoundPage} />
          <AppRoute
            exact
            isLanding
            path={LANDING}
            layout={BlankLayout}
            component={Landing}
          />
          <AppRoute
            isLanding
            path={TRADE_FAIRS_LANDING}
            layout={LandingLayout}
            component={TradeFairsPageLanding}
          />
          <AppRoute
            isLanding
            path={TRADE_FAIRS_LANDING_FORM}
            layout={LandingLayout}
            component={TradeFairsFormLanding}
          />
          <AppRoute
            isLanding
            path={LANDING_OFFICES}
            layout={LandingLayout}
            component={LandingOffices}
          />
          <AppRoute
            isLanding
            path={LANDING_PRODUCTS}
            layout={LandingLayout}
            component={LandingProducts}
          />
          <AppRoute
            isLanding
            path={LANDING_PRIVACY_POLICY}
            layout={LandingLayout}
            component={PrivacyPolicyLanding}
          />
          <AppRoute
            isLanding
            path={LANDING_TERMS_CONDITIONS}
            layout={LandingLayout}
            component={TermsConditionsLanding}
          />
          <AppRoute
            isLanding
            path={LANDING_HELP}
            layout={ContactLayout}
            component={HelpLanding}
          />
          <AppRoute layout={NotFoundLayout} component={NotFoundPage} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
});

const mapDispatchToProps = dispatch => ({
  loginWithRedirect: (token, userId) => {
    dispatch(loginWithCookieRequested(token, userId));
  },
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({ key: 'login', saga });

export default compose(
  withSaga,
  withConnect,
)(App);
