import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withBlurred from 'components/shared/withBlurred';
import Icon from 'components/Icon';
import Button from 'components/Button';

import { MY_ACCOUNT, COLLECTIONS } from 'containers/App/routes';
import { getUserBalancesRequested } from 'containers/AccountProvider/actions';
import { makeSelectBalances } from 'containers/AccountProvider/selectors';
import saga from 'containers/AccountProvider/saga';
import reducer from 'containers/AccountProvider/reducer';

import { avatarGenerator } from 'utils/avatarGenerator';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { storage } from 'utils/storage';

import Menu from './Menu';

import * as S from './style';
import messages from './messages';

const canUseDeposit = storage.getParsedValue('permissions').can_use_deposit;

const ProfileCard = ({ username, open, value }) => (
  <S.ProfileCard>
    <S.NamePreview>{avatarGenerator(username)}</S.NamePreview>
    <S.CardDetail>
      <S.MenuLinkContainer>
        <S.UserName>{username}</S.UserName>
        <Icon name={open ? 'up' : 'down'} width="10" color="#a6a6a6" />
      </S.MenuLinkContainer>
      {canUseDeposit && value !== null ? (
        <S.DepositWrapper>
          <S.Name>
            <FormattedMessage {...messages.depositBalance} />
          </S.Name>
          <S.Value>{value}</S.Value>
        </S.DepositWrapper>
      ) : null}
    </S.CardDetail>
  </S.ProfileCard>
);

const options = [
  {
    text: <FormattedMessage {...messages.profileSettings} />,
    to: MY_ACCOUNT,
    isVisible: true,
  },
  {
    text: <FormattedMessage {...messages.favoriteHotels} />,
    to: COLLECTIONS,
    isVisible: true,
  },
  // {
  //   text: <FormattedMessage {...messages.profit} />,
  //   to: PROFIT,
  //   isVisible: true,
  // },
  {
    text: <FormattedMessage {...messages.agencyManagement} />,
    to: `${MY_ACCOUNT}/?management=agency`,
    isVisible: true,
  },
  {
    text: <FormattedMessage {...messages.subagencyManagement} />,
    to: `${MY_ACCOUNT}/?management=subagency`,
    isVisible: true,
  },
  {
    text: <FormattedMessage {...messages.paymentSettings} />,
    to: '/',
    isVisible: !storage.getItem('IS_SUB_AGENCY'),
  },
  {
    text: <FormattedMessage {...messages.signOut} />,
    to: null,
    isVisible: true,
  },
];

class ProfileMenu extends PureComponent {
  handleChangeShowMenu = () => {
    this.props.handleToggleMenu();
  };

  componentDidMount() {
    this.props.getUserBalances();
  }

  render() {
    const { isShownList, forwardedRef, balances } = this.props;

    return (
      <S.ProfileMenu ref={forwardedRef} isOpened={isShownList}>
        <Button transparent onClick={this.handleChangeShowMenu}>
          <ProfileCard
            username={storage.getItem('USER_FULL_NAME')}
            open={isShownList}
            value={
              balances && balances[0] && balances[0].currency_code
                ? `${balances[0].currency_code}
                  ${parseFloat(balances[0].amount).toFixed(2)}`
                : null
            }
          />
        </Button>
        {isShownList && (
          <S.ListWrapper>
            <S.ProfileMenuList>
              <Menu options={options} />
            </S.ProfileMenuList>
          </S.ListWrapper>
        )}
      </S.ProfileMenu>
    );
  }
}

const mapStateToProps = () =>
  createStructuredSelector({
    balances: makeSelectBalances(),
  });

const mapDispatchToProps = dispatch => ({
  getUserBalances: () => {
    dispatch(getUserBalancesRequested());
  },
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({
  key: 'account',
  saga,
});

const withReducer = injectReducer({
  key: 'account',
  reducer,
});

export default withBlurred(
  compose(
    withConnect,
    withSaga,
    withReducer,
  )(ProfileMenu),
);

ProfileMenu.propTypes = {
  getUserBalances: PropTypes.func,
  handleToggleMenu: PropTypes.func,
  isShownList: PropTypes.bool,
  forwardedRef: PropTypes.object,
  balances: PropTypes.array,
};
