import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.background || '#303030'};
`;

const Loader = styled.div`
  border: 4px solid ${props => props.borderColor || '#e0e0e0'};
  border-top-color: ${props => props.borderTopColor || '#f9a11a'};
  border-radius: 50%;
  animation: 0.8s loader linear infinite;
  position: relative;
  display: inline-block;
  width: ${props => props.size || '60px'};
  height: ${props => props.size || '60px'};
  color: inherit;

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { LoaderContainer, Loader };
