import { call, put, takeLatest, all } from 'redux-saga/effects';

import { getOleryInfoApi } from './api';

import { getOleryDataSucceeded, getOleryDataFailed } from './actions';

import { GET_OLERY_DATA_REQUESTED } from './constants';

export function* getOleryInfoResults({ hotelCode }) {
  try {
    const { data } = yield call(getOleryInfoApi, hotelCode);
    yield put(getOleryDataSucceeded(data));
  } catch (e) {
    yield put(getOleryDataFailed(e));
  }
}

export default function* root() {
  yield all([takeLatest(GET_OLERY_DATA_REQUESTED, getOleryInfoResults)]);
}
