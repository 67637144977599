import { fromJS } from 'immutable';
import { sortBy, uniqWith, isEqual, flattenDeep } from 'lodash';

import * as Status from 'utils/status';

import {
  GET_HOTEL_DETAIL_REQUESTED,
  GET_HOTEL_DETAIL_SUCCEEDED,
  GET_HOTEL_DETAIL_FAILED,
  GET_ROOM_AVAILABILITY_REQUESTED,
  GET_ROOM_AVAILABILITY_SUCCEEDED,
  GET_ROOM_AVAILABILITY_FAILED,
  ADD_BASKET_REQUESTED,
  ADD_BASKET_SUCCEEDED,
  ADD_BASKET_FAILED,
  APPLY_FILTERS,
  SET_HOTEL_PRODUCTS,
} from './constants';

/* eslint-disable camelcase */
export const initialState = fromJS({
  hotelDetail: {
    status: Status.INIT,
    reason: null,
    hotelInfo: {},
  },
  roomAvailability: {
    status: Status.INIT,
    reason: null,
    products: [],
    filteredProducts: [],
    appliedFilters: [],
    collections: [],
    availableFilters: {
      room_types: [],
      meal_types: [],
    },
    applied_provision: false,
  },
  addBasket: {
    status: Status.INIT,
    reason: null,
  },
});

function hotelDetailReducer(state = initialState, action) {
  let mealTypes = [];
  let roomTypes = [];

  switch (action.type) {
    case GET_HOTEL_DETAIL_REQUESTED:
      return state.setIn(['hotelDetail', 'status'], Status.LOADING);
    case GET_HOTEL_DETAIL_SUCCEEDED:
      return state
        .setIn(['hotelDetail', 'status'], Status.LOADED)
        .setIn(['hotelDetail', 'hotelInfo'], fromJS(action.results));
    case GET_HOTEL_DETAIL_FAILED:
      return state
        .setIn(['hotelDetail', 'status'], Status.FAILED)
        .setIn(['hotelDetail', 'reason'], action.reason);
    case GET_ROOM_AVAILABILITY_REQUESTED:
      return state.setIn(['roomAvailability', 'status'], Status.LOADING);
    case GET_ROOM_AVAILABILITY_SUCCEEDED:
      mealTypes = action.results.products.map(item => item.meal_type);
      roomTypes = action.results.products.map(item => {
        if (item && item.rooms) {
          return item.rooms.map(product => product.room_type);
        }
        return null;
      });
      return state
        .setIn(['roomAvailability', 'status'], Status.LOADED)
        .setIn(['roomAvailability', 'collections'], action.results.collections)
        .setIn(
          ['roomAvailability', 'products'],
          sortBy(
            action.results.products,
            product => product.displayed_price.amount,
          ),
        )
        .setIn(['roomAvailability', 'availableFilters'], {
          room_types: uniqWith(flattenDeep(roomTypes), isEqual),
          meal_types: uniqWith(mealTypes, isEqual),
        })
        .setIn(
          ['roomAvailability', 'filteredProducts'],
          fromJS(
            sortBy(
              action.results.products,
              product => product.displayed_price.amount,
            ),
          ),
        )
        .setIn(
          ['roomAvailability', 'applied_provision'],
          action.results.applied_provision,
        );
    case GET_ROOM_AVAILABILITY_FAILED:
      return state
        .setIn(['roomAvailability', 'status'], Status.FAILED)
        .setIn(['roomAvailability', 'reason'], action.reason);
    case ADD_BASKET_REQUESTED:
      return state.setIn(['addBasket', 'status'], Status.LOADING);
    case ADD_BASKET_SUCCEEDED:
      return state.setIn(['addBasket', 'status'], Status.LOADED);
    case ADD_BASKET_FAILED:
      return state
        .setIn(['addBasket', 'status'], Status.FAILED)
        .setIn(['addBasket', 'reason'], action.reason);
    case APPLY_FILTERS:
      return state.setIn(
        ['roomAvailability', 'appliedFilters'],
        fromJS(action.filters),
      );
    case SET_HOTEL_PRODUCTS:
      return state.setIn(
        ['roomAvailability', 'filteredProducts'],
        fromJS(action.products),
      );
    default:
      return state;
  }
}

export default hotelDetailReducer;
