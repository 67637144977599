import { fromJS } from 'immutable';

import * as Status from 'utils/status';
import { storage } from 'utils/storage';

import {
  GET_USER_BALANCES_REQUESTED,
  GET_USER_BALANCES_SUCCEEDED,
  GET_USER_BALANCES_FAILED,
  GET_USER_FINALIZED_BOOKINGS_REQUESTED,
  GET_USER_FINALIZED_BOOKINGS_SUCCEEDED,
  GET_USER_FINALIZED_BOOKINGS_FAILED,
  GET_USER_WAITING_BOOKINGS_REQUESTED,
  GET_USER_WAITING_BOOKINGS_SUCCEEDED,
  GET_USER_WAITING_BOOKINGS_FAILED,
  GET_USER_WAITING_REFUNDS_REQUESTED,
  GET_USER_WAITING_REFUNDS_SUCCEEDED,
  GET_USER_WAITING_REFUNDS_FAILED,
  GET_USER_LOYALTY_REQUESTED,
  GET_USER_LOYALTY_SUCCEEDED,
  GET_USER_LOYALTY_FAILED,
  CREATE_COUPON_CODE_REQUESTED,
  CREATE_COUPON_CODE_SUCCEEDED,
  CREATE_COUPON_CODE_FAILED,
  SET_SELECTED_BOOKING_CODE,
} from './constants';

export const initialState = fromJS({
  reason: null,
  status: Status.INIT,
  balances: JSON.parse(storage.getItem('USER_BALANCE')) || {},
  selectedBookingCode: null,
  finalizedBookings: {
    reason: null,
    status: Status.INIT,
    data: [],
  },
  waitingBookings: {
    reason: null,
    status: Status.INIT,
    data: [],
  },
  waitingRefunds: {
    reason: null,
    status: Status.INIT,
    data: [],
  },
  loyalty: {
    reason: null,
    status: Status.INIT,
    results: [],
  },
  couponCode: {
    reason: null,
    status: Status.INIT,
    results: [],
  },
});

const accountReducer = (state = initialState, action) => {
  const finalizedBookingsData = [];
  const waitingBookingsData = [];
  switch (action.type) {
    case GET_USER_BALANCES_REQUESTED:
      return state.set('status', Status.LOADING);
    case GET_USER_BALANCES_SUCCEEDED:
      if (action.results.objects.length > 0) {
        const balance = action.results.objects;
        storage.setItem('USER_BALANCE', JSON.stringify(balance));
        return state.set('balances', balance).set('status', Status.LOADED);
      }
      storage.setItem('USER_BALANCE', []);
      return state.set('balances', []).set('status', Status.LOADED);
    case GET_USER_BALANCES_FAILED:
      return state.set('status', Status.FAILED);
    case GET_USER_FINALIZED_BOOKINGS_REQUESTED:
      return state.setIn(['finalizedBookings', 'status'], Status.LOADING);
    case GET_USER_FINALIZED_BOOKINGS_SUCCEEDED:
      action.results.objects.map(item =>
        finalizedBookingsData.push({
          reservationNumber: item,
          client: item.user.name,
          cancellation: item.payment_deadline_date,
          roomCoast: item,
          status: item.status,
          leadName: item.lead_name,
        }),
      );
      return state
        .setIn(['finalizedBookings', 'data'], finalizedBookingsData)
        .setIn(['finalizedBookings', 'status'], Status.LOADED);
    case GET_USER_FINALIZED_BOOKINGS_FAILED:
      return state
        .setIn(['finalizedBookings', 'status'], Status.FAILED)
        .setIn(['finalizedBookings', 'reason'], action.reason);
    case GET_USER_WAITING_BOOKINGS_REQUESTED:
      return state.setIn(['waitingBookings', 'status'], Status.LOADING);
    case GET_USER_WAITING_BOOKINGS_SUCCEEDED:
      action.results.objects.map(item =>
        waitingBookingsData.push({
          clientHotel: item,
          roomCoast: item,
          profit: item.agency_price,
          deadline: item.deadline_date,
          code: item.code,
        }),
      );
      return state
        .setIn(['waitingBookings', 'data'], waitingBookingsData)
        .setIn(['waitingBookings', 'status'], Status.LOADED);
    case GET_USER_WAITING_BOOKINGS_FAILED:
      return state
        .setIn(['waitingBookings', 'reason'], action.reason)
        .setIn(['waitingBookings', 'status'], Status.FAILED);
    case GET_USER_WAITING_REFUNDS_REQUESTED:
      return state.setIn(['waitingRefunds', 'status'], Status.LOADING);
    case GET_USER_WAITING_REFUNDS_SUCCEEDED:
      return state
        .setIn(['waitingRefunds', 'data'], action.results.objects)
        .setIn(['waitingRefunds', 'status'], Status.LOADED);
    case GET_USER_WAITING_REFUNDS_FAILED:
      return state
        .setIn(['waitingRefunds', 'reason'], action.reason)
        .setIn(['waitingRefunds', 'status'], Status.FAILED);
    case GET_USER_LOYALTY_REQUESTED:
      return state.setIn(['loyalty', 'status'], Status.LOADING);
    case GET_USER_LOYALTY_SUCCEEDED:
      return state
        .setIn(['loyalty', 'results'], action.results)
        .setIn(['loyalty', 'status'], Status.LOADED);
    case GET_USER_LOYALTY_FAILED:
      return state
        .setIn(['loyalty', 'reason'], action.reason)
        .setIn(['loyalty', 'status'], Status.FAILED);
    case CREATE_COUPON_CODE_REQUESTED:
      return state.setIn(['couponCode', 'status'], Status.LOADING);
    case CREATE_COUPON_CODE_SUCCEEDED:
      return state
        .setIn(['couponCode', 'results'], action.results)
        .setIn(['couponCode', 'status'], Status.LOADED);
    case CREATE_COUPON_CODE_FAILED:
      return state
        .setIn(['couponCode', 'reason'], action.reason)
        .setIn(['couponCode', 'status'], Status.FAILED);
    case SET_SELECTED_BOOKING_CODE:
      return state.set('selectedBookingCode', action.bookingCode);
    default:
      return state;
  }
};

export default accountReducer;
