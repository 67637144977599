import {
  GET_USER_BALANCES_REQUESTED,
  GET_USER_BALANCES_SUCCEEDED,
  GET_USER_BALANCES_FAILED,
  GET_USER_FINALIZED_BOOKINGS_REQUESTED,
  GET_USER_FINALIZED_BOOKINGS_SUCCEEDED,
  GET_USER_FINALIZED_BOOKINGS_FAILED,
  GET_USER_WAITING_BOOKINGS_REQUESTED,
  GET_USER_WAITING_BOOKINGS_SUCCEEDED,
  GET_USER_WAITING_BOOKINGS_FAILED,
  GET_USER_WAITING_REFUNDS_REQUESTED,
  GET_USER_WAITING_REFUNDS_SUCCEEDED,
  GET_USER_WAITING_REFUNDS_FAILED,
  GET_USER_LOYALTY_REQUESTED,
  GET_USER_LOYALTY_SUCCEEDED,
  GET_USER_LOYALTY_FAILED,
  CREATE_COUPON_CODE_REQUESTED,
  CREATE_COUPON_CODE_SUCCEEDED,
  CREATE_COUPON_CODE_FAILED,
  SET_SELECTED_BOOKING_CODE,
} from './constants';

export const getUserBalancesRequested = () => ({
  type: GET_USER_BALANCES_REQUESTED,
});

export const getUserBalancesSucceeded = results => ({
  type: GET_USER_BALANCES_SUCCEEDED,
  results,
});

export const getUserBalancesFailed = reason => ({
  type: GET_USER_BALANCES_FAILED,
  reason,
});

export const getUserFinalizedBookingsRequested = params => ({
  type: GET_USER_FINALIZED_BOOKINGS_REQUESTED,
  params,
});

export const getUserFinalizedBookingsSucceeded = results => ({
  type: GET_USER_FINALIZED_BOOKINGS_SUCCEEDED,
  results,
});

export const getUserFinalizedBookingFailed = reason => ({
  type: GET_USER_FINALIZED_BOOKINGS_FAILED,
  reason,
});

export const getUserWaitingBookingRequested = params => ({
  type: GET_USER_WAITING_BOOKINGS_REQUESTED,
  params,
});

export const getUserWaitingBookingSucceeded = results => ({
  type: GET_USER_WAITING_BOOKINGS_SUCCEEDED,
  results,
});

export const getUserWaitingBookingFailed = reason => ({
  type: GET_USER_WAITING_BOOKINGS_FAILED,
  reason,
});

export const getUserWaitingRefundsRequested = params => ({
  type: GET_USER_WAITING_REFUNDS_REQUESTED,
  params,
});

export const getUserWaitingRefundsSucceeded = results => ({
  type: GET_USER_WAITING_REFUNDS_SUCCEEDED,
  results,
});

export const getUserWaitingRefundsFailed = reason => ({
  type: GET_USER_WAITING_REFUNDS_FAILED,
  reason,
});

export const getUserLoyaltyRequested = () => ({
  type: GET_USER_LOYALTY_REQUESTED,
});

export const getUserLoyaltySucceeded = results => ({
  type: GET_USER_LOYALTY_SUCCEEDED,
  results,
});

export const getUserLoyaltyFailed = reason => ({
  type: GET_USER_LOYALTY_FAILED,
  reason,
});

export const createCouponCodeRequested = (bookingCode, formData) => ({
  type: CREATE_COUPON_CODE_REQUESTED,
  formData,
  bookingCode,
});

export const createCouponCodeSucceeded = results => ({
  type: CREATE_COUPON_CODE_SUCCEEDED,
  results,
});

export const createCouponCodeFailed = reason => ({
  type: CREATE_COUPON_CODE_FAILED,
  reason,
});

export const setSelectedBookingCode = bookingCode => ({
  type: SET_SELECTED_BOOKING_CODE,
  bookingCode,
});
