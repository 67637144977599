import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { range } from 'lodash';

import * as Status from 'utils/status';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import {
  makeSelectCommissions,
  makeSelectCommissionsStatus,
  makeSelectUserPriceMargin,
} from 'containers/CommissionProvider/selectors';
import {
  getUserCommissionsRequested,
  setPriceMarginRequested,
} from 'containers/CommissionProvider/actions';
import saga from 'containers/CommissionProvider/saga';
import reducer from 'containers/CommissionProvider/reducer';

import withBlurred from 'components/shared/withBlurred';

import * as S from './style';
import messages from './messages';

class PriceMargin extends PureComponent {
  handleChangeShowMenu = () => {
    const { getCommissions, commissions } = this.props;
    if (!(commissions.min_commission_rate || commissions.max_commission_rate)) {
      getCommissions();
    }
    this.props.handleToggleMenu();
  };

  setPriceMargin = priceMargin => {
    const { setPriceMargin } = this.props;
    setPriceMargin(priceMargin);
  };

  render() {
    const {
      commissions,
      priceMargin,
      status,
      right,
      isShownList,
      forwardedRef,
    } = this.props;

    const priceMarginOptions = [];

    range(commissions.min_commission_rate, commissions.max_commission_rate).map(
      item => priceMarginOptions.push({ name: item }),
    );

    return (
      <S.PriceMarginSelect ref={forwardedRef} right={right}>
        <S.PriceMarginMenu
          selected={<FormattedMessage {...messages.priceMargin} />}
          onClick={this.handleChangeShowMenu}
          isOpened={isShownList}
        >
          {isShownList && (
            <S.PriceMarginList
              isLoaded={status === Status.LOADED}
              onClickedOption={option => this.setPriceMargin(option.name)}
              options={priceMarginOptions}
              header={<FormattedMessage {...messages.priceMarginHeader} />}
              selected={priceMargin}
              isJustName
            />
          )}
        </S.PriceMarginMenu>
      </S.PriceMarginSelect>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  commissions: makeSelectCommissions(),
  priceMargin: makeSelectUserPriceMargin(),
  status: makeSelectCommissionsStatus(),
});

const mapDispatchToProps = dispatch => ({
  getCommissions: () => {
    dispatch(getUserCommissionsRequested());
  },
  setPriceMargin: priceMargin => {
    dispatch(setPriceMarginRequested(priceMargin));
  },
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({
  key: 'commission',
  reducer,
});

const withSaga = injectSaga({
  key: 'priceMargin',
  saga,
});

export default withBlurred(
  injectIntl(
    compose(
      withConnect,
      withReducer,
      withSaga,
    )(PriceMargin),
  ),
);
