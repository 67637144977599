import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
  getUserCommissionsSucceeded,
  getUserCommissionsFailed,
  setUserCommissionSucceeded,
  setUserCommissionFailed,
  setPriceMarginSucceeded,
  setPriceMarginFailed,
} from './actions';

import {
  getUserCommissionsApi,
  setUserCommissionApi,
  setUserPriceMarginApi,
} from './api';

import {
  GET_COMMISSIONS_REQUESTED,
  SET_COMMISSION_RATE_REQUESTED,
  SET_PRICE_MARGIN_REQUESTED,
} from './constants';

export function* getUserCommissionsResult() {
  try {
    const { data } = yield call(getUserCommissionsApi);
    yield put(getUserCommissionsSucceeded(data));
  } catch (e) {
    yield put(getUserCommissionsFailed(e));
  }
}

export function* setUserCommissionRate({ commissionRate }) {
  try {
    yield call(setUserCommissionApi, {
      commission_rate: commissionRate,
    });
    yield put(setUserCommissionSucceeded(commissionRate));
  } catch (e) {
    yield put(setUserCommissionFailed(e));
  }
}

export function* setPriceMargin({ priceMargin }) {
  try {
    yield call(setUserPriceMarginApi, {
      displayed_price_rate: priceMargin,
    });
    yield put(setPriceMarginSucceeded(priceMargin));
  } catch (e) {
    if (e) {
      const { data, status } = e.response;
      if (status >= 400) {
        yield put(setPriceMarginFailed(data.error));
      }
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_COMMISSIONS_REQUESTED, getUserCommissionsResult),
    takeLatest(SET_COMMISSION_RATE_REQUESTED, setUserCommissionRate),
    takeLatest(SET_PRICE_MARGIN_REQUESTED, setPriceMargin),
  ]);
}
