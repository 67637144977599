import { defineMessages } from 'react-intl';

export const scope = 'app.components.shared.HotelHeader';

export default defineMessages({
  reviewText: {
    id: `${scope}.reviewText`,
    defaultMessage: '{count} {message}',
  },
  review: {
    id: `${scope}.review`,
    defaultMessage: 'Review',
  },
  reviews: {
    id: `${scope}.reviews`,
    defaultMessage: 'Reviews',
  },
  copy: {
    id: `${scope}.copy`,
    defaultMessage: 'Copy',
  },
});
