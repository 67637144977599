import { fromJS } from 'immutable';

import * as Status from 'utils/status';

import { storage } from 'utils/storage';

import {
  CHANGE_LOCALE,
  GET_LANGUAGES_REQUESTED,
  GET_LANGUAGES_SUCCEEDED,
  GET_LANGUAGES_FAILED,
  SET_USER_LANGUAGE_REQUESTED,
  SET_USER_LANGUAGE_SUCCEEDED,
  SET_USER_LANGUAGE_FAILED,
  GET_CORE_LANGUAGES_REQUESTED,
  GET_CORE_LANGUAGES_SUCCEEDED,
  GET_CORE_LANGUAGES_FAILED,
} from './constants';

import { DEFAULT_LOCALE } from '../../i18n'; // eslint-disable-line

export const initialState = fromJS({
  locale: storage.getItem('USER_LANGUAGE') || DEFAULT_LOCALE,
  languages: [],
  getLanguages: {
    reason: null,
    status: Status.INIT,
  },
  setLanguage: {
    reason: null,
    status: Status.INIT,
  },
});

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return state.set('locale', action.locale);
    case GET_LANGUAGES_REQUESTED:
    case GET_CORE_LANGUAGES_REQUESTED:
      return state.setIn(['getLanguages', 'status'], Status.LOADING);
    case GET_LANGUAGES_SUCCEEDED:
    case GET_CORE_LANGUAGES_SUCCEEDED:
      return state
        .set('languages', action.results.objects)
        .setIn(['getLanguages', 'status'], Status.LOADED);
    case GET_LANGUAGES_FAILED:
    case GET_CORE_LANGUAGES_FAILED:
      return state.setIn(['getLanguages', 'status'], Status.FAILED);
    case SET_USER_LANGUAGE_REQUESTED:
      return state.set(['setLanguage', 'status'], Status.LOADING);
    case SET_USER_LANGUAGE_SUCCEEDED:
      storage.setItem('USER_LANGUAGE', action.language.code);
      storage.setItem('USER_LANGUAGE_NAME', action.language.name);
      return state
        .set('locale', action.language.code)
        .setIn(['setLanguage', 'status'], Status.LOADED);
    case SET_USER_LANGUAGE_FAILED:
      return state
        .setIn(['setLanguage', 'reason'], action.reason)
        .setIn(['setLanguage', 'status'], Status.FAILED);
    default:
      return state;
  }
}

export default languageProviderReducer;
