import React from 'react';
import { FormattedMessage } from 'react-intl';
import Cookie from 'js-cookie';

import { EXTERNAL_URLS } from 'containers/App/routes';

import { sendPageSpecificCustomDimensions } from 'helpers/analytics';

import Button from 'components/Button';
import Icon from 'components/Icon';

import messages from './messages';

/* eslint-disable camelcase */
export const SwitchBackVersion = () => {
  const handleBackVersion = () => {
    Cookie.set('use_redesign', 1, {
      path: '',
      domain: '.hprotravel.com',
    });
    sendPageSpecificCustomDimensions({ CD_Redirect_Source: 'new_to_old' });
    window.open(EXTERNAL_URLS.oldHpro, '_self');
  };
  return (
    <Button className="switch-back-button" onClick={handleBackVersion}>
      <Icon name="change" width={14} color="#FFF" />
      <FormattedMessage {...messages.switchBackVersion} />
    </Button>
  );
};
