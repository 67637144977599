import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HOME } from 'containers/App/routes';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { remove } from 'lodash';

import PriceMargin from 'components/PriceMarginSelect';
import Commission from 'components/CommissionSelect';
import Currency from 'components/CurrencySelect';
import Language from 'components/LanguageSelect';
import DepositBalance from 'components/DepositBalance';

import { makeSelectUserID } from 'containers/MyAccount/selectors';
import { getReportsRequested } from 'containers/Reports/actions';
import reducer from 'containers/Reports/reducer';
import saga from 'containers/Reports/saga';
import {
  makeSelectReports,
  makeSelectReportsStatus,
} from 'containers/Reports/selectors';
import {
  makeSelectLoyalty,
  makeSelectLoyaltyStatus,
  makeSelectBalances,
} from 'containers/AccountProvider/selectors';

import { storage } from 'utils/storage';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import * as Status from 'utils/status';

import Logo from 'images/HproTravelLogo.svg';

import { menuList } from './menuList';
import ProfileMenu from './ProfileMenu/index';
import HeaderMenu from './menu';

import * as S from './style';

const hasShownCommission = storage.getParsedValue('permissions')
  .use_user_commission;
const hasShownPriceMargin =
  storage.getParsedValue('permissions').can_use_user_margin &&
  storage.getParsedValue('IS_SUB_AGENCY');

class Header extends Component {
  state = {
    headerList: menuList,
  };

  UNSAFE_componentWillMount() {
    const { getReports, reportsStatus, userId } = this.props;

    if (reportsStatus !== Status.LOADED) {
      getReports(userId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { headerList } = this.state;
    const newHeaderList = [...headerList];

    if (
      nextProps.loyaltyStatus === Status.LOADED &&
      !nextProps.loyalty.is_loyalty_active
    ) {
      remove(newHeaderList, item => item.name === 'profit');
      this.setState({
        headerList: newHeaderList,
      });
    }

    // if (nextProps.reports.length === 0 && reportsStatus === Status.LOADED) {
    //   remove(newHeaderList, item => item.name === 'reports');
    //   this.setState({
    //     headerList: newHeaderList,
    //   });
    // } else if (differenceWith(nextProps.reports, reports, isEqual).length) {
    //   /* eslint-disable no-param-reassign */
    //   nextProps.reports.map(item => {
    //     item.href = `${REPORTS}?report_type=${item.title
    //       .replace(/ +/g, '_')
    //       .toLowerCase()}`;
    //     return null;
    //   });
    //   find(newHeaderList, item => item.name === 'reports').subItems =
    //     nextProps.reports;
    //   this.setState({
    //     headerList: newHeaderList,
    //   });
    // }
  }

  render() {
    const { headerList } = this.state;
    const { balances } = this.props;
    return (
      <S.Header className="header-container">
        <S.MainLogoWrapper to={HOME} transparent>
          <S.LogoImage src={Logo} alt="logo" />
        </S.MainLogoWrapper>
        <S.ListContainer>
          <S.HeaderList>
            <HeaderMenu headerList={headerList} />
          </S.HeaderList>
          <S.MenuList>
            <S.ListItem>
              <Language />
            </S.ListItem>
            <S.ListItem>
              <Currency />
            </S.ListItem>
            {hasShownCommission && (
              <S.ListItem>
                <Commission />
              </S.ListItem>
            )}
            {hasShownPriceMargin && (
              <S.ListItem>
                <PriceMargin />
              </S.ListItem>
            )}
            {balances.length > 0 && (
              <S.ListItem>
                <DepositBalance />
              </S.ListItem>
            )}
          </S.MenuList>
        </S.ListContainer>
        <ProfileMenu />
      </S.Header>
    );
  }
}

Header.propTypes = {
  getReports: PropTypes.func,
  userId: PropTypes.string,
  reportsStatus: PropTypes.string,
  balances: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  loyalty: makeSelectLoyalty(),
  loyaltyStatus: makeSelectLoyaltyStatus(),
  reports: makeSelectReports(),
  reportsStatus: makeSelectReportsStatus(),
  userId: makeSelectUserID(),
  balances: makeSelectBalances(),
});

const mapDispatchToProps = dispatch => ({
  getReports: userId => {
    dispatch(getReportsRequested(userId));
  },
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'reports', reducer });
const withSaga = injectSaga({ key: 'reports', saga });

export default withRouter(
  compose(
    withReducer,
    withSaga,
    withConnect,
  )(Header),
);
