import styled, { css } from 'styled-components';

const Icon = styled.svg`
  fill: currentColor;
  height: ${props => props.width}px;
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: #949494;
    `};
`;

export { Icon };
