import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import Badge from 'components/Badge';
import OleryRate from 'components/shared/OleryRate';
import Review from 'components/shared/Review';
import OleryBadge from 'components/shared/OleryBadge';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import OleryLogo from 'images/olery.png';

import saga from './saga';
import reducer from './reducer';

import messages from './messages';

import './style.scss';

const HOTEL_RATE_RATIO = 10;

export const ReviewItem = ({ title, children, className }) => {
  const classes = classNames('review-item-container', className);
  return (
    <article className={classes}>
      {title && <span className="review-item__title">{title}</span>}
      {children}
    </article>
  );
};

ReviewItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
};

export const TravellerRateBadge = ({ rate, count, className }) => {
  const classes = classNames('traveller-rate__badge', className);

  if (rate === null || count === null) return null;
  const hotelRate = rate === 0 ? 0 : (rate / HOTEL_RATE_RATIO).toFixed(1);
  return (
    <div className={classes}>
      <Badge>{hotelRate}</Badge>
      <div className="traveller-rate__detail">
        <span className="detail__level">Excellent</span>
        {count && (
          <span className="detail__count">
            <FormattedMessage
              {...messages.reviewCount}
              values={{
                reviewCount: <strong> {count}</strong>,
              }}
            />
          </span>
        )}
      </div>
    </div>
  );
};

TravellerRateBadge.propTypes = {
  rate: PropTypes.number,
  count: PropTypes.number,
  level: PropTypes.string,
};

/* eslint-disable camelcase */

export const renderOleryGuestsLoves = (opinions = [], max) => (
  <ul className="olery__guests-loves">
    {opinions.map((item, index) => (
      <Fragment key={`option-${index.toString()}`}>
        {index < max && (
          <li className="olery__guest-loves-item">
            <Icon name="heartTransparent" width={14} color="#adadad" />
            {item && item[1] && item[1].label}
          </li>
        )}
      </Fragment>
    ))}
  </ul>
);

export const compositionRating = (ratings = [], count) => {
  const mappedData = ratings.slice(0, count);
  return mappedData.map((item, idx) => (
    <OleryBadge key={`item-${idx.toString()}`} name={item[0]}>
      {item[1] && item[1].label}
    </OleryBadge>
  ));
};

/* eslint-enable camelcase */

export const renderReviewsRates = reviewsRates =>
  reviewsRates.map(
    (item, idx) =>
      item &&
      item[1] &&
      item[1].value && (
        <OleryRate
          key={`review-${idx.toString()}`}
          value={item[1].value}
          header={item[1].title}
          rate={Number((item[1].value / 10).toFixed(1))}
        />
      ),
  );

export const renderSummaryReviews = (summaries = []) =>
  summaries.map((item, idx) => {
    const summary = item[`${Object.keys(item)[0]}`];
    return (
      summary &&
      summary.sentiment && (
        <li key={`summary-${idx.toString()}`} className="summary-list__item">
          <Review isNegative={summary.sentiment === 'negative'}>
            {summary.text}
          </Review>
        </li>
      )
    );
  });

export const renderOleryInfo = () => (
  <div className="reviews__olery-info">
    <figure className="reviews__olery-figure">
      <img src={OleryLogo} alt="olery-logo" className="logo" />
    </figure>
    <FormattedMessage {...messages.oleryInfo} />
  </div>
);

const Olery = props => {
  const { children } = props;
  return children;
};

const withSaga = injectSaga({
  key: 'olery',
  saga,
});

const withReducer = injectReducer({
  key: 'olery',
  reducer,
});

export default compose(
  withSaga,
  withReducer,
)(Olery);
