import { fromJS } from 'immutable';

import * as Status from 'utils/status';

import { storage } from 'utils/storage';

import {
  GET_CURRENCIES_REQUESTED,
  GET_CURRENCIES_SUCCEEDED,
  GET_CURRENCIES_FAILED,
  SET_CURRENCY_CODE_REQUESTED,
  SET_CURRENCY_CODE_SUCCEEDED,
  SET_CURRENCY_CODE_FAILED,
} from './constants';

export const initialState = fromJS({
  reason: null,
  status: Status.INIT,
  currencies: [],
  currencyCode: storage.getItem('USER_CURRENCY'),
});

function currencyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENCIES_REQUESTED:
      return state.set('status', Status.LOADING);
    case GET_CURRENCIES_SUCCEEDED:
      return state
        .set('currencies', action.results.objects)
        .set('status', Status.LOADED);
    case GET_CURRENCIES_FAILED:
      return state.set('reason', action.reason).set('status', Status.FAILED);
    case SET_CURRENCY_CODE_REQUESTED:
      return state.set('status', Status.LOADING);
    case SET_CURRENCY_CODE_SUCCEEDED:
      storage.setItem('USER_CURRENCY', action.currencyCode);
      return state
        .set('currencyCode', action.currencyCode)
        .set('status', Status.LOADED);
    case SET_CURRENCY_CODE_FAILED:
      return state.set('reason', action.reason).set('status', Status.FAILED);
    default:
      return state;
  }
}

export default currencyReducer;
