import { createSelector } from 'reselect';
import { initialState } from './reducer';

const getCurrencyState = state => state.get('currency', initialState);

const makeSelectCurrencies = () =>
  createSelector(getCurrencyState, state => state.get('currencies'));

const makeSelectCurrencyCode = () =>
  createSelector(getCurrencyState, state => state.get('currencyCode'));

const makeSelectCurrenciesStatus = () =>
  createSelector(getCurrencyState, state => state.get('status'));

export {
  makeSelectCurrencies,
  makeSelectCurrencyCode,
  makeSelectCurrenciesStatus,
};
