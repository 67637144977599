import { storage } from 'utils/storage';

import { IS_SHOWN_SWITCH_NOTIFICATION } from 'config';

const bookingRuleDate = new Date('2019-01-01T00:00:00');

export const checkShownSwitchDesignButton = () => {
  const storageLastBooking = storage.getItem('LAST_BOOKING_CREATED_DATE');
  const lastBookingDate = storageLastBooking
    ? new Date(storageLastBooking)
    : bookingRuleDate;
  const showNotification = storage.getParsedValue('SHOW_NOTIFICATION');

  return (
    showNotification &&
    lastBookingDate >= bookingRuleDate &&
    JSON.parse(IS_SHOWN_SWITCH_NOTIFICATION)
  );
};
