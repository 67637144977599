import { defineMessages } from 'react-intl';

export const scope = 'app.components.Header.ProfileMenu';

export default defineMessages({
  profileSettings: {
    id: `${scope}.profileSettings`,
    defaultMessage: 'Profile Settings',
  },
  favoriteHotels: {
    id: `${scope}.favoriteHotels`,
    defaultMessage: 'Favorite Hotels',
  },
  profit: {
    id: `${scope}.profit`,
    defaultMessage: 'Profit',
  },
  paymentSettings: {
    id: `${scope}.paymentSettings`,
    defaultMessage: 'Payment Settings',
  },
  signOut: {
    id: `${scope}.signOut`,
    defaultMessage: 'Sign Out',
  },
  depositBalance: {
    id: `${scope}.depositBalance`,
    defaultMessage: 'Deposit Balance',
  },
  agencyManagement: {
    id: `${scope}.agencyManagement`,
    defaultMessage: 'Agency and User Management',
  },
  subagencyManagement: {
    id: `${scope}.subagencyManagement`,
    defaultMessage: 'Subagency and User Management',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Are You Sure Want to Log Out?',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'You will be returned to the login screen.',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Log out',
  },
});
