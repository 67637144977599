import { defineMessages } from 'react-intl';

export const scope = 'app.components.LanguageSelect';

export default defineMessages({
  selectLanguageHeader: {
    id: `${scope}.selectLanguageHeader`,
    defaultMessage: 'Select your languages',
  },
});
