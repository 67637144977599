import React from 'react';
import { FormattedMessage } from 'react-intl';
import Cookie from 'js-cookie';
import { sendPageSpecificCustomDimensions } from 'helpers/analytics';

import LogoImage from 'images/ht-logo-white.png';

import { IS_SHOWN_SWITCH_NOTIFICATION } from 'config';

import Icon from 'components/Icon';

import { EXTERNAL_URLS } from 'containers/App/routes';

import messages from 'components/Sidebar/messages';
import { footerList } from './footerList';

import * as S from './style';

const handleBackVersion = () => {
  Cookie.set('use_redesign', 1, { path: '', domain: '.hprotravel.com' });
  // eslint-disable-next-line camelcase
  sendPageSpecificCustomDimensions({ CD_Redirect_Source: 'new_to_old' });
  window.open(EXTERNAL_URLS.oldHpro, '_self');
};

const SwitchBackVersionButton = () => (
  <S.SwitchBack>
    <S.Item key="switch">
      <S.Navigation
        exact={false}
        strict={false}
        to={false}
        onClick={handleBackVersion}
      >
        <S.ItemWrapper>
          <Icon name="change" width={14} />
          <S.ItemName>
            <FormattedMessage {...messages.switchToOldVersion} />
          </S.ItemName>
        </S.ItemWrapper>
      </S.Navigation>
    </S.Item>
  </S.SwitchBack>
);

const Logo = () => (
  <S.LogoWrapper>
    <S.Logo src={LogoImage} alt="logo" />
  </S.LogoWrapper>
);

const FooterList = () =>
  footerList.map(({ key, href, name }) => (
    <S.FooterItemWrapper key={key}>
      <S.FooterItem exact to={href}>
        <FormattedMessage {...messages[name]} />
      </S.FooterItem>
    </S.FooterItemWrapper>
  ));

const Footer = () => (
  <S.FooterWrapper>
    <S.SwitchToOldHpro>
      <Logo />
      {JSON.parse(IS_SHOWN_SWITCH_NOTIFICATION) && <SwitchBackVersionButton />}
    </S.SwitchToOldHpro>
    <S.FooterListContainer>
      <FooterList />
    </S.FooterListContainer>
  </S.FooterWrapper>
);

export default Footer;
