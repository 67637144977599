import { call, put, takeLatest, all } from 'redux-saga/effects';

import history from 'utils/history';

import {
  getUserCurrenciesSucceeded,
  getUserCurrenciesFailed,
  setUserCurrencySucceeded,
  setUserCurrencyFailed,
} from './actions';

import { getUserCurrenciesApi, setUserCurrencyCodeApi } from './api';

import {
  GET_CURRENCIES_REQUESTED,
  SET_CURRENCY_CODE_REQUESTED,
} from './constants';

export function* getUserCurrenciesResult() {
  try {
    const { data } = yield call(getUserCurrenciesApi);
    yield put(getUserCurrenciesSucceeded(data));
  } catch (e) {
    yield put(getUserCurrenciesFailed(e));
  }
}

export function* setUserCurrencyCode({ currencyCode, forceRefresh = false }) {
  try {
    yield call(setUserCurrencyCodeApi, {
      currency_code: currencyCode,
    });
    if (forceRefresh) {
      history.go();
    }
    yield put(setUserCurrencySucceeded(currencyCode));
  } catch (e) {
    yield put(setUserCurrencyFailed(e));
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_CURRENCIES_REQUESTED, getUserCurrenciesResult),
    takeLatest(SET_CURRENCY_CODE_REQUESTED, setUserCurrencyCode),
  ]);
}
