import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash';
import 'react-toastify/dist/ReactToastify.min.css';

import Icon from 'components/Icon';

import * as S from './style';

/* eslint-disable react/prefer-stateless-function */
class Toast extends Component {
  render() {
    const { status, iconName, message, className } = this.props;
    const iconColor = status ? '#27da58' : '#ff5774';
    const classes = classNames('toast-message', className);
    const toastProps = omit(this.props, 'className');
    return (
      <S.Toast {...toastProps} className={classes}>
        <Icon name={iconName} width="12" color={iconColor} />
        <S.ToastMessage>{message}</S.ToastMessage>
      </S.Toast>
    );
  }
}

Toast.propTypes = {
  status: PropTypes.bool,
  iconName: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Toast.defaultProps = {
  status: true,
  iconName: 'check',
  message: 'Success!',
};

export default Toast;
