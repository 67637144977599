import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import HProTravelLogo from 'images/ht-logo-white.png';
// import MetglobalLogo from 'images/logo@metglobal-white.png';
import BankLogosWhite from 'images/bankLogoFooter.png';
import FacebookIcon from 'images/facebook.svg';
import LinkedinIcon from 'images/linkedin.svg';
import InstagramIcon from 'images/instagram.svg';

import {
  LANDING_PRODUCTS,
  TRADE_FAIRS_LANDING,
  LANDING_PRIVACY_POLICY,
  LANDING_TERMS_CONDITIONS,
  LANDING_FAQ,
} from 'containers/App/routes';

import messages from './messages';

import * as S from './style';

const offices = [
  'Dubai',
  'London',
  'Orlando',
  'Cancun',
  'Shanghai',
  'Istanbul',
];

const LandingFooter = () => (
  <S.Footer>
    <S.Wrapper>
      <S.HeaderTop>
        <S.Logo>
          <S.LogoImage src={HProTravelLogo} alt="HProTravel" />
        </S.Logo>
        <S.SocialMedia>
          <S.Item
            href="https://www.linkedin.com/company/hpro-travel/"
            target="_blank"
          >
            <S.SocialImage src={LinkedinIcon} alt="HProTravel Linkedin" />
          </S.Item>
          <S.Item href="https://www.facebook.com/HProTravell/" target="_blank">
            <S.SocialImage src={FacebookIcon} alt="HProTravel Facebook" />
          </S.Item>
          <S.Item href="https://www.instagram.com/hpro.travel/" target="_blank">
            <S.SocialImage src={InstagramIcon} alt="HProTravel Instagram" />
          </S.Item>
        </S.SocialMedia>
      </S.HeaderTop>
      <S.FooterNavigation>
        <S.Links>
          <S.Column>
            <S.ColumnHero>
              <FormattedMessage {...messages.ourOffices} />
            </S.ColumnHero>
            <S.List>
              {offices.map(office => (
                <S.ListItem>{office}</S.ListItem>
              ))}
            </S.List>
          </S.Column>
          <S.Column>
            <S.ColumnHero>
              <FormattedMessage {...messages.ourProducts} />
            </S.ColumnHero>
            <S.List>
              <S.ListItem>
                <NavLink to={LANDING_PRODUCTS}>Compass</NavLink>
              </S.ListItem>
              <S.ListItem>
                <NavLink to={LANDING_PRODUCTS}>Coral</NavLink>
              </S.ListItem>
              <S.ListItem>
                <NavLink to={LANDING_PRODUCTS}>Cosmos</NavLink>
              </S.ListItem>
              <S.ListItem>
                <NavLink to={LANDING_PRODUCTS}>Zeppelin</NavLink>
              </S.ListItem>
            </S.List>
          </S.Column>
          <S.Column>
            <S.ColumnHero>
              <FormattedMessage {...messages.company} />
            </S.ColumnHero>
            <S.List>
              <S.ListItem href="" target="_blank">
                <FormattedMessage {...messages.aboutUs} />
              </S.ListItem>
              <S.ListItem href="" target="_blank">
                <FormattedMessage {...messages.career} />
              </S.ListItem>
            </S.List>
          </S.Column>
          {/*
          <S.Column>
            <S.ColumnHero>
              <FormattedMessage {...messages.blog} />
            </S.ColumnHero>
            
              <S.Newsletter>
                <S.Description><FormattedMessage {...messages.newsletterDesc} /></S.Description>
                <S.Form>
                  <S.Input placeholder="E-mail address" />
                  <S.Button>
                    <Icon name="envelopeSend" width="24" color="#fff" />
                  </S.Button>
                </S.Form>
              </S.Newsletter> 
          </S.Column> */}
        </S.Links>
      </S.FooterNavigation>
      <S.FooterBottom>
        {/* <S.MetglobalLogo src={MetglobalLogo} alt="Metglobal" /> */}
        <S.BottomNavigation>
          <S.BottomItem href={LANDING_TERMS_CONDITIONS}>
            <FormattedMessage {...messages.termsAndConditions} />
          </S.BottomItem>
          <S.BottomItem href={LANDING_PRIVACY_POLICY}>
            <FormattedMessage {...messages.privacyPolicy} />
          </S.BottomItem>
          <S.BottomItem href={TRADE_FAIRS_LANDING}>
            <FormattedMessage {...messages.tradeFairs} />
          </S.BottomItem>
          <S.BottomItem href={LANDING_FAQ}>
            <FormattedMessage {...messages.faq} />
          </S.BottomItem>
        </S.BottomNavigation>
        <S.BankLogos src={BankLogosWhite} alt="HProTravel payments" />
      </S.FooterBottom>
      <S.Copyright>
        <FormattedMessage {...messages.allRightsReserved} />
      </S.Copyright>
    </S.Wrapper>
  </S.Footer>
);

export default LandingFooter;
