import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CreateOffer.OfferPdf';

export default defineMessages({
  roomType: {
    id: `${scope}.roomType`,
    defaultMessage: 'Room Type',
  },
  boardType: {
    id: `${scope}.boardType`,
    defaultMessage: 'Board Type',
  },
  hotel: {
    id: `${scope}.hotel`,
    defaultMessage: 'Hotel',
  },
  cancellation: {
    id: `${scope}.cancellation`,
    defaultMessage: 'Cancellation',
  },
  totalPrice: {
    id: `${scope}.totalPrice`,
    defaultMessage: 'Total Price',
  },
  refundable: {
    id: `${scope}.refundable`,
    defaultMessage: 'Refundable',
  },
  download: {
    id: `${scope}.download`,
    defaultMessage: 'Download',
  },
  nonRefundable: {
    id: `${scope}.nonRefundable`,
    defaultMessage: 'Non-Refundable',
  },
  priceDescription: {
    id: `${scope}.priceDescription`,
    defaultMessage:
      'Availability and prices are only valid at the time of search. They might change when you check again later.',
  },
  cityTaxInformation: {
    id: `${scope}.cityTaxInformation`,
    defaultMessage:
      'City tax will be charged at the hotel, in the local currency.',
  },
  cityTaxDescription: {
    id: `${scope}.cityTaxDescription`,
    defaultMessage:
      'will request these visa documents from the hotel upon Your acceptance of a 30 EUR (thirty Euros) charge in the event that You cancel the booking.',
  },
});
