import moment from 'moment';
import { max } from 'lodash';

export const formatDate = (date, type = 'DD MMM YYYY') =>
  moment(date).format(type);

export const getDiffDays = (checkout, checkin) =>
  moment(checkout).diff(moment(checkin), 'days');

export const getDiffInMinutes = (date, endDate) =>
  moment(endDate).diff(moment(date), 'minutes');

export const getDiffInWeeks = (date, endDate = moment()) =>
  moment(endDate).diff(moment(date), 'week');

export const momentHumanize = (eventDuration, unit) => {
  const eventMDuration = moment.duration(eventDuration, unit);
  const eventDurationArray = [];
  if (eventMDuration.years() > 0) {
    eventDurationArray.push(`${eventMDuration.years()} years`);
    eventMDuration.subtract(eventMDuration.years(), 'years');
  }
  if (eventMDuration.months() > 0) {
    eventDurationArray.push(`${eventMDuration.months()} months`);
    eventMDuration.subtract(eventMDuration.months(), 'months');
  }
  if (eventMDuration.weeks() > 0) {
    eventDurationArray.push(`${eventMDuration.weeks()} weeks`);
    eventMDuration.subtract(eventMDuration.weeks(), 'weeks');
  }
  if (eventMDuration.days() > 0) {
    eventDurationArray.push(`${eventMDuration.days()} days`);
    eventMDuration.subtract(eventMDuration.days(), 'days');
  }
  if (eventMDuration.hours() > 0) {
    eventDurationArray.push(`${eventMDuration.hours()} hours`);
    eventMDuration.subtract(eventMDuration.hours(), 'hours');
  }
  if (eventMDuration.minutes() > 0) {
    eventDurationArray.push(`${eventMDuration.minutes()} minutes`);
  }

  return eventDurationArray.length === 1
    ? eventDurationArray[0]
    : eventDurationArray.join(' and ');
};

export const isFreePast = deadline => moment(deadline).isBefore(moment());

export const deadlineDate = (policy, checkin, format) =>
  formatDate(
    moment(checkin).subtract(policy.days_remaining, 'days'),
    format || '',
  );

export const freeCancellationDeadline = (policies, checkin, format) =>
  deadlineDate(max(policies, 'days_remaining') || {}, checkin, format || '');

export const closestDateToNow = (firstDate, secondDate) =>
  Math.abs(getDiffDays(moment(), firstDate)) -
  Math.abs(getDiffDays(moment(), secondDate));
