import {
  GET_COMMISSIONS_REQUESTED,
  GET_COMMISSIONS_SUCCEEDED,
  GET_COMMISSIONS_FAILED,
  SET_COMMISSION_RATE_REQUESTED,
  SET_COMMISSION_RATE_SUCCEEDED,
  SET_COMMISSION_RATE_FAILED,
  SET_PRICE_MARGIN_REQUESTED,
  SET_PRICE_MARGIN_SUCCEEDED,
  SET_PRICE_MARGIN_FAILED,
} from './constants';

export const getUserCommissionsRequested = () => ({
  type: GET_COMMISSIONS_REQUESTED,
});

export const getUserCommissionsSucceeded = results => ({
  type: GET_COMMISSIONS_SUCCEEDED,
  results,
});

export const getUserCommissionsFailed = reason => ({
  type: GET_COMMISSIONS_FAILED,
  reason,
});

export const setUserCommissionRequested = commissionRate => ({
  type: SET_COMMISSION_RATE_REQUESTED,
  commissionRate,
});

export const setUserCommissionSucceeded = commissionRate => ({
  type: SET_COMMISSION_RATE_SUCCEEDED,
  commissionRate,
});

export const setUserCommissionFailed = reason => ({
  type: SET_COMMISSION_RATE_FAILED,
  reason,
});

// Set Price Margin action creators
export const setPriceMarginRequested = priceMargin => ({
  type: SET_PRICE_MARGIN_REQUESTED,
  priceMargin,
});

export const setPriceMarginSucceeded = priceMargin => ({
  type: SET_PRICE_MARGIN_SUCCEEDED,
  priceMargin,
});

export const setPriceMarginFailed = reason => ({
  type: SET_PRICE_MARGIN_FAILED,
  reason,
});
