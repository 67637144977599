import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  background-color: #f2f1f6;
  height: 100vh;
`;

const PageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e7e6f6;
  background-color: #ffffff;
`;

const HeaderImage = styled.img`
  display: flex;
`;

const Container = styled.div`
  min-width: 720px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 490px;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(71, 60, 164, 0.1);
  background-color: #ffffff;
  margin-top: 105px;
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 180px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #3A8DC3;
  padding-left: 36px;
  padding-right: 36px;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #ffffff;
  line-height: normal;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 36px;
`;

const BottomText = styled.div`
  width: 100%;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0.2px;
  color: #767676;
`;

const BrowsersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
`;

const BrowserText = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #767676;
`;

const Browser = styled.a`
  display: flex;
  flex-direction: column;
  flex-basis: 18%;
  min-height: 149px;
  border-radius: 4px;
  border: solid 1px #e7e5ef;
  background-color: #ffffff;
  &:hover {
    cursor: pointer;
    box-shadow: 0 2px 10px 0 rgba(71, 60, 164, 0.18);
    ${BrowserText} {
      color: #f8a119;
    }
  }
`;

const Figure = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e7e5ef;
`;

const Image = styled.img``;

const Links = styled.div``;

const Link = styled.a`
  color: #f8a119;
`;

export {
  Wrapper,
  Container,
  TopContainer,
  BottomContainer,
  BottomText,
  BrowsersContainer,
  Browser,
  Figure,
  BrowserText,
  Header,
  HeaderImage,
  PageContainer,
  Image,
  Links,
  Link,
};
