import { defineMessages } from 'react-intl';

export const scope = 'app.components.Header';

export default defineMessages({
  backToDashboard: {
    id: `${scope}.backToDashboard`,
    defaultMessage: 'Back to Dashboard',
  },
  booked: {
    id: `${scope}.booked`,
    defaultMessage: 'Booked',
  },
  cancelled: {
    id: `${scope}.cancelled`,
    defaultMessage: 'Cancelled',
  },
  waitingPayment: {
    id: `${scope}.waitingPayment`,
    defaultMessage: 'Waiting Payment',
  },
  getHelp: {
    id: `${scope}.getHelp`,
    defaultMessage: 'Get Help',
  },
  homepage: {
    id: `${scope}.homepage`,
    defaultMessage: 'Homepage',
  },
  myInbox: {
    id: `${scope}.myInbox`,
    defaultMessage: 'My Inbox',
  },
  overview: {
    id: `${scope}.overview`,
    defaultMessage: 'Overview',
  },
  bookings: {
    id: `${scope}.bookings`,
    defaultMessage: 'Bookings',
  },
  reports: {
    id: `${scope}.reports`,
    defaultMessage: 'Reports',
  },
  offlineRequest: {
    id: `${scope}.offlineRequest`,
    defaultMessage: 'Offline Request',
  },
  groupAccommodation: {
    id: `${scope}.groupAccommodation`,
    defaultMessage: 'Group Accommodation',
  },
  fitAccommodation: {
    id: `${scope}.fitAccommodation`,
    defaultMessage: 'FIT Accommodation',
  },
  transfer: {
    id: `${scope}.transfer`,
    defaultMessage: 'Transfer',
  },
});
