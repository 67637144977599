import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import classNames from 'classnames';
import { omit } from 'lodash';

import {
  compositionRating,
  TravellerRateBadge,
} from 'containers/OleryProvider';

import { copyText } from 'helpers/dom';

import Icon from 'components/Icon';
import Stars from 'components/Stars';
import TripAdvisorRating from 'components/TripAdvisorRating';
import Button from 'components/Button';

import * as Status from 'utils/status';

import messages from './messages';
import * as S from './style';

const HotelHeader = props => {
  const {
    hotelName,
    address,
    destination,
    country,
    phoneNumber,
    starCount,
    rating,
    reviewCount,
    hasCopyIcon,
    isModal,
    className,
    isPdf,
    oleryInfo,
    oleryStatus,
    hotelRate,
    hotelRateCount,
    compositionRatings,
    onChangeModalStatus,
  } = props;

  const classes = classNames('hotel-header', className);
  const hotelHeaderProps = omit(props, 'className', 'onChangeModalStatus');
  const addressIconColor = isModal ? '#FFFFFF' : '#626262';
  const showAddress = address && destination && country;
  const isShownRate = oleryStatus === Status.LOADED && oleryInfo;

  return (
    <S.HotelHeader {...hotelHeaderProps} className={classes}>
      <S.DetailsContainer>
        <S.TitleContainer>
          {hasCopyIcon ? (
            <S.TitleWithCopy>
              <S.Title>{hotelName}</S.Title>
              <Button transparent onClick={() => copyText(hotelName)}>
                <Icon name="copy" width={12} color="#f9a11b" />
                <S.CopyText>
                  <FormattedMessage {...messages.copy} />
                </S.CopyText>
              </Button>
            </S.TitleWithCopy>
          ) : (
            <S.Title>{hotelName}</S.Title>
          )}
          <Stars
            color="#ffa300"
            starCount={starCount}
            width={14}
            hideDisabledStars={isPdf}
          />
          {isShownRate && compositionRating(compositionRatings, 1)}
        </S.TitleContainer>
        {showAddress && (
          <S.DetailsLocation>
            <Icon name="pin" width="18" color={addressIconColor} />
            <S.AddressText
            >{`${address} ${destination}, ${country}`}</S.AddressText>
          </S.DetailsLocation>
        )}
        {phoneNumber && (
          <S.DetailsPhoneNumber>
            <Icon name="phone" width="16" color="#626262" />
            <S.PhoneText>{phoneNumber}</S.PhoneText>
          </S.DetailsPhoneNumber>
        )}
      </S.DetailsContainer>
      {isShownRate && (
        <S.RateButton
          transparent
          onClick={() => onChangeModalStatus('MODAL_DRAWER', true)}
        >
          <TravellerRateBadge
            className="hotel-header__rate-badge"
            rate={hotelRate}
            count={hotelRateCount}
          />
        </S.RateButton>
      )}
      {rating && (
        <S.Rating>
          <TripAdvisorRating rating={rating} hasBadge />
          <S.Review>
            <FormattedMessage
              {...messages.reviewText}
              values={{
                message: (
                  <FormattedPlural
                    value={reviewCount}
                    one={<FormattedMessage {...messages.review} />}
                    other={<FormattedMessage {...messages.reviews} />}
                  />
                ),
                count: reviewCount,
              }}
            />
          </S.Review>
        </S.Rating>
      )}
    </S.HotelHeader>
  );
};

HotelHeader.propTypes = {
  hotelName: PropTypes.string,
  address: PropTypes.string,
  starCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default HotelHeader;
