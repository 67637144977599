import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash';

import * as S from './style';

const ProgressBar = props => {
  const { header, headerValue, className } = props;
  const classes = classNames('progress-bar', className);
  const progressProps = omit(props, 'className');
  return (
    <S.Progress className={classes}>
      <S.ProgressHeader>
        <S.ProgressHeaderName>{header}</S.ProgressHeaderName>
        <S.ProgressValue>{headerValue}</S.ProgressValue>
      </S.ProgressHeader>
      <S.ProgressBar {...progressProps} />
    </S.Progress>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ProgressBar;
