import React from 'react';
import PropTypes from 'prop-types';

import Notification from 'containers/Notification';

import { checkShownSwitchDesignButton } from 'utils/switchOldDesign';

import { DetailsHeader } from '../Header';

import * as S from './style';

const DetailsLayout = props => {
  const { children, center, showDetails, isBlock, showNotification } = props;
  const layoutProps = { center };
  const headerProps = { showDetails, isBlock };
  return (
    <>
      {checkShownSwitchDesignButton() && <Notification />}
      <S.DetailsLayout showNotification={showNotification}>
        <DetailsHeader {...headerProps} />
        <S.Wrapper {...layoutProps}>
          <S.DetailsContent className="layout-content">
            {children}
          </S.DetailsContent>
        </S.Wrapper>
      </S.DetailsLayout>
    </>
  );
};

DetailsLayout.propTypes = {
  children: PropTypes.node,
  center: PropTypes.bool,
};

export default DetailsLayout;
