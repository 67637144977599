/* eslint-disable camelcase */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as JSPdf from 'jspdf';
import html2canvas from 'html2canvas';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Redirect } from 'react-router';

import { qs } from 'helpers/dom';
import { getFileName } from 'helpers/filename';
import { handleCategoryText } from 'helpers/analytics';
import { formatDate } from 'utils/dates';
import { storage } from 'utils/storage';

import { GOOGLE_API, GOOGLE_MAP_ENABLED } from 'config';

import {
  makeSelectSelectedPhotos,
  makeSelectMainImage,
  makeSelectSelectedRooms,
} from 'containers/CreateOffer/selectors';
import { makeSelectHotel } from 'containers/HotelDetail/selectors';
import { makeSelectAgencyInformation } from 'containers/MyAccount/selectors';
import { HOME } from 'containers/App/routes';

import HotelHeader from 'components/shared/HotelHeader';
import Icon from 'components/Icon';
import Button from 'components/Button';
import StaticMap from 'components/shared/LeafletMap/StaticMap';

import messages from './messages';
import './style.scss';

class OfferPdf extends Component {
  state = {
    selectedRooms: [],
  };

  componentDidMount() {
    const { selectedRooms } = this.props;

    if (selectedRooms.length > 0) {
      this.setState({
        selectedRooms,
      });
    }
  }

  getCanvas = element =>
    html2canvas(qs(element), {
      useCORS: true,
      logging: false,
    });

  downloadPdf = () => {
    const doc = new JSPdf('p', 'mm', 'a4');
    let content1 = '';
    let content2 = '';

    this.getCanvas('.offer-area-wrapper__main-content').then(canvas1 => {
      content1 = canvas1.toDataURL('image/png');
      this.getCanvas('.offer-area-wrapper__footer')
        .then(canvas2 => {
          content2 = canvas2.toDataURL('image/png');
          doc.addImage(content1, 'PNG', 10, 0, 190, 0);
          doc.addPage();
          doc.addImage(content2, 'PNG', 0, 10, 210, 0);
        })
        .then(() => {
          const randomStringLength = 5;
          const fileName = `${formatDate(moment(), 'DD-MM-YYYY')}-${getFileName(
            randomStringLength,
          )}.pdf`;
          doc.save(fileName);
          this.handleSendOfferEvent();
        });
    });
  };

  renderThumbnailPhotos = () => {
    const { selectedPhotos, mainImage } = this.props;

    return selectedPhotos.map(item => {
      if (item.id !== mainImage.id) {
        return (
          <figure
            key={item.id}
            className="offer-area-wrapper__images--thumbnail"
          >
            <img src={item.src} className="thumbnail" alt="thumbnail" />
          </figure>
        );
      }
      return null;
    });
  };

  getRoomTitle = rooms => {
    if (rooms) {
      return rooms[0].room_description.trim();
    }
    return rooms.reduceRight(
      (titles, room) =>
        `Room ${rooms.indexOf(room) +
          1} - ${room.room_description.trim()} ${titles}`,
      '',
    );
  };

  handleSendOfferEvent = () => {
    const { hotelInfo } = this.props;
    const { name, code } = hotelInfo;

    const commissionRate = storage.getItem('COMMISSION_RATE');
    const userId = storage.getParsedValue('USER_ID');
    const recentSearch = storage.getItem('recentSearch')
      ? storage.getParsedValue('recentSearch')[`${userId}`]
      : null;

    const {
      checkinDate,
      checkoutDate,
      location: { display_text },
    } = recentSearch;

    const searchDate = moment();
    const categoryText = handleCategoryText(display_text);
    const dateFormat = 'YYYY-MM-DD';

    const data = {
      id: code,
      name,
      brand: 'HProTravel',
      category: categoryText,
      CD_HotelID: code,
      dimension11: formatDate(searchDate, dateFormat), // Search Date
      dimension12: formatDate(checkinDate, dateFormat), // Checkin Date
      dimension13: formatDate(checkoutDate, dateFormat), // Checkout Date
      CD_Commission: commissionRate,
    };

    window.dataLayer.push({
      Category: 'Enhanced Ecommerce',
      Action: 'Offer',
      Label: 'Offer Detail',
      Value: 0,
      noninteraction: true,
      ecommerce: {
        offer: {
          products: data,
        },
      },
      event: 'eeEvent',
    });
  };

  render() {
    const {
      hotelInfo,
      mainImage,
      agencyInformation: { logo },
    } = this.props;

    if (Object.keys(hotelInfo).length === 0) {
      return <Redirect to={HOME} />;
    }

    const { selectedRooms } = this.state;
    const {
      address,
      name,
      star,
      partners_info,
      latitude,
      longitude,
    } = hotelInfo;
    const { average_rating, review_count } =
      partners_info !== undefined &&
      partners_info.length > 0 &&
      partners_info[0];
    const mapImage = `//maps.googleapis.com/maps/api/staticmap?center=${name}&zoom=17&size=595x194&key=${GOOGLE_API}&markers=color:red%7Clabel:C%7C${latitude},${longitude}`;
    const rooms = selectedRooms.map((item, idx) => (
      <tr key={`selectedRoom-${idx.toString()}`}>
        <td>{this.getRoomTitle(item.rooms)}</td>
        <td>{item.meal_type}</td>
        <td>
          <FormattedMessage
            {...messages[item.nonrefundable ? 'nonRefundable' : 'refundable']}
          />
        </td>
        <td>
          <strong>
            {item.displayed_price.currency} {item.displayed_price.amount}
          </strong>
        </td>
      </tr>
    ));

    return (
      <div className="offer-area-wrapper">
        <Button size="xs" className="download" onClick={this.downloadPdf}>
          <FormattedMessage {...messages.download} />
        </Button>
        <div className="offer-area-wrapper__main-content">
          <div className="offer-area-wrapper__header">
            <figure className="offer-area-wrapper__header__agency-logo">
              <img src={logo} alt="agency-logo" />
            </figure>
            <div className="offer-area-wrapper__header-hotel-name">
              <p>
                1 <FormattedMessage {...messages.hotel} /> /{' '}
                {selectedRooms.length}
              </p>
            </div>
          </div>
          <div className="offer-area-wrapper__images">
            <figure className="offer-area-wrapper__images--large">
              <img src={mainImage.src} alt="main" />
            </figure>
            <div className="offer-area-wrapper__images__thumbnail-container">
              {this.renderThumbnailPhotos()}
            </div>
          </div>
          <div className="offer-area-wrapper__hotel-header">
            <HotelHeader
              hotelName={name}
              starCount={star}
              address={address}
              rating={average_rating}
              reviewCount={review_count}
              isPdf
            />
          </div>
          <div className="offer-area-wrapper__room-list">
            <table className="offer-area-wrapper__room-list__table">
              <thead className="offer-area-wrapper__room-list__table__header">
                <tr>
                  <td>
                    <FormattedMessage {...messages.roomType} />
                  </td>
                  <td>
                    <FormattedMessage {...messages.boardType} />
                  </td>
                  <td>
                    <FormattedMessage {...messages.cancellation} />
                  </td>
                  <td>
                    <FormattedMessage {...messages.totalPrice} />
                  </td>
                </tr>
              </thead>
              <tbody className="offer-area-wrapper__room-list__table__content">
                {rooms}
              </tbody>
            </table>
          </div>
        </div>
        <div className="offer-area-wrapper__footer">
          <div className="offer-area-wrapper__price-description">
            <p>
              <FormattedMessage {...messages.priceDescription} />
            </p>
          </div>
          <div className="offer-area-wrapper__city-tax">
            <div className="offer-area-wrapper__city-tax__description__container">
              <p className="offer-area-wrapper__city-tax__description__container-title">
                <Icon name="info" color="#da8e17" width="14" />
                <FormattedMessage {...messages.cityTaxInformation} />
              </p>
              {/*
                <p className="offer-area-wrapper__city-tax__description__container-desc">
                  HProTravel <FormattedMessage {...messages.cityTaxDescription} />
                </p>
              */}
            </div>
          </div>
          <div className="offer-area-wrapper__map">
            {JSON.parse(GOOGLE_MAP_ENABLED) ? (
              <img src={mapImage} alt="static-map" />
            ) : (
              <StaticMap
                alt="static-map"
                longitude={longitude}
                latitude={latitude}
                width={300}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  hotelInfo: makeSelectHotel(),
  selectedPhotos: makeSelectSelectedPhotos(),
  selectedRooms: makeSelectSelectedRooms(),
  mainImage: makeSelectMainImage(),
  agencyInformation: makeSelectAgencyInformation(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(OfferPdf);
