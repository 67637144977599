import { createSelector } from 'reselect';
import { storage } from 'utils/storage';
import { initialState } from './reducer';

const selectMyAccount = state => state.get('myAccount', initialState);

const makeSelectLoginHistories = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['loginHistories', 'histories']).toJS(),
  );

const makeSelectLoginHistoriesStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['loginHistories', 'status']),
  );

const makeSelectLoginHistoriesReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['loginHistories', 'reason']),
  );

const makeSelectChangePasswordStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['changePassword', 'status']),
  );

const makeSelectChangePasswordReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['changePassword', 'reason']),
  );

const makeSelectUserInformationStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['userInformation', 'status']),
  );

const makeSelectUserInformationReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['userInformation', 'reason']),
  );

const makeSelectUserInformation = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['userInformation', 'userInfo']).toJS(),
  );

const makeSelectUserCampaigns = () =>
  createSelector(makeSelectUserInformation(), user => user.discount_totals);

const makeSelectFinancialInformationStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['financialInformation', 'status']),
  );

const makeSelectFinancialInformationReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['financialInformation', 'reason']),
  );

const makeSelectFinancialInformation = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['financialInformation', 'financialInfo']).toJS(),
  );

const makeSelectAgencyInformationDetailStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['agencyInformationDetail', 'status']),
  );

const makeSelectAgencyInformationDetailReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['agencyInformationDetail', 'reason']),
  );

const makeSelectAgencyInformationDetail = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['agencyInformationDetail', 'agencyInfo']).toJS(),
  );

const makeSelectUpdateUserPermsStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateUserPerms', 'status']),
  );

const makeSelectUpdateUserPermsReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateUserPerms', 'reason']),
  );

const makeSelectUpdateManagerInformationStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateManagerInformation', 'status']),
  );

const makeSelectUpdateManagerInformationReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateManagerInformation', 'reason']),
  );

const makeSelectUpdateUserInformationStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateUserInformation', 'status']),
  );

const makeSelectUpdateUserInformationReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateUserInformation', 'reason']),
  );

const makeSelectUpdateAgencyFinancialInformationStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateAgencyFinancialInformation', 'status']),
  );

const makeSelectUpdateAgencyFinancialInformationReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateAgencyFinancialInformation', 'reason']),
  );

const makeSelectUpdateAgencyInformationStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateAgencyInformation', 'status']),
  );

const makeSelectUpdateAgencyInformationReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateAgencyInformation', 'reason']),
  );

const makeSelectContactEmails = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['getContactEmails', 'emails']).toJS(),
  );

const makeSelectGetContactEmailsStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['getContactEmails', 'status']),
  );

const makeSelectGetContactEmailsReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['getContactEmails', 'reason']),
  );

const makeSelectPostContactEmailStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['postContactEmail', 'status']),
  );

const makeSelectPostContactEmailReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['postContactEmail', 'reason']),
  );

const makeSelectUpdateContactEmailStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateContactEmail', 'status']),
  );

const makeSelectUpdateContactEmailReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateContactEmail', 'reason']),
  );

const makeSelectDeleteContactEmailStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['deleteContactEmail', 'status']),
  );

const makeSelectDeleteContactEmailReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['deleteContactEmail', 'reason']),
  );

const makeSelectPostNewUserResults = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['postNewUser', 'results']),
  );

const makeSelectPostNewUserStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['postNewUser', 'status']),
  );

const makeSelectPostNewUserReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['postNewUser', 'reason']),
  );

const makeSelectUserID = () => createSelector(() => storage.getItem('USER_ID'));

const makeSelectUserEmail = () =>
  createSelector(() => storage.getItem('USER_EMAIL'));

const makeSelectAgencyInformation = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['agencyInformation', 'data']),
  );

const makeSelectAgencyInformationStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['agencyInformation', 'status']),
  );

const makeSelectAgencyInformationReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['agencyInformation', 'reason']),
  );

const makeSelectAgencyUsers = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['agencyUsers', 'users']),
  );

const makeSelectAgencyUsersStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['agencyUsers', 'status']),
  );

const makeSelectAgencyUsersReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['agencyUsers', 'reason']),
  );

const makeSelectSubagencies = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['subagencies', 'subagenciesData']),
  );

const makeSelectSubagenciesStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['subagencies', 'status']),
  );

const makeSelectSubagenciesReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['subagencies', 'reason']),
  );

const makeSelectSubagenciesTotalCount = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['subagencies', 'totalCount']),
  );

const makeSelectSubagenciesActivePage = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['subagencies', 'activePage']),
  );

const makeSelectFutureBalance = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['futureBalance', 'balance']).toJS(),
  );

const makeSelectFutureBalanceStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['futureBalance', 'status']),
  );

const makeSelectFutureBalanceReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['futureBalance', 'reason']),
  );

const makeSelectCountries = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['countries', 'results']).toJS(),
  );

const makeSelectCountriesStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['countries', 'status']),
  );

const makeSelectCountryStates = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['countryStates', 'results']).toJS(),
  );

const makeSelectCreateSubagencyStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['createSubagency', 'status']),
  );

const makeSelectCreateSubagencyReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['createSubagency', 'reason']),
  );

const makeSelectUpdatePaymentMethodsStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updatePaymentMethods', 'status']),
  );

const makeSelectUpdatePaymentMethodsReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updatePaymentMethods', 'reason']),
  );

const makeSelectGetPaymentMethodsStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['getPaymentMethods', 'status']),
  );

const makeSelectGetPaymentMethodsReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['getPaymentMethods', 'reason']),
  );

const makeSelectGetPaymentMethods = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['getPaymentMethods', 'paymentMethods']).toJS(),
  );

const makeSelectGetVirtualBalanceStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['getVirtualBalance', 'status']),
  );

const makeSelectGetVirtualBalanceReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['getVirtualBalance', 'reason']),
  );

const makeSelectGetVirtualBalance = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['getVirtualBalance', 'virtualBalance']),
  );

const makeSelectUpdateVirtualBalanceStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateVirtualBalance', 'status']),
  );

const makeSelectUpdateVirtualBalanceReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['updateVirtualBalance', 'reason']),
  );

const makeSelectResendActivationLinkStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['resendActivationLink', 'status']),
  );

const makeSelectResendActivationLinkReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['resendActivationLink', 'reason']),
  );

const makeSelectPostCardInformationsStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['postCardInformations', 'status']),
  );

const makeSelectPostCardInformationsReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['postCardInformations', 'reason']),
  );

const makeSelectSavedCardsStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['savedCards', 'status']),
  );

const makeSelectSavedCardsReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['savedCards', 'reason']),
  );

const makeSelectSavedCards = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['savedCards', 'cards']),
  );

const makeSelectDeleteSavedCardStatus = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['deleteSavedCard', 'status']),
  );

const makeSelectDeleteSavedCardReason = () =>
  createSelector(selectMyAccount, state =>
    state.getIn(['deleteSavedCard', 'reason']),
  );

export {
  makeSelectLoginHistories,
  makeSelectLoginHistoriesStatus,
  makeSelectLoginHistoriesReason,
  makeSelectChangePasswordStatus,
  makeSelectChangePasswordReason,
  makeSelectUserInformationStatus,
  makeSelectUserInformationReason,
  makeSelectUserInformation,
  makeSelectFinancialInformation,
  makeSelectFinancialInformationReason,
  makeSelectFinancialInformationStatus,
  makeSelectAgencyInformationDetail,
  makeSelectAgencyInformationDetailReason,
  makeSelectAgencyInformationDetailStatus,
  makeSelectUpdateUserPermsStatus,
  makeSelectUpdateUserPermsReason,
  makeSelectUpdateUserInformationStatus,
  makeSelectUpdateUserInformationReason,
  makeSelectUpdateAgencyFinancialInformationStatus,
  makeSelectUpdateAgencyFinancialInformationReason,
  makeSelectUpdateAgencyInformationStatus,
  makeSelectUpdateAgencyInformationReason,
  makeSelectContactEmails,
  makeSelectGetContactEmailsStatus,
  makeSelectGetContactEmailsReason,
  makeSelectPostContactEmailStatus,
  makeSelectPostContactEmailReason,
  makeSelectUpdateContactEmailStatus,
  makeSelectUpdateContactEmailReason,
  makeSelectDeleteContactEmailStatus,
  makeSelectDeleteContactEmailReason,
  makeSelectPostNewUserResults,
  makeSelectPostNewUserStatus,
  makeSelectPostNewUserReason,
  makeSelectUserID,
  makeSelectUserEmail,
  makeSelectAgencyInformation,
  makeSelectAgencyInformationStatus,
  makeSelectAgencyInformationReason,
  makeSelectAgencyUsers,
  makeSelectAgencyUsersStatus,
  makeSelectAgencyUsersReason,
  makeSelectSubagencies,
  makeSelectSubagenciesStatus,
  makeSelectSubagenciesReason,
  makeSelectUserCampaigns,
  makeSelectSubagenciesTotalCount,
  makeSelectSubagenciesActivePage,
  makeSelectFutureBalance,
  makeSelectFutureBalanceStatus,
  makeSelectFutureBalanceReason,
  makeSelectCountries,
  makeSelectCountriesStatus,
  makeSelectCountryStates,
  makeSelectUpdateManagerInformationStatus,
  makeSelectUpdateManagerInformationReason,
  makeSelectCreateSubagencyStatus,
  makeSelectCreateSubagencyReason,
  makeSelectUpdatePaymentMethodsStatus,
  makeSelectUpdatePaymentMethodsReason,
  makeSelectGetPaymentMethodsStatus,
  makeSelectGetPaymentMethodsReason,
  makeSelectGetPaymentMethods,
  makeSelectGetVirtualBalanceStatus,
  makeSelectGetVirtualBalanceReason,
  makeSelectGetVirtualBalance,
  makeSelectUpdateVirtualBalanceReason,
  makeSelectUpdateVirtualBalanceStatus,
  makeSelectResendActivationLinkStatus,
  makeSelectResendActivationLinkReason,
  makeSelectPostCardInformationsStatus,
  makeSelectPostCardInformationsReason,
  makeSelectSavedCardsStatus,
  makeSelectSavedCardsReason,
  makeSelectSavedCards,
  makeSelectDeleteSavedCardStatus,
  makeSelectDeleteSavedCardReason,
};
