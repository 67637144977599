import { defineMessages } from 'react-intl';

export const scope = 'app.containers.OleryProvider';

export default defineMessages({
  pageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'Profit',
  },
  reviewCount: {
    id: `${scope}.reviewCount`,
    defaultMessage: `Based on {reviewCount} Reviews`,
  },
  summaryOfReviews: {
    id: `${scope}.summaryOfReviews`,
    defaultMessage: `Summary Of Reviews`,
  },
  oleryInfo: {
    id: `${scope}.oleryInfo`,
    defaultMessage:
      'All reviews and ratings on this site are provided by Olery.',
  },
});
