import React from 'react';
import PropTypes from 'prop-types';

import { LoginHeader } from '../Header';

const LandingLayout = props => {
  const { children } = props;
  return (
    <>
      <LoginHeader />
      <div className="layout-content">{children}</div>
    </>
  );
};

LandingLayout.propTypes = {
  children: PropTypes.node,
};

export default LandingLayout;
