import { apiConfig } from 'config';
import { req } from 'utils/request';
import { storage } from 'utils/storage';

const { account } = apiConfig;

export function getUserCurrenciesApi() {
  const userID = storage.getItem('USER_ID');
  return req().get(account.userCurrencies(userID));
}

export function setUserCurrencyCodeApi(currencyCode) {
  const userID = storage.getItem('USER_ID');
  return req().put(account.setCurrency(userID), currencyCode);
}
