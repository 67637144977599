import styled from 'styled-components';

import { Icon } from '../Icon/style';

const Stars = styled.div`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  line-height: 15px;
  ${Icon}:not(:last-child) {
    margin-right: 2px;
  }
  && ${Icon} {
    margin-left: 0;
  }
`;

export { Stars };
