import { defineMessages } from 'react-intl';

export const scope = 'app.components.CurrencySelect';

export default defineMessages({
  selectCurrencyHeader: {
    id: `${scope}.selectCurrencyHeader`,
    defaultMessage: 'Select your currency',
  },
});
