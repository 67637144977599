export const CHANGE_LOCALE = 'app/LanguageToggle/CHANGE_LOCALE';
export const GET_LANGUAGES_REQUESTED = 'GET_LANGUAGES_REQUESTED';
export const GET_LANGUAGES_SUCCEEDED = 'GET_LANGUAGES_SUCCEEDED';
export const GET_LANGUAGES_FAILED = 'GET_LANGUAGES_FAILED';

export const SET_USER_LANGUAGE_REQUESTED = 'SET_USER_LANGUAGE_REQUESTED';
export const SET_USER_LANGUAGE_SUCCEEDED = 'SET_USER_LANGUAGE_SUCCEEDED';
export const SET_USER_LANGUAGE_FAILED = 'SET_USER_LANGUAGE_FAILED';

export const GET_CORE_LANGUAGES_REQUESTED = 'GET_CORE_LANGUAGES_REQUESTED';
export const GET_CORE_LANGUAGES_SUCCEEDED = 'GET_CORE_LANGUAGES_SUCCEEDED';
export const GET_CORE_LANGUAGES_FAILED = 'GET_CORE_LANGUAGES_FAILED';
