import { defineMessages } from 'react-intl';

export const scope = 'app.components.PriceMarginSelect';

export default defineMessages({
  priceMarginHeader: {
    id: `${scope}.priceMarginHeader`,
    defaultMessage: 'Select your price margin',
  },
  priceMargin: {
    id: `${scope}.priceMargin`,
    defaultMessage: 'Price Margin',
  },
});
