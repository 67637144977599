/* eslint-disable no-console */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import FontFaceObserver from 'fontfaceobserver';
import * as Sentry from '@sentry/browser';
// import TagManager from 'react-gtm-module';

import { SENTRY_DSN } from 'config';
import history from 'utils/history';
import { storage } from 'utils/storage';

import 'sanitize.css/sanitize.css';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';
import ErrorBoundary from './ErrorBoundary';

if (process.env.NODE_ENV === 'production') {
  // Sentry configuration
  Sentry.init({ dsn: SENTRY_DSN });
  if (storage.getItem('USER_TOKEN')) {
    Sentry.configureScope(scope => {
      scope.setUser({
        email: storage.getItem('USER_EMAIL'),
        username: storage.getItem('AGENCY_CODE'),
      });
      scope.setTag('page_locale', navigator.language || 'undef');
      scope.setExtra('userFullName', storage.getItem('USER_FULL_NAME'));
      scope.setExtra('userCurrency', storage.getItem('USER_CURRENCY'));
    });
  }

  // Google Tag Manager Configurations
  // const tagManagerArgs = {
  //   gtmId: 'GTM-MC36NV3',
  // };

  // TagManager.initialize(tagManagerArgs);
}

// Data Layer
window.dataLayer = [];

const robotoObserver = new FontFaceObserver('Roboto', {});

// When Roboto is loaded, add a font-family using Roboto to the body
robotoObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line global-require
  const runtime = require('offline-plugin/runtime');

  runtime.install({
    onUpdating: () => {
      console.log('SW Event:', 'onUpdating');
    },
    onUpdateReady: () => {
      console.log('SW Event:', 'onUpdateReady');
      // Tells to new SW to take control immediately
      runtime.applyUpdate();
    },
    onUpdated: () => {
      console.log('SW Event:', 'onUpdated');
      // Reload the webpage to load into the new version
      window.location.reload();
    },
    onUpdateFailed: () => {
      console.log('SW Event:', 'onUpdateFailed');
    },
  });
}

// Clearing cache storage
if ('caches' in window) {
  caches
    .keys()
    .then(cacheNames =>
      Promise.all(cacheNames.map(cacheName => caches.delete(cacheName))),
    );
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then(registrations => {
      // eslint-disable-next-line no-restricted-syntax
      for (const registration of registrations) {
        registration.unregister();
      }
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error('Service Worker registration failed: ', err);
    });
}
