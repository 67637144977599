import {
  GET_REPORTS_REQUESTED,
  GET_REPORTS_SUCCEEDED,
  GET_REPORTS_FAILED,
} from './constants';

// Get Reports action creators
export const getReportsRequested = userId => ({
  type: GET_REPORTS_REQUESTED,
  userId,
});

export const getReportsSucceeded = results => ({
  type: GET_REPORTS_SUCCEEDED,
  results,
});

export const getReportsFailed = reason => ({
  type: GET_REPORTS_FAILED,
  reason,
});
