import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash';
import posed, { PoseGroup } from 'react-pose';

import Loader from 'components/Loader';

import * as S from './style';

const LoaderWrapper = posed.div({
  enter: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -10 },
});

const Button = props => {
  const { children, className, to, isLoading } = props;
  const classes = classNames('button', className);
  const linkClasses = classNames('link', className);
  const buttonProps = omit(props, 'className', 'active');
  const linkProps = omit(props, 'className', 'type', 'active', 'isLoading');
  return (
    <>
      {to ? (
        <S.StyledLink className={linkClasses} {...linkProps}>
          {children}
        </S.StyledLink>
      ) : (
        <S.Button className={classes} {...buttonProps}>
          {children}
          <PoseGroup>
            {isLoading && (
              <LoaderWrapper key="loader">
                <Loader size="12px" background="transparent" />
              </LoaderWrapper>
            )}
          </PoseGroup>
        </S.Button>
      )}
    </>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  to: PropTypes.string,
  transparent: PropTypes.bool,
  secondary: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  to: '',
  transparent: false,
  isLoading: false,
  secondary: false,
};

export default Button;
