import styled from 'styled-components';
import ProgressBar from 'components/ProgressBar';
import {
  ProgressBar as StyledProgressBar,
  ProgressHeaderName,
} from 'components/ProgressBar/style';

const OleryRate = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
`;

const RateBar = styled(ProgressBar)`
  ${StyledProgressBar} {
    height: 6px;
    border-radius: 4px;
    &::-webkit-progress-value {
      border-radius: 4px;
    }
  }
  ${ProgressHeaderName} {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.18px;
    color: #626262;
  }
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #303030;
  padding-left: 8px;
  margin-bottom: -5px;
`;

export { OleryRate, RateBar, Text };
