import { fromJS } from 'immutable';

import * as Status from 'utils/status';

import {
  GET_REPORTS_REQUESTED,
  GET_REPORTS_SUCCEEDED,
  GET_REPORTS_FAILED,
} from './constants';

export const initialState = fromJS({
  reports: {
    results: [],
    status: Status.INIT,
    reason: null,
  },
});

function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORTS_REQUESTED:
      return state.setIn(['reports', 'status'], Status.LOADING);
    case GET_REPORTS_SUCCEEDED:
      return state
        .setIn(['reports', 'status'], Status.LOADED)
        .setIn(['reports', 'results'], fromJS(action.results.objects));
    case GET_REPORTS_FAILED:
      return state
        .setIn(['reports', 'status'], Status.FAILED)
        .setIn(['reports', 'reason'], action.reason);
    default:
      return state;
  }
}

export default reportsReducer;
