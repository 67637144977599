import { LOGIN, SIGNUP, LANDING_HELP } from 'containers/App/routes';

import ContactIcon from 'images/contact.svg';
// import LanguageIcon from 'images/language.svg';

export const navigationLinks = [
  {
    name: 'Contact',
    url: LANDING_HELP,
    icon: ContactIcon,
  },
  /* {
    type: 'lang',
    name: 'En',
    url: '',
    icon: LanguageIcon,
  } */ {
    name: 'Login',
    url: LOGIN,
    icon: null,
  },
  {
    name: 'Sign Up',
    url: SIGNUP,
    icon: null,
  },
];
