import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash';

import * as S from './style';

const Badge = props => {
  const { children, className } = props;
  const classes = classNames('badge', className);
  const badgeProps = omit(props, 'className');
  return (
    <S.Badge className={classes} {...badgeProps}>
      {children}
    </S.Badge>
  );
};

Badge.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
};

export default Badge;
