import { createSelector } from 'reselect';
import { initialState } from './reducer';

const reports = state => state.get('reports', initialState);

const makeSelectReports = () =>
  createSelector(reports, state => state.getIn(['reports', 'results']).toJS());

const makeSelectReportsStatus = () =>
  createSelector(reports, state => state.getIn(['reports', 'status']));

const makeSelectReportsReason = () =>
  createSelector(reports, state => state.getIn(['reports', 'reason']));

export { makeSelectReports, makeSelectReportsStatus, makeSelectReportsReason };
