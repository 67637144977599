import {
  omit,
  pick,
  min,
  property,
  sortBy,
  truncate,
  range,
  times,
  mapKeys,
} from 'lodash';
import { parse, stringify } from 'query-string';
import moment from 'moment';

import { isChinaDomain } from 'helpers/dom';

import NoImage from 'images/no-photo.png';

export const startSearchParams = [
  'checkin',
  'checkout',
  'currency',
  'client_nationality',
  'pax',
  'destination_code',
  'hotel_code',
  'place_id',
];

export const createStartSearchParams = search =>
  stringify(pick(parse(search), startSearchParams));

export const createResultsParams = search =>
  stringify(omit(parse(search), startSearchParams));

export const getFilterParams = search =>
  omit(parse(search), startSearchParams, 'order_by', 'search_key', 'offset');

export const averageRating = partnersInfo => partnersInfo[0].average_rating;

export const minProduct = products =>
  min(products, property('displayed_price.amount'));

export const formatDistance = distance => parseFloat(distance).toFixed(2);

export const formatAmount = amount => Math.round(amount);

export function cancellationType(nonrefundable) {
  if (nonrefundable === null) return null;

  if (nonrefundable === true) {
    return 'Non-Refundable';
  }
  if (nonrefundable === false) {
    return 'Free Cancellation';
  }

  return null;
}

export const roomDescription = rooms => truncate(rooms[0].room_description, 20);

export const roomTitle = (rooms, roomText) => {
  // roomText is mandatory. roomText is must be translatable.
  if (rooms && rooms.length === 1) {
    return rooms[0].room_description.trim();
  }
  if (rooms && rooms.length > 1) {
    return rooms.reduceRight(
      (titles, room) =>
        `${roomText} ${rooms.indexOf(room) +
          1} - ${room.room_description.trim()} <br/> ${titles}`,
      '',
    );
  }

  return '';
};

export const nightCountFromUrl = params =>
  moment(moment(parse(params).checkout)).diff(
    moment(parse(params).checkin),
    'days',
  );

export const calculateNightlyPrice = (amount, day) =>
  formatAmount(amount / day);

export const hotelImageSrc = (image, width, height) => {
  let photoSrc = image;
  if (isChinaDomain && image && image !== '') {
    photoSrc = image.replace('assets', 'assets-proxy');
  }

  return photoSrc ? `${photoSrc}?width=${width}&height=${height}` : NoImage;
};

export function tripAdvisorRatings(ratings) {
  return sortBy(ratings, 'name').reverse();
}

export function hotelStars() {
  return times(6)
    .filter(item => item > 0)
    .map(star => ({
      code: star.toString(),
      value: star.toString(),
    }))
    .reverse();
}

export function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export function cancellationPolicyText(checkin, policies) {
  if (!policies || policies.length === 0) {
    return 'This product is non-refundable at the moment';
  }

  const sortedPolicies = sortBy(policies, 'days_remaining').reverse();
  const firstSentence = daysRemaining =>
    `No penalty for cancellations up to ${daysRemaining} 12:00 GMT+3`;
  const secondSentence = (daysRemaining, ratio) =>
    `Cancellations made after ${daysRemaining} 12:00 GMT+3 will be assessed ${ratio} %.`;
  const deadlineDate = policy =>
    moment(checkin)
      .subtract(policy.days_remaining, 'days')
      .format('MMM D, YYYY');
  const policyRatio = ratio => (parseFloat(ratio) * 100).toFixed(2);

  return sortedPolicies.map(
    (policy, idx) =>
      idx === 0
        ? `${firstSentence(deadlineDate(policy))}.
         ${secondSentence(deadlineDate(policy), policyRatio(policy.ratio))}`
        : `${secondSentence(deadlineDate(policy), policyRatio(policy.ratio))}`,
  );
}

export const includeEndRange = (start, end, step = 1) =>
  range(start, end + step, step);

export const penaltyAmount = (amount, ratio) =>
  parseFloat(amount * Number(ratio)).toFixed(2);

export const hasData = list => list && list.length > 0;

export function getErrorMessage(reason) {
  let errorMessage = '';

  if (typeof reason === 'object') {
    if (reason.errors) {
      const { errors } = reason;
      mapKeys(errors, (value, key) => {
        errorMessage = Array.isArray(errors[key])
          ? errors[key][0]
          : errors[key];
      });
    } else {
      errorMessage = reason.message;
    }
  }

  return errorMessage;
}
