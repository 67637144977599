import { defineMessages } from 'react-intl';

export const scope = 'app.components.LandingFooter';

export default defineMessages({
  ourOffices: {
    id: `${scope}.ourOffices`,
    defaultMessage: 'Our Offices',
  },
  ourProducts: {
    id: `${scope}.ourProducts`,
    defaultMessage: 'Our Products',
  },
  company: {
    id: `${scope}.company`,
    defaultMessage: 'Company',
  },
  blog: {
    id: `${scope}.blog`,
    defaultMessage: 'Blog',
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: 'Help',
  },
  termsAndConditions: {
    id: `${scope}.termsAndConditions`,
    defaultMessage: 'Terms & Conditions',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy Policy',
  },
  tradeFairs: {
    id: `${scope}.tradeFairs`,
    defaultMessage: 'Trade Fairs',
  },
  faq: {
    id: `${scope}.faq`,
    defaultMessage: 'FAQ',
  },
  allRightsReserved: {
    id: `${scope}.allRightsReserved`,
    defaultMessage: '© 2021 HProTravel. All rights reserved',
  },
  aboutUs: {
    id: `${scope}.aboutUs`,
    defaultMessage: 'About Us',
  },
  career: {
    id: `${scope}.career`,
    defaultMessage: 'Career',
  },
  newsletterDesc: {
    id: `${scope}.newsletterDesc`,
    defaultMessage:
      'Join our mailing list we get updates.We respect your privacy',
  },
});
