import { storage } from 'utils/storage';

export const sendPageViewEvent = (path, title) => {
  window.dataLayer.push({
    event: 'virtualPageview',
    pagePath: path || '/',
    pageTitle: title,
  });
};

export const sendPageSpecificCustomDimensions = dimensions => {
  window.dataLayer.push(dimensions);
};

export const sendCustomDimensions = pageType => {
  const defaultFields = {
    CD_Version: 'app_xpro',
    CD_PageType: pageType,
    CD_customer_email: storage.getItem('USER_EMAIL'),
    CD_sessionId: storage.getItem('USER_TOKEN'),
    userCode: storage.getItem('AGENCY_USER_NAME'),
    agencyId: storage.getItem('AGENCY_CODE'),
    CD_profit2: storage.getParsedValue('LOYALTY_MUST_BE_ACTIVATED') ? 0 : 1,
    CD_profit: storage.getParsedValue('IS_LOYALTY_ACTIVE') ? 1 : 0,
    CD_profitBonus: storage.getItem('LOYALTY_BONUS_AMOUNT'),
    CD_Remainingdays: storage.getItem('LOYALTY_REMAINING_DAY'),
    agencyName: storage.getItem('AGENCY_NAME'),
    CD_region: storage.getItem('AGENCY_REGION'),
    CD_country: storage.getItem('AGENCY_COUNTRY_NAME'),
    // Missing properties that should be returned by backend.
    // CD_LastLoginDate
    // CD_LoginDate
    // CD_Frequency
    // CD_FirstSignupSource
  };

  window.dataLayer.push(defaultFields);
};

export const handleCategoryText = categoryText =>
  categoryText
    .split(',')
    .reverse()
    .join(' / ')
    .trim();

export const trackAllClickEvents = e => {
  const { target } = e;
  const className = target.classList[0] || '';

  const data = {
    'gtm.element': target,
    'gtm.elementClasses': className,
    'gtm.elementId': target.id,
    'gtm.elementUrl': target.href,
    event: target.tagName === 'A' ? 'gtm.linkClick' : 'gtm.click',
  };

  window.dataLayer.push(data);
};
