import { createSelector } from 'reselect';
import { map, groupBy, slice, take } from 'lodash';
import { initialState } from './reducer';

const defaultOfferImagesCount = 5;

const selectHotelDetail = state => state.get('hotelDetail', initialState);

const makeSelectHotel = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['hotelDetail', 'hotelInfo']).toJS(),
  );

const makeSelectHotelInfoStatus = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['hotelDetail', 'status']),
  );

const makeSelectHotelInfoReason = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['hotelDetail', 'reason']),
  );

const makeSelectHotelCode = () =>
  createSelector(
    makeSelectHotel(),
    hotel => (hotel && hotel.code ? hotel.code : null),
  );

const makeSelectAllHotelFacilities = () =>
  createSelector(makeSelectHotel(), hotel => hotel.facilities);

const makeSelectPopularHotelFacilities = () =>
  createSelector(makeSelectAllHotelFacilities(), facilities =>
    slice(facilities, 0, 4),
  );

const makeSelectHotelImages = () =>
  createSelector(makeSelectHotel(), hotel => map(hotel.images, 'url'));

const makeSelectOfferImages = () =>
  createSelector(
    makeSelectHotelImages(),
    images =>
      images &&
      take(images, defaultOfferImagesCount).map((image, idx) => ({
        id: idx,
        src: image,
      })),
  );

const makeSelectRoomAvailabilityStatus = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['roomAvailability', 'status']),
  );

const makeSelectRoomAvailabilityReason = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['roomAvailability', 'reason']),
  );

const makeSelectHotelProducts = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['roomAvailability', 'products']),
  );

const makeSelectAvailableFilters = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['roomAvailability', 'availableFilters']),
  );

const makeSelectMinProductCode = () =>
  createSelector(
    makeSelectHotelProducts(),
    products => products && products.length > 0 && products[0].code,
  );

const makeSelectHotelAddedCollections = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['roomAvailability', 'collections']),
  );

const makeSelectAppliedProvision = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['roomAvailability', 'applied_provision']),
  );

const makeSelectFilteredHotelProducts = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['roomAvailability', 'filteredProducts']).toJS(),
  );

const makeSelectAppliedFilters = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['roomAvailability', 'appliedFilters']).toJS(),
  );

const makeSelectHotelRoomCategories = () =>
  createSelector(makeSelectFilteredHotelProducts(), products =>
    groupBy(
      products,
      product =>
        product &&
        product.rooms &&
        product.rooms.length > 0 &&
        product.rooms[0].room_category,
    ),
  );

const makeSelectCheapestProductPrice = () =>
  createSelector(
    makeSelectFilteredHotelProducts(),
    products =>
      products &&
      products.length > 0 &&
      products[0] &&
      products[0].displayed_price,
  );

const makeSelectHotelName = () =>
  createSelector(makeSelectHotel(), hotel => hotel.name);

const makeSelectDestination = () =>
  createSelector(makeSelectHotel(), hotel => hotel.destination.name);

const makeSelectAddBasketStatus = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['addBasket', 'status']),
  );

const makeSelectAddBasketReason = () =>
  createSelector(selectHotelDetail, state =>
    state.getIn(['addBasket', 'reason']),
  );

export {
  makeSelectHotel,
  makeSelectHotelCode,
  makeSelectHotelInfoStatus,
  makeSelectHotelInfoReason,
  makeSelectAllHotelFacilities,
  makeSelectPopularHotelFacilities,
  makeSelectHotelImages,
  makeSelectRoomAvailabilityStatus,
  makeSelectRoomAvailabilityReason,
  makeSelectHotelProducts,
  makeSelectFilteredHotelProducts,
  makeSelectAppliedProvision,
  makeSelectAvailableFilters,
  makeSelectAppliedFilters,
  makeSelectHotelRoomCategories,
  makeSelectHotelAddedCollections,
  makeSelectOfferImages,
  makeSelectMinProductCode,
  makeSelectCheapestProductPrice,
  makeSelectHotelName,
  makeSelectDestination,
  makeSelectAddBasketStatus,
  makeSelectAddBasketReason,
};
