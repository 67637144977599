export const GET_BOOKING_INFO_REQUESTED = 'GET_BOOKING_INFO_REQUESTED';
export const GET_BOOKING_INFO_SUCCEEDED = 'GET_BOOKING_INFO_SUCCEEDED';
export const GET_BOOKING_INFO_FAILED = 'GET_BOOKING_INFO_FAILED';

export const GET_BOOKING_NOTES_REQUESTED = 'GET_BOOKING_NOTES_REQUESTED';
export const GET_BOOKING_NOTES_SUCCEEDED = 'GET_BOOKING_NOTES_SUCCEEDED';
export const GET_BOOKING_NOTES_FAILED = 'GET_BOOKING_NOTES_FAILED';

export const ADD_BOOKING_NOTE_REQUESTED = 'ADD_BOOKING_NOTE_REQUESTED';
export const ADD_BOOKING_NOTE_SUCCEEDED = 'ADD_BOOKING_NOTE_SUCCEEDED';
export const ADD_BOOKING_NOTE_FAILED = 'ADD_BOOKING_NOTE_FAILED';

export const CANCEL_BOOKING_REQUESTED = 'CANCEL_BOOKING_REQUESTED';
export const CANCEL_BOOKING_SUCCEEDED = 'CANCEL_BOOKING_SUCCEEDED';
export const CANCEL_BOOKING_FAILED = 'CANCEL_BOOKING_FAILED';

export const GET_BOOKING_SEARCH_REQUESTED = 'GET_BOOKING_SEARCH_REQUESTED';
export const GET_BOOKING_SEARCH_SUCCEEDED = 'GET_BOOKING_SEARCH_SUCCEEDED';
export const GET_BOOKING_SEARCH_FAILED = 'GET_BOOKING_SEARCH_FAILED';
