import styled from 'styled-components';

const progressBar = `
  border-radius: 2px;
  overflow: hidden;
  background-color: #e7e5ef;
`;

const progressValue = `
  border-radius: 2px 0 0 2px;
  background-color: #00c8ce;
`;

const ProgressBar = styled.progress`
  appearance: none;
  width: 100%;
  height: 4px;
  ${progressBar};
  ${progressValue};
  &::-webkit-progress-bar {
    ${progressBar};
  }
  &::-moz-progress-bar {
    ${progressBar};
  }
  &::-webkit-progress-value {
    ${progressValue};
  }
`;

const Progress = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const ProgressHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const ProgressValue = styled.span`
  color: #303030;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2px;
`;

const ProgressHeaderName = styled.span`
  color: #949494;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  padding-right: 16px;
`;

export {
  ProgressBar,
  Progress,
  ProgressHeader,
  ProgressValue,
  ProgressHeaderName,
};
