/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';

import { storage } from 'utils/storage';
import { checkShownSwitchDesignButton } from 'utils/switchOldDesign';

import { LOGIN, HOME, LANDING } from 'containers/App/routes';

import { isAvailableBrowser } from 'utils/checkBrowserVersion';
import CheckBrowser from '../components/CheckBrowser';

export const AppRoute = ({
  component: Component,
  layout: Layout,
  isPrivateRoute,
  layoutStyle,
  headerStyle,
  isLanding,
  ...rest
}) => {
  const isAuth = !!storage.getItem('USER_TOKEN');
  if (isLanding) {
    if (isAuth) {
      return <Redirect to={HOME} />;
    }
    return (
      <Route
        {...rest}
        render={props => {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }}
      />
    );
  } else {
    const renderLayout = props => (
      <Layout
        {...layoutStyle}
        {...headerStyle}
        showNotification={checkShownSwitchDesignButton()}
      >
        <Component {...props} />
      </Layout>
    );
    const renderRedirect = (props, path) => (
      <Redirect
        to={{
          pathname: path,
          state: { from: props.location, next: props.location.pathname },
        }}
      />
    );
    const Redirection = props =>
      isAuth ? renderLayout(props) : renderRedirect(props, LANDING);
    return (
      <>
        {isAvailableBrowser() ? (
          <CheckBrowser />
        ) : (
          <Route
            {...rest}
            render={props =>
              isPrivateRoute ? renderLayout(props) : Redirection(props)
            }
          />
        )}
      </>
    );
  }
};

AppRoute.propTypes = {
  location: PropTypes.object,
};

export const RedirectPage = props => {
  const { children, redirectPath = HOME, isRedirect = false } = props;
  if (isRedirect) {
    return <Redirect to={redirectPath} />;
  }
  return children;
};
