import { apiConfig } from 'config';
import { req } from 'utils/request';
import { storage } from 'utils/storage';

const { account } = apiConfig;

export function getUserCommissionsApi() {
  const userID = storage.getItem('USER_ID');
  return req().get(account.getCommissionRates(userID));
}

export function setUserCommissionApi(commissionRate) {
  const userID = storage.getItem('USER_ID');
  return req().put(account.setCommission(userID), commissionRate);
}

export function setUserPriceMarginApi(priceMargin) {
  const userID = storage.getItem('USER_ID');
  return req().put(account.setPriceMargin(userID), priceMargin);
}
