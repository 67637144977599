import React, { Component, createRef } from 'react';

import { $on, $off, qs } from 'helpers/dom';

const withBlurred = WrappedComponent =>
  class extends Component {
    constructor(props) {
      super(props);
      this.wrapperRef = createRef();
      this.state = {
        isShownList: false,
      };
    }

    componentDidMount() {
      $on(document, 'mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
      $off(document, 'mousedown', this.handleClickOutside);
    }

    handleClickOutside = event => {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        qs('.layout-content').classList.remove('content--blurred');
        this.setState({
          isShownList: false,
        });
      }
    };

    handleToggleMenu = () => {
      this.setState(
        prevState => ({
          isShownList: !prevState.isShownList,
        }),
        () => {
          if (this.state.isShownList) {
            qs('.layout-content').classList.add('content--blurred');
          } else {
            qs('.layout-content').classList.remove('content--blurred');
          }
        },
      );
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          forwardedRef={this.wrapperRef}
          isShownList={this.state.isShownList}
          handleToggleMenu={this.handleToggleMenu}
        />
      );
    }
  };

export default withBlurred;
