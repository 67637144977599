import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectCreateOffer = state => state.get('createOffer', initialState);

const makeSelectSelectedPhotos = () =>
  createSelector(selectCreateOffer, state =>
    state.get('selectedPhotos').toJS(),
  );

const makeSelectSelectedRooms = () =>
  createSelector(selectCreateOffer, state => state.get('selectedRooms').toJS());

const makeSelectMainImage = () =>
  createSelector(selectCreateOffer, state => state.get('mainImage').toJS());

export {
  makeSelectSelectedPhotos,
  makeSelectSelectedRooms,
  makeSelectMainImage,
};
