import Bowser from 'bowser';
/* eslint-disable */
export const isAvailableBrowser = () => {
  const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
  const databrowser = [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome', min: 11 },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox', min: 17 },
    { name: 'Safari', value: 'Safari', version: 'Version', min: 6 },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE', min: 10 },
    { name: 'Opera', value: 'Opera', version: 'Opera', min: 11 },
  ];

  return databrowser.some(
    item => item.name === browser.name && item.min > parseInt(browser.version),
  );
};
