import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

import { makeSelectBalances } from 'containers/AccountProvider/selectors';

import withBlurred from 'components/shared/withBlurred';

import * as S from './style';
import messages from './messages';

const DepositBalance = props => {
  const { isShownList, forwardedRef, balances, handleToggleMenu } = props;
  const balanceList = [];

  /* eslint-disable array-callback-return */
  balances.map(item => {
    balanceList.push({
      code: item.currency_code,
      name: item.amount,
    });
  });
  return (
    <S.DepositBalance ref={forwardedRef}>
      <S.DepositBalanceMenu
        selected={<FormattedMessage {...messages.deposit} />}
        onClick={handleToggleMenu}
        isOpened={isShownList}
      >
        {isShownList && <S.DepositBalanceList options={balanceList} />}
      </S.DepositBalanceMenu>
    </S.DepositBalance>
  );
};

const mapStateToProps = createStructuredSelector({
  balances: makeSelectBalances(),
});

export default withBlurred(
  connect(
    mapStateToProps,
    null,
  )(DepositBalance),
);
