import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash';

import Icon from '../Icon';
import Loader from '../Loader';

import * as S from './style';

const List = props => {
  const {
    header,
    options,
    isJustName,
    selected,
    className,
    onClickedOption,
    isLoaded,
    showCodes,
  } = props;
  const classes = classNames('list', className);
  const listProps = omit(props, 'className', 'onClickedOption');
  const selectedOption = selected;
  const SelectedIcon = <Icon name="check" width="12" />;

  const Options = options.map(item => {
    let isSelected;
    /* eslint-disable eqeqeq */
    if (isJustName) {
      isSelected = selectedOption == item.name;
    } else {
      isSelected = selectedOption == item.code;
    }
    /* eslint-enable eqeqeq */
    return (
      <S.ListItem
        key={item.code ? item.code : item.name}
        className={isSelected ? 'selected-item' : ''}
        active={isSelected}
        onClick={() => onClickedOption(item)}
      >
        <S.ListItemWrapper>
          {showCodes && (
            <>{item.code && <S.ItemCode>{item.code}</S.ItemCode>}</>
          )}
          {item.name}
        </S.ListItemWrapper>
        {isSelected && SelectedIcon}
      </S.ListItem>
    );
  });
  return (
    <S.Wrapper className={classes} {...listProps}>
      {isLoaded ? (
        <>
          {header && <S.Header>{header}</S.Header>}
          <S.List>{Options}</S.List>
        </>
      ) : (
        <Loader size="48px" background="transparent" />
      )}
    </S.Wrapper>
  );
};

List.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired,
  ]),
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  isJustName: PropTypes.bool,
  selected: PropTypes.string,
  isLoaded: PropTypes.bool,
  showCodes: PropTypes.bool,
  onClickedOption: PropTypes.func,
};

List.defaultProps = {
  isLoaded: true,
  showCodes: true,
  onClickedOption: () => {},
};

export default List;
