import React from 'react';
import { FormattedMessage } from 'react-intl';

import SignOut from 'images/exit-sign.svg';

import * as Status from 'utils/status';

import Modal from 'components/Modal';
import Button from 'components/Button';

import messages from './messages';
import * as S from './style';

const SignOutModal = ({ logout, onChangeModalStatus, status }) => (
  <Modal type="SIGN_OUT_MODAL" width="540px" height="234px">
    <S.SignoutModalWrapper>
      <S.ModalIconArea>
        <S.ModalLogoutImage src={SignOut} />
      </S.ModalIconArea>
      <S.ModalContent>
        <S.ModalTitle>
          <FormattedMessage {...messages.title} />
        </S.ModalTitle>
        <S.ModalDescription>
          <FormattedMessage {...messages.description} />
        </S.ModalDescription>
      </S.ModalContent>
      <S.ModalButtonContainer>
        <Button
          block
          secondary
          size="sm"
          onClick={() => onChangeModalStatus('SIGN_OUT_MODAL', false)}
        >
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button
          block
          size="sm"
          onClick={logout}
          disabled={status === Status.LOADING}
        >
          <FormattedMessage {...messages.logout} />
        </Button>
      </S.ModalButtonContainer>
    </S.SignoutModalWrapper>
  </Modal>
);

export default SignOutModal;
