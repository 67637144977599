import {
  TRADE_FAIRS_LANDING,
  LANDING_OFFICES,
  LANDING_PRODUCTS,
} from 'containers/App/routes';

export const navigationItems = [
  {
    name: 'Trade Fairs',
    url: TRADE_FAIRS_LANDING,
    isBlank: false,
  },
  {
    name: 'Products',
    url: LANDING_PRODUCTS,
    isBlank: false,
  },
  {
    name: 'Offices',
    url: LANDING_OFFICES,
    isBlank: false,
  },
  {
    name: 'GoGlobal',
    url: 'https://www.goglobal.travel/',
    isBlank: true,
  },
];
