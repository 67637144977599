import { fromJS } from 'immutable';

import { SET_MODAL_STATUS } from './constants';

export const initialState = fromJS({
  modalStatus: false,
  name: '',
});

function modalProviderReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MODAL_STATUS:
      if (state.get('name') === action.name) {
        return state.set('modalStatus', action.status);
      }
      return state.set('name', action.name).set('modalStatus', action.status);

    default:
      return state;
  }
}

export default modalProviderReducer;
