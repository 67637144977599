import { createSelector } from 'reselect';
import { initialState } from './reducer';

const getCommissionState = state => state.get('commission', initialState);

const makeSelectCommissions = () =>
  createSelector(getCommissionState, state => state.get('commissions'));

const makeSelectCommissionRate = () =>
  createSelector(getCommissionState, state => state.get('commissionRate'));

const makeSelectCommissionsStatus = () =>
  createSelector(getCommissionState, state => state.get('status'));

const makeSelectSetPriceMarginStatus = () =>
  createSelector(getCommissionState, state =>
    state.getIn(['setPriceMargin', 'status']),
  );

const makeSelectSetPriceMarginReason = () =>
  createSelector(getCommissionState, state =>
    state.getIn(['setPriceMargin', 'reason']),
  );

const makeSelectUserPriceMargin = () =>
  createSelector(getCommissionState, state => state.get('priceMargin'));

export {
  makeSelectCommissions,
  makeSelectCommissionRate,
  makeSelectCommissionsStatus,
  makeSelectSetPriceMarginStatus,
  makeSelectSetPriceMarginReason,
  makeSelectUserPriceMargin,
};
