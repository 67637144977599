import { parse, stringify } from 'query-string';
import { map, groupBy, forEach } from 'lodash';

import history from 'utils/history';

export const formUrl = form => {
  const filters = map(groupBy(form, 'name'), (field, key) => {
    const values = field
      .map(item => {
        if (item.name === 'pax') {
          return item.value.map(subItem => `${key}=${subItem}`).join('&');
        }
        return item.value;
      })
      .join(',');
    if (key !== 'pax') {
      return `${key}=${values}`;
    }
    return values;
  }).join('&');

  return filters;
};

export const formUrlWithOperator = form => {
  const filters = map(groupBy(form, 'name'), (field, key) => {
    const values = field.map(item => item.value).join(',');
    return `${key}=${values}`;
  }).join('&');

  return filters ? `${filters}` : '';
};

export const syncUrl = newParams => {
  const parsedCurrentParams = parse(history.location.search);
  const parsedNewParams = {};
  const query = formUrl(newParams);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    parsedNewParams[pair[0]] = decodeURIComponent(pair[1]);
  }
  const mergedParams = { ...parsedCurrentParams, ...parsedNewParams };
  history.push(`${history.location.pathname}?${stringify(mergedParams)}`);
};

export const replaceUrl = newParams => {
  const parsedNewParams = {};
  const query = formUrl(newParams);
  const vars = query.split('&');
  let paramsKeyCount = 1;
  let newQuery = query;

  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (parsedNewParams[pair[0]] === undefined) {
      parsedNewParams[pair[0]] = decodeURIComponent(pair[1]);
    } else {
      paramsKeyCount += 1;
      parsedNewParams[pair[0] + paramsKeyCount] = decodeURIComponent(pair[1]);
      /* eslint-disable no-loop-func */
      forEach(parsedNewParams, (value, key) => {
        if (key === pair[0] + paramsKeyCount) {
          newQuery = newQuery.replace(pair[0] + paramsKeyCount, pair[0]);
        }
      });
    }
  }
  history.push(`${history.location.pathname}?${newQuery}`);
};
