import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectBookDetail = state => state.get('bookDetail', initialState);

const makeSelectBookingInfo = () =>
  createSelector(selectBookDetail, state => state.getIn(['booking', 'data']));

const makeSelectBookingStatus = () =>
  createSelector(selectBookDetail, state => state.getIn(['booking', 'status']));

const makeSelectBookingReason = () =>
  createSelector(selectBookDetail, state => state.getIn(['booking', 'reason']));

const makeSelectBookingNotes = () =>
  createSelector(selectBookDetail, state => state.getIn(['notes', 'data']));

const makeSelectBookingNotesStatus = () =>
  createSelector(selectBookDetail, state => state.getIn(['notes', 'status']));

const makeSelectBookingNotesReason = () =>
  createSelector(selectBookDetail, state => state.getIn(['notes', 'reason']));

const makeSelectAddBookingNote = () =>
  createSelector(selectBookDetail, state => state.getIn(['addNote', 'data']));

const makeSelectAddBookingNoteStatus = () =>
  createSelector(selectBookDetail, state => state.getIn(['addNote', 'status']));

const makeSelectBookingSearchResults = () =>
  createSelector(selectBookDetail, state =>
    state.getIn(['bookingSearch', 'data']),
  );

const makeSelectBookingSearchResultsStatus = () =>
  createSelector(selectBookDetail, state =>
    state.getIn(['bookingSearch', 'status']),
  );

const makeSelectBookingSearchResultsReason = () =>
  createSelector(selectBookDetail, state =>
    state.getIn(['bookingSearch', 'reason']),
  );

const makeSelectBookStatus = () =>
  createSelector(
    makeSelectBookingInfo(),
    booking => (booking && booking.status ? booking.status.code : null),
  );

const makeSelectPaymentDeadline = () =>
  createSelector(
    makeSelectBookingInfo(),
    booking =>
      booking && booking.payment_deadline_date
        ? booking.payment_deadline_date
        : null,
  );

const makeSelectBookingPolicies = () =>
  createSelector(
    makeSelectBookingInfo(),
    booking => (booking && booking.policies ? booking.policies : []),
  );

const makeSelectBookingCheckin = () =>
  createSelector(
    makeSelectBookingInfo(),
    booking => (booking && booking.checkin ? booking.checkin : null),
  );

const makeSelectBookingAmount = () =>
  createSelector(
    makeSelectBookingInfo(),
    booking => (booking && booking.total_price ? booking.total_price : null),
  );

const makeSelectBookingRooms = () =>
  createSelector(
    makeSelectBookingInfo(),
    booking => (booking && booking.rooms ? booking.rooms : []),
  );

const makeSelectBookingNonrefundable = () =>
  createSelector(
    makeSelectBookingInfo(),
    booking =>
      booking && booking.nonrefundable ? booking.nonrefundable : false,
  );

const makeSelectBookingCode = () =>
  createSelector(
    makeSelectBookingInfo(),
    booking => (booking && booking.code ? booking.code : null),
  );

const makeSelectCancellationStatus = () =>
  createSelector(selectBookDetail, state =>
    state.getIn(['cancelBooking', 'status']),
  );

const makeSelectCancellationReason = () =>
  createSelector(selectBookDetail, state =>
    state.getIn(['cancelBooking', 'reason']),
  );

export {
  makeSelectBookingInfo,
  makeSelectBookingStatus,
  makeSelectBookingReason,
  makeSelectBookingNotes,
  makeSelectBookingNotesStatus,
  makeSelectBookingNotesReason,
  makeSelectAddBookingNote,
  makeSelectAddBookingNoteStatus,
  makeSelectBookStatus,
  makeSelectPaymentDeadline,
  makeSelectBookingCode,
  makeSelectBookingPolicies,
  makeSelectBookingCheckin,
  makeSelectBookingAmount,
  makeSelectBookingRooms,
  makeSelectBookingNonrefundable,
  makeSelectBookingSearchResults,
  makeSelectBookingSearchResultsStatus,
  makeSelectBookingSearchResultsReason,
  makeSelectCancellationStatus,
  makeSelectCancellationReason,
};
