/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
const addLocaleData = require('react-intl').addLocaleData; //eslint-disable-line
const enLocaleData = require('react-intl/locale-data/en');
const trLocaleData = require('react-intl/locale-data/tr');
const ruLocaleData = require('react-intl/locale-data/ru');
const frLocaleData = require('react-intl/locale-data/fr');
const nlLocaleData = require('react-intl/locale-data/nl');
const noLocaleData = require('react-intl/locale-data/no');
const deLocaleData = require('react-intl/locale-data/de');
const itLocaleData = require('react-intl/locale-data/it');
const svLocaleData = require('react-intl/locale-data/sv');
const faLocaleData = require('react-intl/locale-data/fa');
const arLocaleData = require('react-intl/locale-data/ar');
const esLocaleData = require('react-intl/locale-data/es');
const fiLocaleData = require('react-intl/locale-data/fi');
const daLocaleData = require('react-intl/locale-data/da');
const jaLocaleData = require('react-intl/locale-data/ja');
const plLocaleData = require('react-intl/locale-data/pl');
const zhLocalData = require('react-intl/locale-data/zh');
const hiLocalData = require('react-intl/locale-data/hi');
const urLocalData = require('react-intl/locale-data/ur');
const roLocalData = require('react-intl/locale-data/ro');
const heLocalData = require('react-intl/locale-data/he');
const elLocalData = require('react-intl/locale-data/el');
const bgLocalData = require('react-intl/locale-data/bg');
const thLocalData = require('react-intl/locale-data/th');
const bnLocalData = require('react-intl/locale-data/bn');
const sqLocalData = require('react-intl/locale-data/sq');
const huLocalData = require('react-intl/locale-data/hu');
const idLocalData = require('react-intl/locale-data/id');
const ptLocaleData = require('react-intl/locale-data/pt');

// const ukLocalData = require('react-intl/locale-data/uk');
// const kaLocalData = require('react-intl/locale-data/ka');
// const srLocalData = require('react-intl/locale-data/sr');
// const azLocalData = require('react-intl/locale-data/az');
// const hyLocalData = require('react-intl/locale-data/hy');

const enTranslationMessages = require('./translations/en.json');
const trTranslationMessages = require('./translations/tr.json');
const ruTranslationMessages = require('./translations/ru.json');
const frTranslationMessages = require('./translations/fr.json');
const nlTranslationMessages = require('./translations/nl.json');
const faTranslationMessages = require('./translations/fa.json');
const noTranslationMessages = require('./translations/no.json');
const deTranslationMessages = require('./translations/de.json');
const itTranslationMessages = require('./translations/it.json');
const svTranslationMessages = require('./translations/sv.json');
const arTranslationMessages = require('./translations/ar.json');
const esTranslationMessages = require('./translations/es.json');
const fiTranslationMessages = require('./translations/fi.json');
const daTranslationMessages = require('./translations/da.json');
const jaTranslationMessages = require('./translations/ja.json');
const plTranslationMessages = require('./translations/pl.json');
const zhTranslationMessages = require('./translations/zh.json');
const hiTranslationMessages = require('./translations/hi.json');
const urTranslationMessages = require('./translations/ur.json');
const roTranslationMessages = require('./translations/ro.json');
const heTranslationMessages = require('./translations/he.json');
const elTranslationMessages = require('./translations/el.json');
const bgTranslationMessages = require('./translations/bg.json');
const thTranslationMessages = require('./translations/th.json');
const bnTranslationMessages = require('./translations/bn.json');
const sqTranslationMessages = require('./translations/sq.json');
const huTranslationMessages = require('./translations/hu.json');
const idTranslationMessages = require('./translations/id.json');
const ptTranslationMessages = require('./translations/pt.json');

// const ukTranslationMessages = require('./translations/uk.json');
// const kaTranslationMessages = require('./translations/ka.json');
// const srTranslationMessages = require('./translations/sr.json');
// const azTranslationMessages = require('./translations/az.json');
// const hyTranslationMessages = require('./translations/hy.json');

addLocaleData(enLocaleData);
addLocaleData(trLocaleData);
addLocaleData(ruLocaleData);
addLocaleData(frLocaleData);
addLocaleData(nlLocaleData);
addLocaleData(noLocaleData);
addLocaleData(deLocaleData);
addLocaleData(itLocaleData);
addLocaleData(svLocaleData);
addLocaleData(faLocaleData);
addLocaleData(arLocaleData);
addLocaleData(esLocaleData);
addLocaleData(fiLocaleData);
addLocaleData(daLocaleData);
addLocaleData(jaLocaleData);
addLocaleData(plLocaleData);
addLocaleData(zhLocalData);
addLocaleData(hiLocalData);
addLocaleData(urLocalData);
addLocaleData(roLocalData);
addLocaleData(heLocalData);
addLocaleData(elLocalData);
addLocaleData(bgLocalData);
addLocaleData(thLocalData);
addLocaleData(bnLocalData);
addLocaleData(sqLocalData);
addLocaleData(huLocalData);
addLocaleData(idLocalData);
addLocaleData(ptLocaleData);

// addLocaleData(ukLocalData);
// addLocaleData(kaLocalData);
// addLocaleData(srLocalData);
// addLocaleData(hyLocalData);
// addLocaleData(azLocalData);

const DEFAULT_LOCALE = 'en';

// prettier-ignore
const appLocales = [
  'en',
  'tr',
  'ru',
  'fr',
  'nl',
  'no',
  'de',
  'it',
  'sv',
  'fa',
  'ar',
  'es',
  'fi',
  'da',
  'ja',
  'pl',
  'zh',
  'hi',
  'ur',
  'ro',
  'he',
  'el',
  'bg',
  'th',
  'bn',
  'sq',
  'hu',
  'id',
  'pt',
  // 'uk',
  // 'ka',
  // 'sr',
  // 'az',
  // 'hy',
];

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  tr: formatTranslationMessages('tr', trTranslationMessages),
  ru: formatTranslationMessages('ru', ruTranslationMessages),
  fr: formatTranslationMessages('fr', frTranslationMessages),
  nl: formatTranslationMessages('nl', nlTranslationMessages),
  no: formatTranslationMessages('no', noTranslationMessages),
  de: formatTranslationMessages('de', deTranslationMessages),
  it: formatTranslationMessages('it', itTranslationMessages),
  sv: formatTranslationMessages('sv', svTranslationMessages),
  fa: formatTranslationMessages('fa', faTranslationMessages),
  ar: formatTranslationMessages('ar', arTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
  fi: formatTranslationMessages('fi', fiTranslationMessages),
  da: formatTranslationMessages('da', daTranslationMessages),
  ja: formatTranslationMessages('ja', jaTranslationMessages),
  pl: formatTranslationMessages('pl', plTranslationMessages),
  'zh-cn': formatTranslationMessages('zh-cn', zhTranslationMessages),
  hi: formatTranslationMessages('hi', hiTranslationMessages),
  ur: formatTranslationMessages('ur', urTranslationMessages),
  ro: formatTranslationMessages('ro', roTranslationMessages),
  he: formatTranslationMessages('he', heTranslationMessages),
  el: formatTranslationMessages('el', elTranslationMessages),
  bg: formatTranslationMessages('bg', bgTranslationMessages),
  th: formatTranslationMessages('th', thTranslationMessages),
  bn: formatTranslationMessages('bn', bnTranslationMessages),
  sq: formatTranslationMessages('sq', sqTranslationMessages),
  hu: formatTranslationMessages('hu', huTranslationMessages),
  id: formatTranslationMessages('id', idTranslationMessages),
  pt: formatTranslationMessages('pt', ptTranslationMessages),
  // uk: formatTranslationMessages('uk', ukTranslationMessages),
  // ka: formatTranslationMessages('ka', kaTranslationMessages),
  // sr: formatTranslationMessages('sr', srTranslationMessages),
  // hy: formatTranslationMessages('hy', hyTranslationMessages),
  // az: formatTranslationMessages('az', azTranslationMessages),
};

exports.appLocales = appLocales;
exports.formatTranslationMessages = formatTranslationMessages;
exports.translationMessages = translationMessages;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
