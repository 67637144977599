import styled from 'styled-components';

const Footer = styled.div`
  padding-top: 65px;
  padding-bottom: 25px;
  background-color: #483da5;
`;

const Wrapper = styled.div`
  width: 1110px;
  margin-left: auto;
  margin-right: auto;
`;

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
`;

const Item = styled.a`
  margin-left: 10px;
`;

const SocialMedia = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FooterNavigation = styled.div``;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div``;

const ColumnHero = styled.div`
  margin-bottom: 15px;
  color: #fff;
  font-size: 17px;
`;

const ListItem = styled.a`
  display: block;
  margin-bottom: 2px;
  color: #fff;
  font-size: 14px;
  a {
    color: #fff;
    font-size: 14px;
  }
`;

const Description = styled.div`
  margin-bottom: 15px;
  color: #fff;
  font-size: 12px;
`;

const Form = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 350px;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  color: #949494;
  font-size: 15px;
  background-color: #fff;
  border: solid 1px #e7e5ef;
  border-radius: 2px;
`;

const Button = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  background-color: #ffa300;
  border-radius: 2px;
  .icon {
    margin-right: -12px;
    margin-bottom: -7px;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: solid 1px rgba(255, 255, 255, 0.3);
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
`;

const BottomNavigation = styled.div``;
const BottomItem = styled.a`
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-size: 12px;
`;

const MetglobalLogo = styled.img`
  max-width: 105px;
`;

const BankLogos = styled.img`
  max-width: 140px;
`;

const Copyright = styled.div`
  color: #fff;
  font-size: 12px;
  text-align: center;
`;

const Logo = styled.figure``;
const LogoImage = styled.img``;
const SocialImage = styled.img``;
const List = styled.div``;
const Newsletter = styled.div``;

export {
  Footer,
  Wrapper,
  HeaderTop,
  Logo,
  SocialMedia,
  LogoImage,
  SocialImage,
  Item,
  FooterNavigation,
  Links,
  Column,
  ColumnHero,
  List,
  ListItem,
  Newsletter,
  Description,
  Form,
  Input,
  Button,
  FooterBottom,
  MetglobalLogo,
  BottomNavigation,
  BottomItem,
  BankLogos,
  Copyright,
};
