import {
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  BANK_DETAILS,
} from 'containers/App/routes';

export const footerList = [
  {
    key: '0',
    href: TERMS_AND_CONDITIONS,
    name: 'termsConditions',
  },
  {
    key: '1',
    href: BANK_DETAILS,
    name: 'bankDetails',
  },
  {
    key: '2',
    href: PRIVACY_POLICY,
    name: 'privacyPolicy',
  },
];
