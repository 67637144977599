import { fromJS } from 'immutable';

import * as Status from 'utils/status';
import { storage } from 'utils/storage';

import {
  LOGIN_REQUESTED,
  LOGIN_FORM_SENT,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT_REQUESTED,
  LOGOUT_SUCCEEDED,
  LOGOUT_FAILED,
} from './constants';

export const initialState = fromJS({
  token: storage.getItem('USER_TOKEN') || null,
  reason: null,
  otpSent: false,
  otpPubKey: '',
  status: Status.INIT,
  userId: null,
  logout: {
    reason: null,
    status: Status.INIT,
  },
});

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUESTED:
      return state.set('status', Status.LOADING);

    case LOGIN_SUCCEEDED:
      return state
        .set('status', Status.LOADED)
        .set('token', action.token)
        .set('userId', action.userId);

    case LOGIN_FORM_SENT:
      return state
        .set('status', Status.LOADED)
        .set('otpSent', true)
        .set('otpPubKey', action.otpPubKey)
        .set('reason', null);

    case LOGIN_FAILED:
      if (
        action.reason.error_key !== undefined &&
        ['otp_expired', 'otp_pub_wrong', 'otp_try_exceeded'].includes(
          action.reason.error_key,
        )
      ) {
        // reset to main login form with the error message
        return state
          .set('status', Status.LOADED)
          .set('otpSent', false)
          .set('otpPubKey', '')
          .set('reason', action.reason);
      }
      return state.set('status', Status.FAILED).set('reason', action.reason);

    case LOGOUT_REQUESTED:
      return state.setIn(['logout', 'status'], Status.LOADING);
    case LOGOUT_SUCCEEDED:
      return state.setIn(['logout', 'status'], Status.LOADED);
    case LOGOUT_FAILED:
      return state
        .setIn(['logout', 'status'], Status.FAILED)
        .setIn(['logout', 'reason'], action.reason);

    default:
      return state;
  }
}
