import React from 'react';
import PropTypes from 'prop-types';

import { LoginHeader } from '../Header';

import * as S from './style';

const LoginLayout = props => {
  const { children, center, showDetails } = props;
  const layoutProps = { center };
  const headerProps = { showDetails };
  return (
    <S.DetailsLayout>
      <LoginHeader {...headerProps} />
      <S.Wrapper {...layoutProps}>
        <S.LoginContent className="layout-content">{children}</S.LoginContent>
      </S.Wrapper>
    </S.DetailsLayout>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
  center: PropTypes.bool,
};

export default LoginLayout;
