import styled, { css } from 'styled-components';

const Header = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #3A8DC3;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
`;

const Logo = styled.a``;

const Image = styled.img`
  max-width: 150px;
`;

const Navigation = styled.div`
  margin-left: 15px;
  margin-right: auto;
`;

const NavigationItem = styled.div`
  display: inline-block;
  margin-left: 30px;
  .item {
    color: #cdc7ff;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s linear;
    &:hover {
      color: #fff;
    }
  }
`;

const Links = styled.div``;

const LinkItem = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 15px;
  .item {
    color: #cdc7ff;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s linear;
    text-transform: capitalize;
    &:hover {
      color: #fff;
    }
  }
  &:nth-child(2) .item {
    margin-left: 15px;
    margin-right: 15px;
  }
  &:nth-child(2) .item {
    display: inline-block;
    width: 110px;
    height: 35px;
    margin-left: 15px;
    color: #f8a119;
    text-align: center;
    line-height: 35px;
    border: solid 1px #f8a119;
    border-radius: 2px;
  }
  &:nth-child(3) .item {
    display: inline-block;
    width: 110px;
    height: 35px;
    color: #fff;
    text-align: center;
    line-height: 35px;
    background-color: #f8a119;
    border: solid 1px #f8a119;
    border-radius: 2px;
  }
  .menu > span {
    width: 50px !important;
  }
  ${props =>
    props.language &&
    css`
      .menu > span {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        margin-top: -20px;
        width: 100px;
        height: 30px;
      }
      .list {
        margin-top: 20px;
      }
    `};
`;

const Icon = styled.img`
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
`;

export {
  Header,
  Wrapper,
  Logo,
  Image,
  Navigation,
  NavigationItem,
  Links,
  LinkItem,
  Icon,
};
