import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { storage } from 'utils/storage';

import Button from 'components/Button';
import Icon from 'components/Icon';

import { SwitchBackVersion } from './SwitchBackVersion';

import messages from './messages';
import './style.scss';

const NotificationToast = () => (
  <div className="notification-toast">
    <Icon name="info" width="20" color="#f8a119" />
    <div className="notification-toast__message">
      <FormattedMessage {...messages.returnBottomOfPage} />
    </div>
  </div>
);

class Notification extends PureComponent {
  state = {
    isShownNotification: JSON.parse(storage.getItem('SHOW_NOTIFICATION')),
  };

  handleShowNotification = () => {
    storage.setItem('SHOW_NOTIFICATION', false);

    this.setState(
      {
        isShownNotification: false,
      },
      () => {
        toast(<NotificationToast />, {
          className: 'notification-toast-container',
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000,
        });
      },
    );
  };

  render() {
    const { isShownNotification } = this.state;

    if (!isShownNotification) {
      return null;
    }

    return (
      <div className="notification-bar">
        <span className="notification-desc">
          <span role="img" aria-label="tada">
            🎉
          </span>{' '}
          <FormattedMessage {...messages.desc} />
        </span>
        <SwitchBackVersion />
        <Button
          className="close-button"
          transparent
          onClick={this.handleShowNotification}
        >
          <Icon name="close" width={12} color="#FFF" />
        </Button>
      </div>
    );
  }
}

export default Notification;
