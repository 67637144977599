import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Loader } from 'components/Loader/style';
import { Icon } from 'components/Icon/style';

const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #f8a119;
  padding: 16.5px 16px;
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  border: 1px solid transparent;
  letter-spacing: 0.4px;
  cursor: pointer;
  &:active {
    background-color: #e39214;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: #dbdbdb;
  }
  ${props =>
    props.isLoading &&
    css`
      ${Loader} {
        margin-left: 10px;
      }
    `};
  ${props =>
    props.block &&
    css`
      display: flex;
      width: 100%;
    `};
  ${props =>
    props.transparent &&
    css`
      height: inherit;
      border-radius: inherit;
      background-color: transparent;
      border: 0;
      padding: 0;
      color: inherit;
      &:active {
        background-color: transparent;
      }
    `};
  ${props =>
    props.secondary &&
    css`
      border: 1px solid #f8a119;
      background-color: #fff;
      color: #f8a119;
      &:active {
        border: 1px solid #e39214;
        color: #e39214;
        background-color: #fff;
      }
      &:disabled {
        border: 1px solid #dcdbe2;
        cursor: not-allowed;
        pointer-events: none;
        color: #949494;
        background-color: transparent;
      }
      &:hover {
        &&& {
          background-color: #f8a119;
          color: #fff;
          ${Icon} {
            color: #fff;
          }
        }
      }
    `};
  ${({ size }) =>
    size === 'sm' &&
    css`
      padding: 13.5px 16px;
      font-size: 16px;
    `};

  ${({ size }) =>
    size === 'xs' &&
    css`
      padding: 10.5px 16px;
      font-size: 14px;
      letter-spacing: 0.2px;
    `};

  ${({ size }) =>
    size === 'xxs' &&
    css`
      padding: 6.5px 16px;
      font-size: 14px;
      letter-spacing: 0.2px;
      ${Loader} {
        width: 8px;
        height: 8px;
      }
    `};

  ${({ size }) =>
    size === 'lg' &&
    css`
      padding: 14px 70px;
      font-size: 16px;
      letter-spacing: 0.2px;
    `};

  ${Icon} + *, * + ${Icon} {
    margin-left: 8px;
  }
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #f8a119;
  padding: 16.5px 16px;
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  border: 1px solid transparent;
  cursor: pointer;
  &:active {
    background-color: #e39214;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #dbdbdb;
  }
  ${props =>
    props.block &&
    css`
      display: flex;
      width: 100%;
    `};
  ${props =>
    props.transparent &&
    css`
      height: inherit;
      border-radius: inherit;
      background-color: transparent;
      border: 0;
      padding: 0;
      color: inherit;
      &:active {
        background-color: transparent;
      }
    `};
  ${props =>
    props.secondary &&
    css`
      border: 1px solid #f8a119;
      background-color: #fff;
      color: #f8a119;
      &:active {
        border: 1px solid #e39214;
        color: #e39214;
        background-color: #fff;
      }
      &:disabled {
        border: 1px solid #dcdbe2;
        cursor: not-allowed;
        color: #949494;
        background-color: transparent;
      }
      &:hover {
        &&& {
          background-color: #f8a119;
          color: #fff;
          ${Icon} {
            color: #fff;
          }
        }
      }
    `};
  ${({ size }) =>
    size === 'sm' &&
    css`
      padding: 13.5px 16px;
      font-size: 16px;
    `};

  ${({ size }) =>
    size === 'xs' &&
    css`
      padding: 10.5px 16px;
      font-size: 14px;
      letter-spacing: 0.2px;
    `};

  ${({ size }) =>
    size === 'xxs' &&
    css`
      padding: 6.5px 16px;
      font-size: 14px;
      letter-spacing: 0.2px;
    `};

  ${Icon} + *, * + ${Icon} {
    margin-left: 8px;
  }
`;

export { Button, StyledLink };
