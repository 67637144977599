import { fromJS } from 'immutable';

import {
  SET_SELECTED_PHOTOS,
  SET_SELECTED_ROOMS,
  SET_MAIN_IMAGE,
} from './constants';

export const initialState = fromJS({
  selectedPhotos: [],
  selectedRooms: [],
  mainImage: { id: '', src: '' },
});

function createOfferReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_PHOTOS:
      return state.set('selectedPhotos', fromJS(action.selectedPhotosList));
    case SET_SELECTED_ROOMS:
      return state.set('selectedRooms', fromJS(action.selectedRoomsList));
    case SET_MAIN_IMAGE:
      return state.set('mainImage', fromJS(action.mainImage));
    default:
      return state;
  }
}

export default createOfferReducer;
