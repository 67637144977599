export const GET_COMMISSIONS_REQUESTED = 'GET_COMMISSIONS_REQUESTED';
export const GET_COMMISSIONS_SUCCEEDED = 'GET_COMMISSIONS_SUCCEEDED';
export const GET_COMMISSIONS_FAILED = 'GET_COMMISSIONS_FAILED';

export const SET_COMMISSION_RATE_REQUESTED = 'SET_COMMISSION_RATE_REQUESTED';
export const SET_COMMISSION_RATE_SUCCEEDED = 'SET_COMMISSION_RATE_SUCCEEDED';
export const SET_COMMISSION_RATE_FAILED = 'SET_COMMISSION_RATE_FAILED';

export const SET_PRICE_MARGIN_REQUESTED = 'SET_PRICE_MARGIN_REQUESTED';
export const SET_PRICE_MARGIN_SUCCEEDED = 'SET_PRICE_MARGIN_SUCCEEDED';
export const SET_PRICE_MARGIN_FAILED = 'SET_PRICE_MARGIN_FAILED';
