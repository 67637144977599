import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { omit, noop } from 'lodash';

import {
  makeSelectModalStatus,
  makeSelectModalName,
} from 'containers/ModalProvider/selectors';

import { setModalStatus } from 'containers/ModalProvider/actions';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { $on, $off } from 'helpers/dom';

import Icon from '../Icon';

import * as S from './style';
import messages from './messages';

class Modal extends PureComponent {
  componentDidMount() {
    $on(document, 'mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    $off(document, 'mousedown', this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    const { name, onChangeModalStatus, closable, onClose } = this.props;
    const contains = this.wrapperRef && !this.wrapperRef.contains(event.target);
    if (contains && closable) {
      onChangeModalStatus(name, false);
      onClose();
    }
  };

  render() {
    const {
      header,
      closeHeader,
      children,
      status,
      type,
      name,
      closeText,
      position,
      closable,
      onChangeModalStatus,
      className,
      onClose,
    } = this.props;
    const modalProps = omit(this.props, 'onChangeModalStatus');
    const classes = classNames('modal', className);
    const isVisible = type === name && status;
    return (
      isVisible && (
        <S.Wrapper {...modalProps} className={classes}>
          {position === 'current' && <S.PositionBasedModal />}
          <S.Modal ref={this.setWrapperRef}>
            <S.Close>
              {closeHeader && <S.CloseHeader>{closeHeader}</S.CloseHeader>}
              {closable && (
                <S.Link
                  onClick={() => {
                    onChangeModalStatus(name, false);
                    onClose();
                  }}
                >
                  {closeText}
                  <Icon name="close" width="14" color="#fff" />
                </S.Link>
              )}
            </S.Close>
            {header && <S.Header>{header}</S.Header>}
            <S.Content>{children}</S.Content>
          </S.Modal>
        </S.Wrapper>
      )
    );
  }
}

Modal.defaultProps = {
  closeText: <FormattedMessage {...messages.close} />,
  position: 'center',
  closable: true,
  isFocusable: true,
  onClose: () => noop,
};

Modal.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  closeHeader: PropTypes.node,
  status: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  closeText: PropTypes.node,
  position: PropTypes.string,
  onChangeModalStatus: PropTypes.func,
  closable: PropTypes.bool,
  scrollable: PropTypes.bool,
  isFocusable: PropTypes.bool,
  onClose: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  status: makeSelectModalStatus(),
  name: makeSelectModalName(),
});

const mapDispatchToProps = dispatch => ({
  onChangeModalStatus: (name, status) => {
    dispatch(setModalStatus(name, status));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
