import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as S from './style';

const OleryRate = props => {
  const { value, header, rate, className } = props;
  const classes = classNames('olery-rate', className);
  return (
    <S.OleryRate className={classes}>
      <S.RateBar max={100} value={value} header={header} />
      <S.Text>{rate}</S.Text>
    </S.OleryRate>
  );
};

OleryRate.propTypes = {
  header: PropTypes.string,
  value: PropTypes.number,
  rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default OleryRate;
