import styled, { css } from 'styled-components';
import Button from 'components/Button';
import { Icon } from '../Icon/style';
import { LogoWrapper, Logo } from '../Sidebar/style';
import { ProfileCard } from './ProfileMenu/style';

export const headerHeight = `60px`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: ${headerHeight};
  border-bottom: 1px solid #e7e6f6;
  background-color: #fff;
`;

const ListItem = styled.li`
  height: 100%;
  a {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.2px;
    text-decoration: none;
  }
`;

const MenuList = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
`;

const HeaderListItem = styled.nav`
  height: 100%;
  margin-right: 8px;
  .nav-link {
    display: flex;
    padding: 8px;
    align-items: center;
    height: 100%;
    color: #444444;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-decoration: none;
    position: relative;
  }
  ${Icon} {
    margin-left: 8px;
  }
`;

const Menu = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  color: #303030;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  ${Icon} {
    margin-left: 8px;
  }
`;

const DetailHeader = styled(Header)`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  padding-left: 0;

  ${props =>
    props.isBlock &&
    css`
      max-width: inherit;
    `};
`;

const DetailHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 9.4%;
  padding-right: 9.4%;
`;

const BackLink = styled.a`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 23px;
  ${Icon} {
    margin-right: 10px;
  }
`;

const DateList = styled.ul`
  display: inline-flex;
`;

const DateItem = styled.li`
  display: inline-flex;
  padding-left: 16px;
  padding-right: 16px;
  color: #626262;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 23px;
  &:not(:last-child) {
    border-right: 1px solid #e7e6f6;
  }
  &:last-child {
    padding-right: 0;
  }
`;

const StatusBadge = styled.div`
  display: inline-flex;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
  height: 28px;
  border-radius: 2px;
  border: 1px solid #adadad;
  background-color: rgba(233, 233, 233, 0.35);
  color: #949494;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  ${props =>
    props.success &&
    css`
      border-color: #27da58;
      background-color: #f4fff7;
      color: #27da58;
    `};
  ${props =>
    props.cancelled &&
    css`
      border-color: #ff5774;
      background-color: rgba(255, 87, 116, 0.35);
      color: #ff5774;
    `};
  ${Icon} {
    margin-right: 8px;
  }
`;

const DetailHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckoutHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${headerHeight};
  border-bottom: 1px solid #e7e6f6;
  background-color: #fff;
  padding-left: 20px;

  ${ProfileCard} {
    border-left: 0;
  }

  ${LogoWrapper} {
    margin-right: 24px;
    width: initial;
  }

  ${Logo} {
    height: 36px;
  }
`;

const LogoImage = styled.img`
  width: fit-content;
`;

const LoginHeader = styled(Header)`
  padding-left: 24px;
  padding-right: 24px;
`;

const LoginLogoWrapper = styled(Button)`
  display: inline-flex;
  align-items: center;
  height: 100%;
`;

const MainLogoWrapper = styled(Button)`
  display: inline-flex;
  align-items: center;
  height: 100%;
  margin-left: 32px;
  margin-right: 20px;
`;

const ListContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const LanguageMenu = styled.div`
  position: relative;
`;

const HeaderList = styled.div`
  display: flex;
  height: 100%;
`;

const LogoButton = styled(Button)`
  ${LogoWrapper} {
    margin-right: 0;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ItemName = styled.div``;

const DropdownIcon = styled.div``;

const SubItemList = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 20px;
    top: 0;
    transform: rotate(-45deg) translateY(-10px);
    background-color: #fff;
    border: 1px solid #e7e5ef;
    border-bottom: 0;
    border-left: 0;
    border-radius: 0px 4px;
    z-index: 1;
  }
`;

const SubItemWrapper = styled.a`
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #f0f0f0;
  transition: 0.2s;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.22px;
  color: #484848;
  &:hover {
    background-color: #f2f1f6;
  }
`;

export {
  Header,
  CheckoutHeader,
  DetailHeader,
  MenuList,
  HeaderListItem,
  ListItem,
  Menu,
  BackLink,
  DateList,
  DateItem,
  StatusBadge,
  DetailHeaderWrapper,
  DetailHeaderContainer,
  LogoWrapper,
  Logo,
  LogoImage,
  LoginHeader,
  LoginLogoWrapper,
  ListContainer,
  HeaderList,
  LanguageMenu,
  LogoButton,
  MainLogoWrapper,
  ItemWrapper,
  ItemName,
  DropdownIcon,
  SubItemList,
  SubItemWrapper,
};
