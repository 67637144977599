import {
  HOME,
  SEARCH_RESULTS,
  HOTEL_DETAIL,
  CREATE_OFFER,
  TRANSFER_FORM,
  TRADE_FAIRS,
  TRADE_FAIRS_FORM,
  CHECKOUT,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  THANK_YOU_BASE,
  MY_ACCOUNT,
  AGENCY_INFORMATION_EDITOR,
  ADD_NEW_USER,
  GET_HELP,
  MY_BOOKINGS,
  SIGNUP,
  GROUP_ACCOMMODATION,
  FIT_ACCOMMODATION,
  TRANSFER,
  PROFIT,
  FAQ,
  INBOX,
  TOURS,
  REPORTS,
  MY_FAVOURITES_BASE,
  PAYOUT_BASE,
  VOUCHER_BASE,
  SUBAGENCY_INFORMATION_PATH,
  CAR_RENTAL,
} from './routes';

export const titles = path => {
  const checkedPath = checkPath(path);
  switch (checkedPath) {
    case HOME:
      return 'Home Page';
    case MY_BOOKINGS:
      return 'My Bookings';
    case SEARCH_RESULTS:
      return 'Search Results';
    case HOTEL_DETAIL:
      return 'Hotel Detail';
    case CREATE_OFFER:
      return 'Create Offer';
    case TRADE_FAIRS:
      return 'Trade Fairs';
    case TRADE_FAIRS_FORM:
      return 'Trade Fairs Form';
    case PRIVACY_POLICY:
      return 'Privacy Policy';
    case TERMS_AND_CONDITIONS:
      return 'Terms And Conditions';
    case CHECKOUT:
      return 'Checkout';
    case PAYOUT_BASE:
      return 'Payout';
    case THANK_YOU_BASE:
      return 'Thank You';
    case TRANSFER_FORM:
      return 'Transfer Form';
    case VOUCHER_BASE:
      return 'Voucher';
    case MY_ACCOUNT:
      return 'My Account';
    case AGENCY_INFORMATION_EDITOR:
      return 'Agency Information Editor';
    case SUBAGENCY_INFORMATION_PATH:
      return 'Subagency Information';
    case ADD_NEW_USER:
      return 'Add New User';
    case GET_HELP:
      return 'Get Help';
    case PROFIT:
      return 'Profit';
    case TOURS:
      return 'Tours and Activities';
    case SIGNUP:
      return 'Sign Up';
    case GROUP_ACCOMMODATION:
      return 'Offline Requests / Group Accommodation';
    case FIT_ACCOMMODATION:
      return 'Offline Requests / FIT Accommodation';
    case TRANSFER:
      return 'Offline Requests / Transfer';
    case FAQ:
      return 'Frequently Asked Questions';
    case INBOX:
      return 'My Inbox';
    case MY_FAVOURITES_BASE:
      return 'My Favourites';
    case REPORTS:
      return 'Reports';
    case CAR_RENTAL:
      return 'Car Rental';
    default:
      return 'No title';
  }
};

const checkPath = path => {
  let checkedPath = null;
  if (path.length > 1 && path.slice(-1) === '/') {
    checkedPath = path.slice(0, -1);
  } else {
    checkedPath = path;
  }

  return checkedPath;
};
