import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { range } from 'lodash';

import Icon from 'components/Icon';

import * as S from './style';

const maxStarCount = 5;

const Stars = props => {
  const { starCount, color, width, className, hideDisabledStars } = props;
  const classes = classNames('stars', className);

  if (starCount === 0) {
    return null;
  }

  const renderStars = () =>
    range(0, hideDisabledStars ? starCount : maxStarCount).map(star => (
      <Icon
        name="star"
        width={width}
        color={star < starCount ? color : '#d7d7d7'}
        key={star}
      />
    ));

  return <S.Stars className={classes}>{renderStars()}</S.Stars>;
};

Stars.defaultProps = {
  color: '',
  starCount: 0,
  width: 0,
  hideDisabledStars: false,
};

Stars.propTypes = {
  color: PropTypes.string,
  starCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.number,
  className: PropTypes.string,
  hideDisabledStars: PropTypes.bool,
};

export default Stars;
