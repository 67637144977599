import { apiConfig, API_KEY } from 'config';
import { storage } from 'utils/storage';

import { req } from 'utils/request';

export function loginApi(authParams) {
  return req({
    'API-KEY': API_KEY,
    'Accept-Language': storage.getItem('USER_LANGUAGE'),
  }).post(apiConfig.account.login, authParams);
}

export function logoutApi() {
  return req({
    Authorization: `Token ${storage.getItem('USER_TOKEN')}`,
    'API-KEY': API_KEY,
  }).get(apiConfig.account.logout);
}

export function forgotPasswordApi(authParams) {
  return req({
    'API-KEY': API_KEY,
    'Accept-Language': storage.getItem('USER_LANGUAGE'),
  }).post(apiConfig.account.passwordReset, authParams);
}

export function resendActivationApi(email) {
  return req({
    'API-KEY': API_KEY,
    'Accept-Language': storage.getItem('USER_LANGUAGE'),
  }).post(apiConfig.account.resendActivation, email);
}

export function resetPasswordApi(authParams) {
  return req({
    'API-KEY': API_KEY,
    'Accept-Language': storage.getItem('USER_LANGUAGE'),
  }).put(apiConfig.account.passwordReset, authParams);
}

export function userInfoApi(token, userId) {
  return req({
    Authorization: `Token ${token}`,
    'API-KEY': API_KEY,
  }).get(`${apiConfig.account.userInfo}/${userId}`);
}

export function userPermissionsApi(token, userId) {
  return req({
    Authorization: `Token ${token}`,
    'API-KEY': API_KEY,
  }).get(`${apiConfig.account.userPermissions(userId)}`);
}

export function userPermsApi(token, userId) {
  return req({
    Authorization: `Token ${token}`,
    'API-KEY': API_KEY,
  }).get(`${apiConfig.account.userPerms(userId)}`);
}

export function userAgencyInfoApi(token, agencyId) {
  return req({
    Authorization: `Token ${token}`,
    'API-KEY': API_KEY,
  }).get(`${apiConfig.account.agencyInformationDetail(agencyId)}`);
}

export function getUserBalancesApi(token, userId) {
  return req({
    Authorization: `Token ${token}`,
    'API-KEY': API_KEY,
  }).get(`${apiConfig.account.userBalances(userId)}`);
}

export function getUserWaitingBookingsApi(token, userId) {
  return req({
    Authorization: `Token ${token}`,
    'API-KEY': API_KEY,
  }).get(`${apiConfig.account.getWaitingBookings(userId)}`);
}

export function authCheckApi(token) {
  return req({
    Authorization: `Token ${token}`,
    'API-KEY': API_KEY,
  }).get(`${apiConfig.account.authCheck}/${token}`);
}
