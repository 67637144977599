import React, { Component } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { HOME } from 'containers/App/routes';
import { makeSelectFullName } from 'containers/Authentication/ReturnLogin/selectors';

import LogoSmall from 'images/hp.png';

import ProfileMenu from './ProfileMenu/index';

import * as S from './style';

class CheckoutHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: props.userName,
    };
  }

  render() {
    const { userName } = this.state;
    return (
      <S.CheckoutHeader>
        <S.LogoButton to={HOME} transparent>
          <S.LogoWrapper>
            <S.Logo src={LogoSmall} alt="logo" />
          </S.LogoWrapper>
        </S.LogoButton>
        <ProfileMenu userName={userName} />
      </S.CheckoutHeader>
    );
  }
}

CheckoutHeader.propTypes = {
  userName: PropTypes.string,
};

const mapStateToProps = createSelector(makeSelectFullName(), userName => ({
  userName,
}));

export default connect(
  mapStateToProps,
  null,
)(CheckoutHeader);
