import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import Cookie from 'js-cookie';

import { createBrowserHistory } from 'history';

import { HOME, LOGIN } from 'containers/App/routes';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { setUserCurrencySucceeded } from 'containers/CurrencyProvider/actions';
import { setUserCommissionSucceeded } from 'containers/CommissionProvider/actions';
import { storage } from 'utils/storage';
import { setLanguageDirection } from 'containers/LanguageProvider/saga';
import {
  LOGIN_REQUESTED,
  LOGOUT_REQUESTED,
  LOGIN_WITH_COOKIE_REQUESTED,
} from './constants';
import {
  loginFailed,
  loginFormSent,
  loginSucceeded,
  logoutSucceeded,
  logoutFailed,
} from './actions';

import {
  userInfoApi,
  loginApi,
  logoutApi,
  userPermissionsApi,
  userPermsApi,
  userAgencyInfoApi,
  getUserBalancesApi,
  getUserWaitingBookingsApi,
} from '../api';
import { makeSelectUserId, makeSelectToken } from './selectors';

const history = createBrowserHistory({
  forceRefresh: true,
});

function* storeToken(token) {
  storage.setItem('USER_TOKEN', token);
  Cookie.set('token', token, { path: '', domain: '.hprotravel.com' });
  Cookie.set('token', token, { path: '', domain: '.met.ms' });
}

function* storeAccountType(accountType) {
  storage.setItem('ACCOUNT_TYPE', accountType);
}

function* storeUserId(userId) {
  storage.setItem('USER_ID', userId);
}

/* eslint-disable camelcase */
function* storeUserInfo({
  email,
  first_name,
  last_name,
  currency,
  language,
  commission_rate,
  displayed_price_rate,
  username,
}) {
  storage.setItem('USER_FULL_NAME', `${first_name} ${last_name}`);
  storage.setItem('USER_CURRENCY', currency.code);
  storage.setItem('USER_LANGUAGE', language.code);
  storage.setItem('USER_LANGUAGE_NAME', language.name);
  storage.setItem('USER_EMAIL', email);
  storage.setItem('COMMISSION_RATE', commission_rate);
  storage.setItem('PRICE_MARGIN', displayed_price_rate);
  storage.setItem('AGENCY_USER_NAME', username);
}

function* storeAgencyInfo({
  is_sub_agency,
  country,
  id,
  name,
  region,
  code,
  last_booking_created_date,
}) {
  storage.setItem('IS_SUB_AGENCY', !!is_sub_agency);
  storage.setItem('AGENCY_COUNTRY_CODE', country.code);
  storage.setItem('AGENCY_COUNTRY_NAME', country.name);
  storage.setItem('AGENCY_ID', id);
  storage.setItem('AGENCY_NAME', name);
  storage.setItem('AGENCY_REGION', region.code);
  storage.setItem('AGENCY_CODE', code);
  storage.setItem('LAST_BOOKING_CREATED_DATE', last_booking_created_date);
}

function* storeUserPerms({ can_edit_commission }) {
  storage.setItem('CAN_EDIT_COMMISSION', can_edit_commission);
}

function* removeUserToken() {
  storage.removeItem('USER_TOKEN');
  return storage.getItem('USER_TOKEN');
}

function* storeShowNotification() {
  storage.setItem('SHOW_NOTIFICATION', true);
}

export function* getUserInfo(token, userId) {
  try {
    const { data } = yield call(userInfoApi, token, userId);

    return data;
  } catch (e) {
    yield put(loginFailed(e.message));
    return false;
  }
}

export function* getUserPerms(token, userId) {
  try {
    const { data } = yield call(userPermissionsApi, token, userId);
    const {
      data: { objects },
    } = yield call(userPermsApi, token, userId);
    const userAllPermissions = { ...data };
    objects.map(item => {
      userAllPermissions[item.codename] = item.value;
      return null;
    });
    storage.setItem('permissions', JSON.stringify(userAllPermissions));
    return data;
  } catch (e) {
    yield put(loginFailed(e.message));
    return false;
  }
}

export function* getUserBalances(token, userId) {
  try {
    const { data } = yield call(getUserBalancesApi, token, userId);
    return data;
  } catch (e) {
    yield put(loginFailed(e.message));
    return false;
  }
}

export function* getUserWaitingBookings(token, userId) {
  try {
    const { data } = yield call(getUserWaitingBookingsApi, token, userId);

    return data;
  } catch (e) {
    yield put(loginFailed(e.message));
    return false;
  }
}

export function* getAgencyInfo(token, agencyId) {
  try {
    const { data } = yield call(userAgencyInfoApi, token, agencyId);

    return data;
  } catch (e) {
    yield put(loginFailed(e.message));
    return false;
  }
}

export function* login({ payload }) {
  const { setSubmitting, setErrors } = payload.actions;
  const { username, rememberMe } = payload.values;

  try {
    if (payload.values.alreadyPosted !== undefined) {
      if (payload.values.alreadyPosted === true) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      payload.values.alreadyPosted = true;
    }
    const { data } = yield call(loginApi, payload.values);
    if (
      data.next_mfa_step !== undefined &&
      data.next_mfa_step === 2 &&
      data.otp_pub_key
    ) {
      // OTP
      yield put(loginFormSent(data.otp_pub_key));
      window.grecaptcha.reset();
      yield call(setSubmitting, false);
      return;
    }

    yield put(loginSucceeded(data.token, data.user_id));

    const userId = yield select(makeSelectUserId());
    const token = yield select(makeSelectToken());

    if (rememberMe) {
      storage.setItem('USERNAME', username);
    } else {
      storage.removeItem('USERNAME');
    }

    if (userId && token) {
      const userData = yield call(getUserInfo, token, data.user_id);
      const userPerms = yield call(getUserPerms, token, data.user_id);
      const agencyInfo = yield call(getAgencyInfo, token, userData.agency_id);
      yield call(storeToken, data.token);
      yield call(storeAccountType, data.account_type);
      yield call(storeUserId, data.user_id);
      yield call(storeAgencyInfo, agencyInfo);
      yield put(changeLocale(userData.language.code, userData.language.name));
      yield put(setUserCurrencySucceeded(userData.currency.code));
      yield put(setUserCommissionSucceeded(userData.commission_rate));
      yield call(storeUserInfo, userData);
      yield call(storeUserPerms, userPerms);
      yield call(storeShowNotification);
      yield call(setLanguageDirection, userData.language.code);
    }

    yield call(setSubmitting, false);
    if (history.location.pathname) {
      yield call(
        history.replace,
        `${history.location.pathname}${history.location.search}`,
      );
    } else {
      yield call(history.replace, HOME);
    }
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      yield call(setErrors, {
        authentication: data.error,
      });
      yield put(loginFailed(data.error));
    }

    if (window.grecaptcha !== undefined) {
      window.grecaptcha.reset();
    }
    yield call(setSubmitting, false);
  }
}

// @TODO: Will be deleted old hprotravel.com
export function* loginWithCookie({ token, userId }) {
  try {
    const userData = yield call(getUserInfo, token, userId);
    const userPerms = yield call(getUserPerms, token, userId);
    const agencyInfo = yield call(getAgencyInfo, token, userData.agency_id);
    yield call(storeToken, token);
    yield call(storeUserId, userId);
    yield call(storeAgencyInfo, agencyInfo);
    yield put(changeLocale(userData.language.code, userData.language.name));
    yield put(setUserCurrencySucceeded(userData.currency.code));
    yield put(setUserCommissionSucceeded(userData.commission_rate));
    yield call(storeUserInfo, userData);
    yield call(storeAccountType, userData.account_type);
    yield call(storeUserPerms, userPerms);
    yield call(storeShowNotification);
    yield call(setLanguageDirection, userData.language.code);

    // Old to new login state.
    if (history.location.pathname) {
      yield call(
        history.replace,
        `${history.location.pathname}${history.location.search}`,
      );
    } else {
      yield call(history.replace, HOME);
    }
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      yield put(loginFailed(data.error));
    }
  }
}

export function* logout() {
  try {
    const { data } = yield call(logoutApi);
    yield put(logoutSucceeded(data));
    const token = yield call(removeUserToken);
    if (token === null) {
      yield call(history.replace, LOGIN);
    }
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      yield put(logoutFailed(data.error.message));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* root() {
  yield all([
    takeLatest(LOGIN_REQUESTED, login),
    takeLatest(LOGOUT_REQUESTED, logout),
    takeLatest(LOGIN_WITH_COOKIE_REQUESTED, loginWithCookie),
  ]);
}
