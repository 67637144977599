/**
 * Login selectors
 */

import { createSelector } from 'reselect';
import { storage } from 'utils/storage';
import { initialState } from './reducer';

const selectLogin = state => state.get('login', initialState);

const makeSelectToken = () =>
  createSelector(selectLogin, state => state.get('token'));

const makeSelectUserId = () =>
  createSelector(selectLogin, state => state.get('userId'));

const makeSelectReason = () =>
  createSelector(selectLogin, state => state.get('reason'));

const makeSelectOtpSent = () =>
  createSelector(selectLogin, state => state.get('otpSent'));

const makeSelectOtpPubKey = () =>
  createSelector(selectLogin, state => state.get('otpPubKey'));

const makeSelectStatus = () =>
  createSelector(selectLogin, state => state.get('status'));

const makeSelectLogoutStatus = () =>
  createSelector(selectLogin, state => state.getIn(['logout', 'status']));

const makeSelectLogoutReason = () =>
  createSelector(selectLogin, state => state.getIn(['logout', 'reason']));

const makeSelectLanguage = () =>
  createSelector(() => storage.getItem('USER_LANGUAGE'));

const makeSelectFullName = () =>
  createSelector(() => storage.getItem('USER_FULL_NAME'));

const makeSelectAgencyId = () =>
  createSelector(() => storage.getItem('AGENCY_ID'));

const makeSelectIsSubAgency = () =>
  createSelector(() => JSON.parse(storage.getItem('IS_SUB_AGENCY')));

const makeSelectAgencyCountryCode = () =>
  createSelector(() => storage.getItem('AGENCY_COUNTRY_CODE'));

const makeSelectAgencyCountryName = () =>
  createSelector(() => storage.getItem('AGENCY_COUNTRY_NAME'));

const makeSelectCommissionRate = () =>
  createSelector(() => storage.getItem('COMMISSION_RATE'));

const makeSelectUserInfoStatus = () =>
  createSelector(selectLogin, state => state.get('status'));

const makeSelectUserBalance = () =>
  createSelector(() => JSON.parse(storage.getItem('USER_BALANCE')));

const makeSelectCanEditCommission = () =>
  createSelector(
    () => storage.getParsedValue('permissions').use_user_commission,
  );

export {
  selectLogin,
  makeSelectToken,
  makeSelectReason,
  makeSelectOtpSent,
  makeSelectOtpPubKey,
  makeSelectStatus,
  makeSelectLanguage,
  makeSelectFullName,
  makeSelectUserId,
  makeSelectIsSubAgency,
  makeSelectAgencyId,
  makeSelectAgencyCountryCode,
  makeSelectAgencyCountryName,
  makeSelectCommissionRate,
  makeSelectUserInfoStatus,
  makeSelectUserBalance,
  makeSelectCanEditCommission,
  makeSelectLogoutStatus,
  makeSelectLogoutReason,
};
