import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Notification';

export default defineMessages({
  desc: {
    id: `${scope}.desc`,
    defaultMessage:
      'Welcome to the new HProTravel website. You can return to the old website at any time.',
  },
  switchBackVersion: {
    id: `${scope}.switchBackVersion`,
    defaultMessage: 'Switch to old HProTravel',
  },
  returnBottomOfPage: {
    id: `${scope}.returnBottomOfPage`,
    defaultMessage:
      'You can return to the old website by clicking the button at the bottom of the page.',
  },
});
