import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import withBlurred from 'components/shared/withBlurred';
import Icon from 'components/Icon';

import messages from 'components/Sidebar/messages';
import * as S from './style';

class SubItem extends Component {
  changeShownSubMenu = e => {
    e.preventDefault();
    this.props.handleToggleMenu();
  };

  render() {
    const dropdownMenuIcon = isShownList ? 'up' : 'down';
    const { subItems, isShownList, forwardedRef, itemName, href } = this.props;

    return (
      <S.HeaderListItem isOpened={isShownList} ref={forwardedRef}>
        <NavLink
          className="nav-link"
          to={href}
          onClick={e => this.changeShownSubMenu(e)}
        >
          <S.ItemWrapper>
            <S.ItemName>
              <FormattedMessage {...messages[itemName]} />
            </S.ItemName>
            {subItems && (
              <S.DropdownIcon>
                <Icon name={dropdownMenuIcon} width={10} color="#989898" />
              </S.DropdownIcon>
            )}
          </S.ItemWrapper>
        </NavLink>
        {isShownList && (
          <S.SubItemList>
            {subItems &&
              subItems.length &&
              subItems.map(subItem => (
                <S.SubItemWrapper
                  href={subItem.href}
                  isActive={(match, location) =>
                    location.href === `${subItem.href}`
                  }
                >
                  <S.ItemName>
                    {subItem.name ? (
                      <FormattedMessage {...messages[subItem.name]} />
                    ) : (
                      subItem.title
                    )}
                  </S.ItemName>
                </S.SubItemWrapper>
              ))}
          </S.SubItemList>
        )}
      </S.HeaderListItem>
    );
  }
}

SubItem.propTypes = {
  subItems: PropTypes.array,
  isShownList: PropTypes.bool,
  forwardedRef: PropTypes.object,
  handleToggleMenu: PropTypes.func,
  itemName: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.array,
};
export default withBlurred(SubItem);
