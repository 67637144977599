import styled, { css } from 'styled-components';
import Button from 'components/Button';
import { NavLink } from 'react-router-dom';
import { absoluteAlignment } from '../style';
import { Icon } from '../Icon/style';

const menuTransition = `.4s all ease-in-out`;
const iconTransition = `.1s all ease-in-out`;

const LogoWrapper = styled.figure`
  transition: ${iconTransition};
  max-width: 100%;
  width: 100%;
`;

const Logo = styled.img`
  height: 26px;
  transition: ${iconTransition};
`;

const Sidebar = styled.div`
  position: relative;
  flex-shrink: 0;
  z-index: 3;
  display: inline-flex;
  flex-direction: column;
  overflow: ${props => props.isShown && 'hidden'};
  width: 196px;
  background-color: #3A8DC3;
  height: 100vh;
  transition: ${menuTransition};
  ${props =>
    !props.isShown &&
    css`
      width: 72px;
      ${ItemWrapper}:hover ${TooltipItem} {
        opacity: 1;
        display: inline-flex;
        visibility: visible;
      }
      ${FooterItem}, ${ItemName} {
        opacity: 0;
        max-width: 0;
        padding-left: 0;
        visibility: hidden;
      }
      ${ItemWrapper}, ${LogoWrapper} {
        max-width: 72px;
        justify-content: center;
        padding-left: 0;
      }
      ${HeaderLink} {
        padding-left: 18px;
      }
      ${Menu} ${Icon} {
        width: 20px;
      }
      ${LogoWrapper} {
        width: 36px;
        overflow: hidden;
      }
      ${Logo} {
        height: 36px;
      }
      ${FooterList} {
        visibility: hidden;
      }
      ${DropdownIcon} {
        position: absolute;
        opacity: 0;
      }
      ${SwitchBack} {
        overflow: visible;
      }
      ${SwitchBack} ${ItemWrapper} {
        margin-left: 10px;
      }
    `};
`;

const HeaderLink = styled(Button)`
  margin-top: 18px;
  display: flex;
  justify-content: flex-start;
  padding-left: 24px;
  width: 100%;
  height: 36px;
  transition: ${menuTransition};
  align-items: center;
`;

const MenuWrapper = styled.div`
  flex: 1;
  margin-top: 32px;
  min-height: 380px;
`;

const Menu = styled.ul``;

const ItemName = styled.span`
  white-space: nowrap;
  display: inline-flex;
  color: #a39dd1;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 23px;
  padding-left: 16px;
  transition: ${menuTransition};
`;

const Navigation = styled(NavLink)`
  display: flex;
  height: 100%;
  ${props =>
    props.active &&
    css`
      background-color: rgba(93, 80, 204, 0.4);
      ${Icon}, ${ItemName} {
        color: #fff;
      }
    `} &.active {
    background-color: rgba(93, 80, 204, 0.4);
    ${Icon}, ${ItemName} {
      color: #fff;
    }
  }
`;

const Item = styled.li`
  transition: ${menuTransition};
  cursor: pointer;
  height: 42px;
  &:hover {
    ${ItemName}, ${Icon} {
      color: #fff;
    }
  }
  ${Icon} {
    flex: 0 0 auto;
    color: #a39dd1;
    transition: ${iconTransition};
  }
`;

const SubItemWrapper = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  padding-left: 8px;
  margin-top: 16px;
  ${ItemName} {
    font-weight: normal;
  }
  &.active {
    ${ItemName} {
      color: #fff;
    }
  }
`;

const FooterList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  transition: ${menuTransition};
`;

const FooterItem = styled(NavLink)`
  white-space: nowrap;
  padding-left: 24px;
  display: flex;
  cursor: pointer;
  color: #978aff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  max-width: 100%;
  transition: ${menuTransition};
  justify-content: flex-start;

  &.active {
    color: #fff;
  }
`;

const FooterItemWrapper = styled.li`
  &:hover {
    ${FooterItem} {
      color: #fff;
    }
  }
  &:not(:last-child) {
    ${FooterItem} {
      padding-bottom: 4px;
    }
  }
`;

const Toggle = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 2px;
  border: 1px solid #6759dc;
  background-color: #5245c0;
  position: absolute;
  bottom: 60px;
  right: 16px;
  z-index: 4;
  cursor: pointer;
`;

const TooltipItem = styled.div`
  white-space: nowrap;
  transition: ${iconTransition};
  opacity: 0;
  visibility: hidden;
  ${absoluteAlignment(null, '50%')};
  left: 90%;
  z-index: 5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  min-width: 130px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  background-color: #a39dd1;
  border-radius: 2px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.2px;
  line-height: 26px;
  &:before {
    content: '';
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background-color: #a39dd1;
  }
`;

const SubItemList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .link {
    margin-top: 16px;
  }
`;

const DropdownIcon = styled.div`
  position: relative;
  opacity: 1;
  margin-left: 18px;
`;

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 24px;
  height: 100%;
  width: 100%;
`;

const SwitchBack = styled.ul`
  background-color: #f8a119;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  overflow: hidden;
  transition: ${iconTransition};
  ${Item} {
    height: 36px;
  }
  &&& {
    .active {
      background-color: #f8a119;
    }
    ${ItemName} {
      padding-left: 10px;
      font-size: 12px;
      color: #19143e;
    }
    ${Icon} {
      color: #19143e;
    }
  }
  ${ItemWrapper}:hover {
    ${ItemName}, ${Icon} {
      color: #19143e;
    }
  }
`;

export {
  Sidebar,
  HeaderLink,
  Menu,
  MenuWrapper,
  Item,
  Navigation,
  ItemName,
  FooterItemWrapper,
  FooterList,
  FooterItem,
  LogoWrapper,
  Logo,
  Toggle,
  TooltipItem,
  SubItemWrapper,
  SubItemList,
  DropdownIcon,
  ItemWrapper,
  SwitchBack,
};
