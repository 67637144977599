import { defineMessages } from 'react-intl';

export const scope = 'app.components.TripAdvisor';

export default defineMessages({
  terrible: {
    id: `${scope}.terrible`,
    defaultMessage: 'Terrible',
  },
  poor: {
    id: `${scope}.poor`,
    defaultMessage: 'Poor',
  },
  average: {
    id: `${scope}.average`,
    defaultMessage: 'Average',
  },
  standart: {
    id: `${scope}.standart`,
    defaultMessage: 'Standart',
  },
  excellentAndUp: {
    id: `${scope}.excellentAndUp`,
    defaultMessage: 'Excellent & Up',
  },
  excellent: {
    id: `${scope}.excellent`,
    defaultMessage: 'Excellent',
  },
  review: {
    id: `${scope}.review`,
    defaultMessage: 'Review',
  },
  reviews: {
    id: `${scope}.reviews`,
    defaultMessage: 'Reviews',
  },
  reviewText: {
    id: `${scope}.reviewText`,
    defaultMessage: '{count} {message}',
  },
});
