import styled, { css } from 'styled-components';
import { Icon } from '../Icon/style';
import { Badge } from '../Badge/style';

const RatingText = styled.span`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 20px;
  margin-right: 4px;
  margin-left: 8px;
`;

const WithReviewCount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 8px;
`;

const ReviewCountText = styled.span`
  color: #f8a119;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
`;

const TripAdvisorRating = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  flex-direction: row-reverse;
  justify-content: flex-end;

  ${Icon} {
    opacity: 0.7;
  }

  ${RatingText} {
    color: ${props => props.color};
  }

  ${Badge} {
    background-color: ${props => props.color};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 20px;
    border-radius: 4px;
    border: 0;
  }

  ${props =>
    props.hasBadge &&
    css`
      flex-direction: row;
      justify-content: flex-start;
      ${RatingText} {
        color: #626262;
      }
    `};

  ${props =>
    props.reviewCount &&
    css`
      flex-direction: row-reverse;
      justify-content: flex-end;

      ${RatingText} {
        color: ${props.color};
        margin-right: 0px;
        margin-left: 0px;
      }

      ${Badge} {
        width: 48px;
        height: 32px;
      }
    `};
`;

export { TripAdvisorRating, RatingText, WithReviewCount, ReviewCountText };
