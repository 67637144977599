/* eslint-disable camelcase */
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { stringify } from 'query-string';

import { storage } from 'utils/storage';
import { REFUNDS } from 'utils/bookingStatus';

import {
  getUserBalancesFailed,
  getUserBalancesSucceeded,
  getUserFinalizedBookingsSucceeded,
  getUserFinalizedBookingFailed,
  getUserWaitingBookingFailed,
  getUserWaitingBookingSucceeded,
  getUserWaitingRefundsFailed,
  getUserWaitingRefundsSucceeded,
  getUserLoyaltyFailed,
  getUserLoyaltySucceeded,
  createCouponCodeFailed,
  createCouponCodeSucceeded,
  getUserWaitingRefundsRequested,
} from './actions';

import {
  getUserBalancesApi,
  getUserBookingsApi,
  getUserLoyaltyApi,
  createCouponCodeApi,
} from './api';

import {
  GET_USER_BALANCES_REQUESTED,
  GET_USER_FINALIZED_BOOKINGS_REQUESTED,
  GET_USER_WAITING_BOOKINGS_REQUESTED,
  GET_USER_WAITING_REFUNDS_REQUESTED,
  GET_USER_LOYALTY_REQUESTED,
  CREATE_COUPON_CODE_REQUESTED,
} from './constants';

export function* getUserBalancesResult() {
  try {
    const { data } = yield call(getUserBalancesApi);
    yield put(getUserBalancesSucceeded(data));
  } catch (e) {
    yield put(getUserBalancesFailed(e.response));
  }
}

export function* getUserFinalizedBookingsResult({ params }) {
  const queryString = stringify(params);
  try {
    const { data } = yield call(getUserBookingsApi, queryString);
    yield put(getUserFinalizedBookingsSucceeded(data));
  } catch (e) {
    yield put(getUserFinalizedBookingFailed(e.data));
  }
}

export function* getUserWaitingBookingsResult({ params }) {
  const queryString = stringify(params);
  try {
    const { data } = yield call(getUserBookingsApi, queryString);
    yield put(getUserWaitingBookingSucceeded(data));
  } catch (e) {
    yield put(getUserWaitingBookingFailed(e.data));
  }
}

export function* getUserWaitingRefundsResult({ params }) {
  const queryString = stringify(params);
  try {
    const { data } = yield call(getUserBookingsApi, queryString);
    yield put(getUserWaitingRefundsSucceeded(data));
  } catch (e) {
    yield put(getUserWaitingRefundsFailed(e.data));
  }
}

export function* getUserLoyaltyResult() {
  try {
    const { data } = yield call(getUserLoyaltyApi);
    const {
      is_loyalty_active,
      must_be_activated,
      current_balance: { amount },
      loyalty_remaining_day,
    } = data;
    yield put(getUserLoyaltySucceeded(data));
    storage.setItem('LOYALTY_BONUS_AMOUNT', amount);
    storage.setItem('IS_LOYALTY_ACTIVE', is_loyalty_active);
    storage.setItem('LOYALTY_MUST_BE_ACTIVATED', must_be_activated);
    storage.setItem('LOYALTY_REMAINING_DAY', loyalty_remaining_day);
  } catch (e) {
    yield call(getUserLoyaltyFailed(e.response));
  }
}

export function* createCouponCode({ bookingCode, formData }) {
  try {
    const { data } = yield call(createCouponCodeApi, bookingCode, formData);
    yield put(createCouponCodeSucceeded(data));

    yield put(getUserWaitingRefundsRequested({ status: REFUNDS }));
  } catch (e) {
    if (e) {
      const { data, status } = e.response;
      if (status >= 400) {
        yield put(createCouponCodeFailed(data.error));
      }
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_USER_BALANCES_REQUESTED, getUserBalancesResult),
    takeLatest(
      GET_USER_FINALIZED_BOOKINGS_REQUESTED,
      getUserFinalizedBookingsResult,
    ),
    takeLatest(
      GET_USER_WAITING_BOOKINGS_REQUESTED,
      getUserWaitingBookingsResult,
    ),
    takeLatest(GET_USER_WAITING_REFUNDS_REQUESTED, getUserWaitingRefundsResult),
    takeLatest(GET_USER_LOYALTY_REQUESTED, getUserLoyaltyResult),
    takeLatest(CREATE_COUPON_CODE_REQUESTED, createCouponCode),
  ]);
}
