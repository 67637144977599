import styled, { css } from 'styled-components';
import List from 'components/List';
import Menu from 'components/Menu';
import { Item } from 'components/Menu/style';

const LanguageSelect = styled.div`
  height: 100%;
  ${props =>
    props.position === 'right' &&
    css`
      ${MenuList} {
        z-index: 2;
        right: 0;
        left: auto;
        &:before {
          right: 20px;
          left: auto;
        }
      }
      ${LanguageMenu} {
        background-color: #fff;
      }
    `};
`;

const MenuList = styled(List)`
  position: absolute;
  top: 100%;
  left: 0;
  height: 402px;
  z-index: 2;
`;

const LanguageMenu = styled(Menu)`
  display: flex;
  height: 100%;
  background-color: ${props => (props.isOpened ? '#f2f1f6' : '#FFF')};
  padding-left: 16px;
  padding-right: 20px;
  ${Item} {
    text-transform: uppercase;
  }
`;

export { LanguageSelect, MenuList, LanguageMenu };
