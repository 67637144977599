import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash';

import { icons } from './icons';

import * as S from './style';

const Icon = props => {
  const { name, className } = props;
  const classes = classNames('icon', className);
  const iconProps = omit(props, 'className');
  return (
    <S.Icon
      className={classes}
      {...iconProps}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <path d={icons[name]} />
    </S.Icon>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default React.memo(Icon);
