import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CheckBrowser';

export default defineMessages({
  browserSupport: {
    id: `${scope}.browserSupport`,
    defaultMessage: 'Your browser is currently not supported.',
  },
  browserVersionMessage: {
    id: `${scope}.browserVersionMessage`,
    defaultMessage:
      'HProTravel cannot provide a good experience to your browser.',
  },
  versionMessage: {
    id: `${scope}.versionMessage`,
    defaultMessage: 'To use this site, please upgrade to the latest version of',
  },
  connectorMessage: {
    id: `${scope}.connectorMessage`,
    defaultMessage: ' or ',
  },
});
