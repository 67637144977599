import { createSelector } from 'reselect';
import { initialState } from './reducer';

const modalState = state => state.get('modal', initialState);

const makeSelectModalStatus = () =>
  createSelector(modalState, state => state.get('modalStatus'));

const makeSelectModalName = () =>
  createSelector(modalState, state => state.get('name'));

export { modalState, makeSelectModalStatus, makeSelectModalName };
