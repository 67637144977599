import axios from 'axios';

import { storage } from 'utils/storage';
import { API_KEY, B2C_API_KEY, baseApiUrl } from 'config';
import { LOGIN } from 'containers/App/routes';

function getActiveApiKey() {
  const activeAccountType = storage.getItem('ACCOUNT_TYPE');
  const accounts = {
    b2c: B2C_API_KEY,
    b2b: API_KEY,
  };

  return accounts[activeAccountType] || accounts.b2b;
}

const USER_TOKEN = storage.getItem('USER_TOKEN');
const isTokenExist = USER_TOKEN
  ? `Token ${storage.getItem('USER_TOKEN')}`
  : null;

const defaultHeaders = {
  Authorization: isTokenExist,
  'Content-Type': 'application/json',
  'Accept-Language': storage.getItem('USER_LANGUAGE'),
  'API-KEY': getActiveApiKey(),
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON);
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const req = (headers = defaultHeaders, baseURL = baseApiUrl) =>
  axios.create({
    baseURL,
    headers,
    validateStatus(status) {
      if (status === 401) {
        storage.removeItem('USER_TOKEN');
        window.location = LOGIN;
      }
      return status >= 200 && status < 300;
    },
  });
