import styled, { css } from 'styled-components';
import List from 'components/List';
import { Icon } from 'components/Icon/style';
import Menu from 'components/Menu';

const CurrencySelect = styled.div`
  height: 100%;
  position: relative;
  ${props =>
    props.right &&
    css`
      ${List} {
        z-index: 2;
        right: 0;
        left: auto;
        &:before {
          right: 20px;
          left: auto;
        }
      }
    `};
`;

const CurrencyList = styled(List)`
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 402px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  ${Icon} {
    margin-left: 10px;
  }
`;

const CurrencyMenu = styled(Menu)`
  display: flex;
  height: 100%;
  background-color: ${props => (props.isOpened ? '#f2f1f6' : '#FFF')};
  padding-left: 16px;
  padding-right: 20px;
`;

export { CurrencySelect, CurrencyList, CurrencyMenu };
