import { fromJS } from 'immutable';

import * as Status from 'utils/status';

import {
  GET_OLERY_DATA_REQUESTED,
  GET_OLERY_DATA_SUCCEEDED,
  GET_OLERY_DATA_FAILED,
} from './constants';

export const initialState = fromJS({
  reason: null,
  status: Status.INIT,
  data: [],
});

const oleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OLERY_DATA_REQUESTED:
      return state.set('status', Status.LOADING);
    case GET_OLERY_DATA_SUCCEEDED:
      return state.set('data', action.results).set('status', Status.LOADED);
    case GET_OLERY_DATA_FAILED:
      return state.set('reason', Status.FAILED).set('status', Status.FAILED);
    default:
      return state;
  }
};

export default oleryReducer;
