import styled, { css } from 'styled-components';
import { Sidebar } from 'components/Sidebar/style';

import { Header, headerHeight } from '../Header/style';

const Content = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  /* 60 is height of Header */
`;

const Layout = styled.div`
  display: flex;
  ${Header} {
    z-index: 8;
  }
  ${props =>
    props.showNotification &&
    css`
      ${Sidebar} {
        min-height: 690px;
        /* 48 is height of Notification bar */
      }
      ${Content} {
        /* 108 is height of Notification bar and Header */
      }
    `};
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  ${props =>
    props.center &&
    css`
      background-color: #f2f1f6;
      ${CheckoutContent}, ${DetailsContent} {
        width: 100%;
        margin: 0 auto;
        max-width: 1440px;
      }
      ${DetailsContent} {
        height: calc(100% - ${headerHeight});
      }
    `};
`;

const DetailsLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CheckoutLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${props =>
    props.showNotification &&
    css`
      ${CheckoutContent} {
        height: 100%;
      }
    `};
`;

const DetailsContent = styled.div``;

const LoginContent = styled.div`
  height: calc(100vh - ${headerHeight});
  display: flex;
  overflow: auto;
`;

const InfoContent = styled.div`
  height: calc(100vh - ${headerHeight});
  display: flex;
  justify-content: center;
  background: #f2f1f6;
  overflow-y: scroll;
`;

const InfoContentScrolable = styled.div`
  padding: 52px 9.75%;
  flex: 1;
`;

const CheckoutContent = styled.div``;

const AccountContentContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100%;
  background: #f2f1f6;
`;

const AccountContent = styled.div`
  max-width: 100%;
  min-height: calc(100vh - 60px);
  flex-grow: 1;
`;

export {
  Layout,
  Content,
  DetailsLayout,
  DetailsContent,
  Wrapper,
  InfoContent,
  InfoContentScrolable,
  CheckoutLayout,
  CheckoutContent,
  LoginContent,
  AccountContentContainer,
  AccountContent,
};
