import { fromJS } from 'immutable';

import * as Status from 'utils/status';

import {
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCEEDED,
  CHANGE_PASSWORD_FAILED,
  GET_USER_INFORMATION_REQUESTED,
  GET_USER_INFORMATION_SUCCEEDED,
  GET_USER_INFORMATION_FAILED,
  GET_FINANCIAL_INFORMATION_REQUESTED,
  GET_FINANCIAL_INFORMATION_SUCCEEDED,
  GET_FINANCIAL_INFORMATION_FAILED,
  GET_AGENCY_INFORMATION_DETAIL_REQUESTED,
  GET_AGENCY_INFORMATION_DETAIL_SUCCEEDED,
  GET_AGENCY_INFORMATION_DETAIL_FAILED,
  UPDATE_USER_INFORMATION_REQUESTED,
  UPDATE_USER_INFORMATION_SUCCEEDED,
  UPDATE_USER_INFORMATION_FAILED,
  UPDATE_MANAGER_INFORMATION_REQUESTED,
  UPDATE_MANAGER_INFORMATION_SUCCEEDED,
  UPDATE_MANAGER_INFORMATION_FAILED,
  UPDATE_USER_PERMS_REQUESTED,
  UPDATE_USER_PERMS_SUCCEEDED,
  UPDATE_USER_PERMS_FAILED,
  GET_CONTACT_EMAILS_REQUESTED,
  GET_CONTACT_EMAILS_SUCCEEDED,
  GET_CONTACT_EMAILS_FAILED,
  GET_AGENCY_INFORMATION_REQUESTED,
  GET_AGENCY_INFORMATION_SUCCEEDED,
  GET_AGENCY_INFORMATION_FAILED,
  POST_CONTACT_EMAIL_REQUESTED,
  POST_CONTACT_EMAIL_SUCCEEDED,
  POST_CONTACT_EMAIL_FAILED,
  UPDATE_AGENCY_FINANCIAL_INFORMATION_REQUESTED,
  UPDATE_AGENCY_FINANCIAL_INFORMATION_SUCCEEDED,
  UPDATE_AGENCY_FINANCIAL_INFORMATION_FAILED,
  UPDATE_AGENCY_INFORMATION_REQUESTED,
  UPDATE_AGENCY_INFORMATION_SUCCEEDED,
  UPDATE_AGENCY_INFORMATION_FAILED,
  UPDATE_CONTACT_EMAIL_REQUESTED,
  UPDATE_CONTACT_EMAIL_SUCCEEDED,
  UPDATE_CONTACT_EMAIL_FAILED,
  DELETE_CONTACT_EMAIL_REQUESTED,
  DELETE_CONTACT_EMAIL_SUCCEEDED,
  DELETE_CONTACT_EMAIL_FAILED,
  POST_NEW_USER_REQUESTED,
  POST_NEW_USER_SUCCEEDED,
  POST_NEW_USER_FAILED,
  GET_AGENCY_USERS_REQUESTED,
  GET_AGENCY_USERS_SUCCEEDED,
  GET_AGENCY_USERS_FAILED,
  GET_SUBAGENCIES_REQUESTED,
  GET_SUBAGENCIES_SUCCEEDED,
  GET_SUBAGENCIES_FAILED,
  GET_FUTURE_BALANCE_REQUESTED,
  GET_FUTURE_BALANCE_SUCCEEDED,
  GET_FUTURE_BALANCE_FAILED,
  GET_COUNTRIES_REQUESTED,
  GET_COUNTRIES_SUCCEEDED,
  GET_COUNTRIES_FAILED,
  GET_COUNTRY_STATES_REQUESTED,
  GET_COUNTRY_STATES_SUCCEEDED,
  GET_COUNTRY_STATES_FAILED,
  CREATE_SUBAGENCY_REQUESTED,
  CREATE_SUBAGENCY_SUCCEEDED,
  CREATE_SUBAGENCY_FAILED,
  UPDATE_PAYMENT_METHODS_REQUESTED,
  UPDATE_PAYMENT_METHODS_SUCCEEDED,
  UPDATE_PAYMENT_METHODS_FAILED,
  GET_PAYMENT_METHODS_REQUESTED,
  GET_PAYMENT_METHODS_SUCCEEDED,
  GET_PAYMENT_METHODS_FAILED,
  GET_VIRTUAL_BALANCE_REQUESTED,
  GET_VIRTUAL_BALANCE_SUCCEEDED,
  GET_VIRTUAL_BALANCE_FAILED,
  UPDATE_VIRTUAL_BALANCE_REQUESTED,
  UPDATE_VIRTUAL_BALANCE_SUCCEEDED,
  UPDATE_VIRTUAL_BALANCE_FAILED,
  RESEND_ACTIVATION_LINK_REQUESTED,
  RESEND_ACTIVATION_LINK_SUCCEEDED,
  RESEND_ACTIVATION_LINK_FAILED,
  CHANGE_PAGE,
  GET_CARD_TOKEN_REQUESTED,
  GET_CARD_TOKEN_SUCCEEDED,
  GET_CARD_TOKEN_FAILED,
  POST_CARD_INFORMATIONS_REQUESTED,
  POST_CARD_INFORMATIONS_SUCCEEDED,
  POST_CARD_INFORMATIONS_FAILED,
  GET_SAVED_CARDS_REQUESTED,
  GET_SAVED_CARDS_SUCCEEDED,
  GET_SAVED_CARDS_FAILED,
  DELETE_SAVED_CARD_REQUESTED,
  DELETE_SAVED_CARD_SUCCEEDED,
  DELETE_SAVED_CARD_FAILED,
} from './constants';

export const initialState = fromJS({
  changePassword: {
    status: Status.INIT,
    reason: null,
  },
  userInformation: {
    status: Status.INIT,
    reason: null,
    userInfo: {},
  },
  financialInformation: {
    status: Status.INIT,
    reason: null,
    financialInfo: {},
  },
  agencyInformationDetail: {
    status: Status.INIT,
    reason: null,
    agencyInfo: {},
  },
  updateUserInformation: {
    status: Status.INIT,
    reason: null,
  },
  getContactEmails: {
    status: Status.INIT,
    reason: null,
    emails: [],
  },
  postContactEmail: {
    status: Status.INIT,
    reason: null,
  },
  updateContactEmail: {
    status: Status.INIT,
    reason: null,
  },
  updateUserPerms: {
    status: Status.INIT,
    reason: null,
  },
  deleteContactEmail: {
    status: Status.INIT,
    reason: null,
  },
  agencyInformation: {
    status: Status.INIT,
    reason: null,
    data: [],
  },
  agencyUsers: {
    status: Status.INIT,
    reason: null,
    users: [],
  },
  subagencies: {
    status: Status.INIT,
    reason: null,
    subagenciesData: [],
    activePage: 1,
    totalCount: 0,
  },
  futureBalance: {
    status: Status.INIT,
    reason: null,
    balance: [],
  },
  updateAgencyFinancialInformation: {
    status: Status.INIT,
    reason: null,
  },
  updateManagerInformation: {
    status: Status.INIT,
    reason: null,
  },
  updateAgencyInformation: {
    status: Status.INIT,
    reason: null,
  },
  postNewUser: {
    status: Status.INIT,
    reason: null,
    results: {},
  },
  countries: {
    status: Status.INIT,
    reason: null,
    results: [],
  },
  countryStates: {
    status: Status.INIT,
    reason: null,
    results: [],
  },
  createSubagency: {
    status: Status.INIT,
    reason: null,
  },
  updatePaymentMethods: {
    status: Status.INIT,
    reason: null,
  },
  getPaymentMethods: {
    paymentMethods: {},
    status: Status.INIT,
    reason: null,
  },
  getVirtualBalance: {
    virtualBalance: {},
    status: Status.INIT,
    reason: null,
  },
  updateVirtualBalance: {
    status: Status.INIT,
    reason: null,
  },
  resendActivationLink: {
    status: Status.INIT,
    reason: null,
  },
  cardToken: {
    status: Status.INIT,
    reason: null,
  },
  postCardInformations: {
    status: Status.INIT,
    reason: null,
  },
  savedCards: {
    cards: [],
    status: Status.INIT,
    reason: null,
  },
  deleteSavedCard: {
    status: Status.INIT,
    reason: null,
  },
});

/* eslint-disable camelcase */
function myAccountReducer(state = initialState, action) {
  let users = [];
  let countries = [];
  let countryStates = [];

  switch (action.type) {
    case CHANGE_PASSWORD_REQUESTED:
      return state.setIn(['changePassword', 'status'], Status.LOADING);
    case CHANGE_PASSWORD_SUCCEEDED:
      return state.setIn(['changePassword', 'status'], Status.LOADED);
    case CHANGE_PASSWORD_FAILED:
      return state
        .setIn(['changePassword', 'status'], Status.FAILED)
        .setIn(['changePassword', 'reason'], action.reason);
    case GET_USER_INFORMATION_REQUESTED:
      return state.setIn(['userInformation', 'status'], Status.LOADING);
    case GET_USER_INFORMATION_SUCCEEDED:
      return state
        .setIn(['userInformation', 'status'], Status.LOADED)
        .setIn(['userInformation', 'userInfo'], fromJS(action.results));
    case GET_USER_INFORMATION_FAILED:
      return state
        .setIn(['userInformation', 'status'], Status.FAILED)
        .setIn(['userInformation', 'reason'], action.reason);
    case GET_FINANCIAL_INFORMATION_REQUESTED:
      return state.setIn(['financialInformation', 'status'], Status.LOADING);
    case GET_FINANCIAL_INFORMATION_SUCCEEDED:
      return state
        .setIn(['financialInformation', 'status'], Status.LOADED)
        .setIn(
          ['financialInformation', 'financialInfo'],
          fromJS(action.results),
        );
    case GET_FINANCIAL_INFORMATION_FAILED:
      return state
        .setIn(['financialInformation', 'status'], Status.FAILED)
        .setIn(['financialInformation', 'reason'], action.reason);
    case GET_AGENCY_INFORMATION_DETAIL_REQUESTED:
      return state.setIn(['agencyInformationDetail', 'status'], Status.LOADING);
    case GET_AGENCY_INFORMATION_DETAIL_SUCCEEDED:
      return state
        .setIn(['agencyInformationDetail', 'status'], Status.LOADED)
        .setIn(
          ['agencyInformationDetail', 'agencyInfo'],
          fromJS(action.results),
        );
    case GET_AGENCY_INFORMATION_DETAIL_FAILED:
      return state
        .setIn(['agencyInformationDetail', 'status'], Status.FAILED)
        .setIn(['agencyInformationDetail', 'reason'], action.reason);
    case UPDATE_USER_INFORMATION_REQUESTED:
      return state.setIn(['updateUserInformation', 'status'], Status.LOADING);
    case UPDATE_USER_INFORMATION_SUCCEEDED:
      return state.setIn(['updateUserInformation', 'status'], Status.LOADED);
    case UPDATE_USER_INFORMATION_FAILED:
      return state
        .setIn(['updateUserInformation', 'status'], Status.FAILED)
        .setIn(['updateUserInformation', 'reason'], action.reason);
    case UPDATE_MANAGER_INFORMATION_REQUESTED:
      return state.setIn(
        ['updateManagerInformation', 'status'],
        Status.LOADING,
      );
    case UPDATE_MANAGER_INFORMATION_SUCCEEDED:
      return state.setIn(['updateManagerInformation', 'status'], Status.LOADED);
    case UPDATE_MANAGER_INFORMATION_FAILED:
      return state
        .setIn(['updateManagerInformation', 'status'], Status.FAILED)
        .setIn(['updateManagerInformation', 'reason'], action.reason);
    case GET_CONTACT_EMAILS_REQUESTED:
      return state.setIn(['getContactEmails', 'status'], Status.LOADING);
    case GET_CONTACT_EMAILS_SUCCEEDED:
      return state
        .setIn(['getContactEmails', 'status'], Status.LOADED)
        .setIn(['getContactEmails', 'emails'], fromJS(action.results.objects));
    case GET_CONTACT_EMAILS_FAILED:
      return state
        .setIn(['getContactEmails', 'status'], Status.FAILED)
        .setIn(['getContactEmails', 'reason'], action.reason);
    case POST_CONTACT_EMAIL_REQUESTED:
      return state.setIn(['postContactEmail', 'status'], Status.LOADING);
    case POST_CONTACT_EMAIL_SUCCEEDED:
      return state.setIn(['postContactEmail', 'status'], Status.LOADED);
    case POST_CONTACT_EMAIL_FAILED:
      return state
        .setIn(['postContactEmail', 'status'], Status.FAILED)
        .setIn(['postContactEmail', 'reason'], action.reason);
    case UPDATE_AGENCY_FINANCIAL_INFORMATION_REQUESTED:
      return state.setIn(
        ['updateAgencyFinancialInformation', 'status'],
        Status.LOADING,
      );
    case UPDATE_AGENCY_FINANCIAL_INFORMATION_SUCCEEDED:
      return state.setIn(
        ['updateAgencyFinancialInformation', 'status'],
        Status.LOADED,
      );
    case UPDATE_AGENCY_FINANCIAL_INFORMATION_FAILED:
      return state
        .setIn(['updateAgencyFinancialInformation', 'status'], Status.FAILED)
        .setIn(['updateAgencyFinancialInformation', 'reason'], action.reason);
    case UPDATE_AGENCY_INFORMATION_REQUESTED:
      return state.setIn(['updateAgencyInformation', 'status'], Status.LOADING);
    case UPDATE_AGENCY_INFORMATION_SUCCEEDED:
      return state.setIn(['updateAgencyInformation', 'status'], Status.LOADED);
    case UPDATE_AGENCY_INFORMATION_FAILED:
      return state
        .setIn(['updateAgencyInformation', 'status'], Status.FAILED)
        .setIn(['updateAgencyInformation', 'reason'], action.reason);
    case UPDATE_CONTACT_EMAIL_REQUESTED:
      return state.setIn(['updateContactEmail', 'status'], Status.LOADING);
    case UPDATE_CONTACT_EMAIL_SUCCEEDED:
      return state.setIn(['updateContactEmail', 'status'], Status.LOADED);
    case UPDATE_CONTACT_EMAIL_FAILED:
      return state
        .setIn(['updateContactEmail', 'status'], Status.FAILED)
        .setIn(['updateContactEmail', 'reason'], action.reason);
    case UPDATE_USER_PERMS_REQUESTED:
      return state.setIn(['updateUserPerms', 'status'], Status.LOADING);
    case UPDATE_USER_PERMS_SUCCEEDED:
      return state.setIn(['updateUserPerms', 'status'], Status.LOADED);
    case UPDATE_USER_PERMS_FAILED:
      return state
        .setIn(['updateUserPerms', 'status'], Status.FAILED)
        .setIn(['updateUserPerms', 'reason'], action.reason);
    case DELETE_CONTACT_EMAIL_REQUESTED:
      return state.setIn(['deleteContactEmail', 'status'], Status.LOADING);
    case DELETE_CONTACT_EMAIL_SUCCEEDED:
      return state.setIn(['deleteContactEmail', 'status'], Status.LOADED);
    case DELETE_CONTACT_EMAIL_FAILED:
      return state
        .setIn(['deleteContactEmail', 'status'], Status.FAILED)
        .setIn(['deleteContactEmail', 'reason'], action.reason);
    case GET_AGENCY_INFORMATION_REQUESTED:
      return state.setIn(['agencyInformation', 'status'], Status.LOADING);
    case GET_AGENCY_INFORMATION_SUCCEEDED:
      return state
        .setIn(['agencyInformation', 'status'], Status.LOADED)
        .setIn(['agencyInformation', 'data'], action.results);
    case GET_AGENCY_INFORMATION_FAILED:
      return state
        .setIn(['agencyInformation', 'status'], Status.FAILED)
        .setIn(['agencyInformation', 'reason'], action.reason);
    case GET_AGENCY_USERS_REQUESTED:
      return state.setIn(['agencyUsers', 'status'], Status.LOADING);
    case GET_AGENCY_USERS_SUCCEEDED:
      users = action.results.objects;
      /* eslint-disable no-param-reassign */
      users.map(item => {
        item.full_name = `${item.first_name} ${item.last_name}`;
        return null;
      });
      /* eslint-enable no-param-reassign */
      return state
        .setIn(['agencyUsers', 'status'], Status.LOADED)
        .setIn(['agencyUsers', 'users'], users);
    case GET_AGENCY_USERS_FAILED:
      return state
        .setIn(['agencyUsers', 'status'], Status.FAILED)
        .setIn(['agencyUsers', 'reason'], action.reason);
    case GET_SUBAGENCIES_REQUESTED:
      return state.setIn(['subagencies', 'status'], Status.LOADING);
    case GET_SUBAGENCIES_SUCCEEDED:
      return state
        .setIn(['subagencies', 'totalCount'], action.results.meta.total_count)
        .setIn(['subagencies', 'status'], Status.LOADED)
        .setIn(['subagencies', 'subagenciesData'], action.results.objects);
    case GET_SUBAGENCIES_FAILED:
      return state
        .setIn(['subagencies', 'status'], Status.FAILED)
        .setIn(['subagencies', 'reason'], action.reason);
    case GET_FUTURE_BALANCE_REQUESTED:
      return state.setIn(['futureBalance', 'status'], Status.LOADING);
    case GET_FUTURE_BALANCE_SUCCEEDED:
      return state
        .setIn(['futureBalance', 'status'], Status.LOADED)
        .setIn(['futureBalance', 'balance'], fromJS(action.results));
    case GET_FUTURE_BALANCE_FAILED:
      return state
        .setIn(['futureBalance', 'status'], Status.FAILED)
        .setIn(['futureBalance', 'reason'], action.reason);
    case POST_NEW_USER_REQUESTED:
      return state.setIn(['postNewUser', 'status'], Status.LOADING);
    case POST_NEW_USER_SUCCEEDED:
      return state
        .setIn(['postNewUser', 'status'], Status.LOADED)
        .setIn(['postNewUser', 'results'], action.results);
    case POST_NEW_USER_FAILED:
      return state
        .setIn(['postNewUser', 'status'], Status.FAILED)
        .setIn(['postNewUser', 'reason'], action.reason);
    case GET_COUNTRIES_REQUESTED:
      return state.setIn(['countries', 'status'], Status.LOADING);
    case GET_COUNTRIES_SUCCEEDED:
      countries = action.results.objects.map(country => ({
        value: country.id,
        name: country.name,
        id: country.id,
      }));
      return state
        .setIn(['countries', 'status'], Status.LOADED)
        .setIn(['countries', 'results'], fromJS(countries));
    case GET_COUNTRIES_FAILED:
      return state
        .setIn(['countries', 'status'], Status.FAILED)
        .setIn(['countries', 'reason'], action.reason);
    case GET_COUNTRY_STATES_REQUESTED:
      return state.setIn(['countryStates', 'status'], Status.LOADING);
    case GET_COUNTRY_STATES_SUCCEEDED:
      countryStates = action.results.objects.map(countryState => ({
        value: countryState.id,
        name: countryState.name,
        id: countryState.id,
      }));
      return state
        .setIn(['countryStates', 'status'], Status.LOADED)
        .setIn(['countryStates', 'results'], fromJS(countryStates));
    case GET_COUNTRY_STATES_FAILED:
      return state
        .setIn(['countryStates', 'status'], Status.FAILED)
        .setIn(['countryStates', 'reason'], action.reason);
    case CREATE_SUBAGENCY_REQUESTED:
      return state.setIn(['createSubagency', 'status'], Status.LOADING);
    case CREATE_SUBAGENCY_SUCCEEDED:
      return state.setIn(['createSubagency', 'status'], Status.LOADED);
    case CREATE_SUBAGENCY_FAILED:
      return state
        .setIn(['createSubagency', 'status'], Status.FAILED)
        .setIn(['createSubagency', 'reason'], action.reason);
    case UPDATE_PAYMENT_METHODS_REQUESTED:
      return state.setIn(['updatePaymentMethods', 'status'], Status.LOADING);
    case UPDATE_PAYMENT_METHODS_SUCCEEDED:
      return state.setIn(['updatePaymentMethods', 'status'], Status.LOADED);
    case UPDATE_PAYMENT_METHODS_FAILED:
      return state
        .setIn(['updatePaymentMethods', 'status'], Status.FAILED)
        .setIn(['updatePaymentMethods', 'reason'], action.reason);
    case GET_PAYMENT_METHODS_REQUESTED:
      return state.setIn(['getPaymentMethods', 'status'], Status.LOADING);
    case GET_PAYMENT_METHODS_SUCCEEDED:
      return state
        .setIn(['getPaymentMethods', 'status'], Status.LOADED)
        .setIn(['getPaymentMethods', 'paymentMethods'], fromJS(action.results));
    case GET_PAYMENT_METHODS_FAILED:
      return state
        .setIn(['getPaymentMethods', 'status'], Status.FAILED)
        .setIn(['getPaymentMethods', 'reason'], action.reason);
    case GET_VIRTUAL_BALANCE_REQUESTED:
      return state.setIn(['getVirtualBalance', 'status'], Status.LOADING);
    case GET_VIRTUAL_BALANCE_SUCCEEDED:
      return state
        .setIn(['getVirtualBalance', 'status'], Status.LOADED)
        .setIn(['getVirtualBalance', 'virtualBalance'], action.results);
    case GET_VIRTUAL_BALANCE_FAILED:
      return state
        .setIn(['getVirtualBalance', 'status'], Status.FAILED)
        .setIn(['getVirtualBalance', 'reason'], action.reason);
    case UPDATE_VIRTUAL_BALANCE_REQUESTED:
      return state.setIn(['updateVirtualBalance', 'status'], Status.LOADING);
    case UPDATE_VIRTUAL_BALANCE_SUCCEEDED:
      return state.setIn(['updateVirtualBalance', 'status'], Status.LOADED);
    case UPDATE_VIRTUAL_BALANCE_FAILED:
      return state
        .setIn(['updateVirtualBalance', 'status'], Status.FAILED)
        .setIn(['updateVirtualBalance', 'reason'], action.reason);
    case RESEND_ACTIVATION_LINK_REQUESTED:
      return state.setIn(['resendActivationLink', 'status'], Status.LOADING);
    case RESEND_ACTIVATION_LINK_SUCCEEDED:
      return state.setIn(['resendActivationLink', 'status'], Status.LOADED);
    case RESEND_ACTIVATION_LINK_FAILED:
      return state
        .setIn(['resendActivationLink', 'status'], Status.FAILED)
        .setIn(['resendActivationLink', 'reason'], action.reason);
    case GET_CARD_TOKEN_REQUESTED:
      return state.setIn(['cardToken', 'status'], Status.LOADING);
    case GET_CARD_TOKEN_SUCCEEDED:
      return state.setIn(['cardToken', 'status'], Status.LOADED);
    case GET_CARD_TOKEN_FAILED:
      return state
        .setIn(['cardToken', 'status'], Status.FAILED)
        .setIn(['cardToken', 'reason'], action.reason);
    case POST_CARD_INFORMATIONS_REQUESTED:
      return state.setIn(['postCardInformations', 'status'], Status.LOADING);
    case POST_CARD_INFORMATIONS_SUCCEEDED:
      return state.setIn(['postCardInformations', 'status'], Status.LOADED);
    case POST_CARD_INFORMATIONS_FAILED:
      return state
        .setIn(['postCardInformations', 'status'], Status.FAILED)
        .setIn(['postCardInformations', 'reason'], action.reason);
    case GET_SAVED_CARDS_REQUESTED:
      return state.setIn(['savedCards', 'status'], Status.LOADING);
    case GET_SAVED_CARDS_SUCCEEDED:
      return state
        .setIn(['savedCards', 'status'], Status.LOADED)
        .setIn(['savedCards', 'cards'], action.results);
    case GET_SAVED_CARDS_FAILED:
      return state
        .setIn(['savedCards', 'status'], Status.FAILED)
        .setIn(['savedCards', 'reason'], action.reason);
    case DELETE_SAVED_CARD_REQUESTED:
      return state.setIn(['deleteSavedCard', 'status'], Status.LOADING);
    case DELETE_SAVED_CARD_SUCCEEDED:
      return state.setIn(['deleteSavedCard', 'status'], Status.LOADED);
    case DELETE_SAVED_CARD_FAILED:
      return state
        .setIn(['deleteSavedCard', 'status'], Status.FAILED)
        .setIn(['deleteSavedCard', 'reason'], action.reason);
    case CHANGE_PAGE:
      return state.setIn(['subagencies', 'activePage'], action.page);
    default:
      return state;
  }
}

export default myAccountReducer;
