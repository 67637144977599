import { defineMessages } from 'react-intl';

export const scope = 'app.components.CommissionsSelect';

export default defineMessages({
  selectCommissionHeader: {
    id: `${scope}.selectCommissionHeader`,
    defaultMessage: 'Select your commission',
  },
  commission: {
    id: `${scope}.commission`,
    defaultMessage: 'Commission',
  },
});
