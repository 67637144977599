import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import Cookie from 'js-cookie';

import withBlurred from 'components/shared/withBlurred';

import * as Status from 'utils/status';
import { storage } from 'utils/storage';
import injectSaga from 'utils/injectSaga';

import saga from 'containers/LanguageProvider/saga';

import {
  makeSelectLanguages,
  makeSelectLocale,
  makeSelectLanguagesStatus,
} from 'containers/LanguageProvider/selectors';

import {
  getUserLanguagesRequested,
  setUserLanguageRequested,
  getCoreLanguagesRequested,
  changeLocale,
} from 'containers/LanguageProvider/actions';

import * as S from './style';
import messages from './messages';

class Language extends Component {
  static propTypes = {
    languages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    locale: PropTypes.string,
    position: PropTypes.string,
    isPreLogin: PropTypes.bool,
  };

  handleChangeShowMenu = () => {
    const {
      getLanguages,
      getCoreLanguages,
      languages,
      isPreLogin,
    } = this.props;

    if (!languages.length) {
      if (isPreLogin) {
        getCoreLanguages();
      } else {
        getLanguages();
      }
    }
    this.props.handleToggleMenu();
  };

  setUserLanguage = language => {
    const { changeUserLanguage, isPreLogin, changeLocaleLanguage } = this.props;

    Cookie.set('language', language.code);

    if (!isPreLogin) {
      changeUserLanguage(language);
    } else {
      changeLocaleLanguage(language.code);
      storage.setItem('USER_LANGUAGE', language.code);
      storage.setItem('USER_LANGUAGE_NAME', language.name);
    }
  };

  render() {
    const {
      locale,
      languages,
      status,
      position,
      isShownList,
      forwardedRef,
    } = this.props;

    return (
      <S.LanguageSelect ref={forwardedRef} position={position}>
        <S.LanguageMenu
          selected={locale}
          onClick={this.handleChangeShowMenu}
          isOpened={isShownList}
        >
          {isShownList && (
            <S.MenuList
              isLoaded={status === Status.LOADED}
              onClickedOption={option => this.setUserLanguage(option)}
              options={languages}
              header={<FormattedMessage {...messages.selectLanguageHeader} />}
              selected={locale}
            />
          )}
        </S.LanguageMenu>
      </S.LanguageSelect>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  languages: makeSelectLanguages(),
  locale: makeSelectLocale(),
  status: makeSelectLanguagesStatus(),
});

const mapDispatchToProps = dispatch => ({
  changeUserLanguage: language => {
    dispatch(setUserLanguageRequested(language));
  },
  getLanguages: () => {
    dispatch(getUserLanguagesRequested());
  },
  getCoreLanguages: () => {
    dispatch(getCoreLanguagesRequested());
  },
  changeLocaleLanguage: languageCode => {
    dispatch(changeLocale(languageCode));
  },
});

Language.defaultProps = {
  isPreLogin: false,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({
  key: 'language',
  saga,
});

export default withBlurred(
  compose(
    withConnect,
    withSaga,
  )(Language),
);
