import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash';

import Icon from '../Icon';

import * as S from './style';

const Menu = props => {
  const { selected, className, children, isOpened } = props;
  const classes = classNames('menu', className);
  const menuProps = omit(props, 'className', 'children');
  return (
    <S.Menu className={classes} {...menuProps}>
      <S.Item>
        {selected}
        <Icon name={isOpened ? 'up' : 'down'} width="10" color="#a6a6a6" />
      </S.Item>
      {children}
    </S.Menu>
  );
};

Menu.propTypes = {
  selected: PropTypes.node.isRequired,
  children: PropTypes.node,
  isOpened: PropTypes.bool,
};

export default Menu;
