import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import withBlurred from 'components/shared/withBlurred';

import injectSaga from 'utils/injectSaga';
import { syncUrl } from 'helpers/syncUrl';

import saga from 'containers/CurrencyProvider/saga';

import * as Status from 'utils/status';

import {
  getUserCurrenciesRequested,
  setUserCurrencyRequested,
} from 'containers/CurrencyProvider/actions';

import {
  makeSelectCurrencies,
  makeSelectCurrencyCode,
  makeSelectCurrenciesStatus,
} from 'containers/CurrencyProvider/selectors';

import * as S from './style';
import messages from './messages';

class CurrencyProvider extends PureComponent {
  handleChangeShowMenu = () => {
    const { getCurrencies, currencies } = this.props;

    if (!currencies.length) {
      getCurrencies();
    }
    this.props.handleToggleMenu();
  };

  setUserCurrencyCode = currencyCode => {
    const { changeUserCurrencyCode } = this.props;
    changeUserCurrencyCode(currencyCode);
  };

  render() {
    const {
      currencies,
      currencyCode,
      status,
      right,
      isShownList,
      forwardedRef,
    } = this.props;

    return (
      <S.CurrencySelect ref={forwardedRef} right={right}>
        <S.CurrencyMenu
          selected={currencyCode}
          onClick={this.handleChangeShowMenu}
          isOpened={isShownList}
        >
          {isShownList && (
            <S.CurrencyList
              isLoaded={status === Status.LOADED}
              onClickedOption={option => this.setUserCurrencyCode(option.code)}
              options={currencies}
              header={<FormattedMessage {...messages.selectCurrencyHeader} />}
              selected={currencyCode}
              isClosed={false}
            />
          )}
        </S.CurrencyMenu>
      </S.CurrencySelect>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currencies: makeSelectCurrencies(),
  currencyCode: makeSelectCurrencyCode(),
  status: makeSelectCurrenciesStatus(),
});

const mapDispatchToProps = dispatch => ({
  getCurrencies: () => {
    dispatch(getUserCurrenciesRequested());
  },
  changeUserCurrencyCode: currencyCode => {
    dispatch(setUserCurrencyRequested(currencyCode));
    if (window.location.href.indexOf('hotels') > -1) {
      syncUrl([{ name: 'currency', value: currencyCode }]);
    }
  },
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({
  key: 'currency',
  saga,
});

CurrencyProvider.propTypes = {
  currencies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  getCurrencies: PropTypes.func,
};

export default withBlurred(
  compose(
    withConnect,
    withSaga,
  )(CurrencyProvider),
);
