import styled, { createGlobalStyle, css } from 'styled-components';
import { absoluteAlignment } from '../style';
import { Icon } from '../Icon/style';
import { Layout } from '../Layout/style';

const PositionBasedModal = createGlobalStyle`
  ${Layout} {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      background-color: rgba(18, 18, 18, 0.85);
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      z-index: 10;
      height: 100vh;
      overflow: hidden;
    }
  }
`;

const Wrapper = styled.div`
  z-index: 11;
  ${({ position }) =>
    position === 'center' &&
    css`
      display: flex;
      flex-direction: colum;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(18, 18, 18, 0.85);
    `};
  ${({ position }) =>
    position === 'center' &&
    css`
      ${Modal} {
        width: ${props => props.width || '410px'};
        ${absoluteAlignment('50%', '50%')};
        height: ${props => props.height};
        display: flex;
        flex-direction: column;
      }
      ${Content} {
        flex-shrink: 0;
        height: 100%;
      }
    `};

  ${({ isFocusable, position }) =>
    position === 'current' &&
    isFocusable &&
    css`
      ${Content}::before {
        content: '';
        position: absolute;
        left: -16px;
        top: -16px;
        right: -16px;
        height: 100%;
        border-radius: 2px;
        border: 1px solid #979797;
        background-color: rgba(242, 241, 246, 0.5);
        z-index: -1;
      }
      ${Close} {
        margin-right: -16px;
      }
    `};

  ${({ position }) =>
    position === 'current' &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin-top: -48px; /* 48px is modal close header and content margin-top value */
      ${Modal} {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 515px;
      }
      ${Content} {
        margin-top: 8px;
        align-items: flex-start;
        background-color: transparent;
        width: ${props => props.width};
        height: ${props => props.height};
      }
    `};

  ${({ transparent }) =>
    transparent &&
    css`
      ${Content} {
        background-color: transparent;
      }
    `};

  ${({ scrollable }) =>
    scrollable &&
    css`
      ${Content} {
        overflow: auto;
      }
    `};

  ${({ header, position }) =>
    position === 'current' &&
    header &&
    css`
      ${Content} {
        margin-top: 0px;
      }
    `};
`;

const Modal = styled.div`
  z-index: 9;
`;

const Close = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 2px 2px;
  background-color: white;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  overflow: hidden;
  flex-shrink: 0;
`;

const CloseHeader = styled.div`
  display: flex;
`;

const Link = styled.a`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  align-self: flex-end;
  color: #bebebe;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  user-select: none;
  margin-left: auto;
  ${Icon} {
    margin-left: 6px;
  }
`;

export {
  Wrapper,
  Modal,
  Close,
  Content,
  Header,
  Link,
  PositionBasedModal,
  CloseHeader,
};
