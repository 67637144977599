import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import messages from 'components/Sidebar/messages';
import SubItem from './subItem';

import * as S from './style';

const HeaderMenu = ({ headerList }) =>
  headerList.map(({ name, href, key, subItems }) => (
    <>
      {subItems ? (
        <SubItem href={href} itemName={name} subItems={subItems} />
      ) : (
        <S.HeaderListItem key={key}>
          <NavLink className="nav-link" to={href}>
            <S.ItemWrapper>
              <S.ItemName>
                <FormattedMessage {...messages[name]} />
              </S.ItemName>
            </S.ItemWrapper>
          </NavLink>
        </S.HeaderListItem>
      )}
    </>
  ));

export default HeaderMenu;
